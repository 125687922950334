import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tarifs from './Tarifs';
import { ITarifs } from './Tarifs';

interface IProps {
    showTitle?: boolean;
    opening?: string;
    showTaxes?: boolean;
    setMinHeight?: boolean;
    showSurface?: boolean;
    showName?: boolean;
    nights?: number[];
    prices?: number[];
}

const TarifsMaisonnette = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const [tarifs, setTarifs] = useState<ITarifs>();

    useEffect(() => {
        // Fetch tarifs

        setTarifs({
            showTitle: props.showTitle,
            opening: props.opening,
            showTaxes: props.showTaxes,
            setMinHeight: props.setMinHeight,
            name: props.showName ? 'La Maisonnette' : undefined,
            winter: true,
            nights: props.nights || [3, 3, 7, 3],
            prices: props.prices || [250, 260, 260, 260],
            capacity: t('maisonnette.tarifs.maisonnette'),
            surface: props.showSurface ? "74" : undefined
        });
    }, [i18n.language]);

    return (
        <div className='w-full h-full'>
            {
                tarifs && <Tarifs {...tarifs} />
            }
        </div>
    );
}

export default TarifsMaisonnette;