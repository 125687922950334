import { useEffect, useState } from "react";
import Logo from "../assets/logo.png";

import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from "history";

const LangSwitcher = (props: { className: string }) => {
    const { i18n } = useTranslation();
    const [language] = useState<string[]>(["fr", "en", "de"]);

    return (
        <div className={`${props.className} w-fit h-full whitespace-nowrap flex-row items-center ml-[20px] ff-zilla`}>
            <p className="text-black cursor-pointer min-w-[25px] flex justify-center" onClick={() => { i18n.changeLanguage(language.filter((lang) => lang !== i18n.language)[0]) }}>{language.filter((lang) => lang !== i18n.language)[0]}</p>
            <p className="text-gray-500 px-1 text-xl"> | </p>
            <p className="text-black cursor-pointer min-w-[25px] flex justify-center" onClick={() => { i18n.changeLanguage(language.filter((lang) => lang !== i18n.language)[1]) }}>{language.filter((lang) => lang !== i18n.language)[1]}</p>
        </div>
    )
}

interface IProps {
    setIndex: (index: number) => void;
}

const Navbar = (props: IProps) => {
    const [isAtTop, setIsAtTop] = useState(true);
    const [openMenu, setOpenMenu] = useState(false);
    const indexName = ["home", "maison", "cabane", "maisonnette", "soins", "histoire", "informations", "milknlove", "reseaux", "confidentialite", "mentionslegales"];
    const { t } = useTranslation();
    const history = createBrowserHistory();

    useEffect(() => {
        const pathName = window.location.pathname.split("/")[1];

        if (pathName === "") {
            props.setIndex(0);
        } else if (!indexName.includes(pathName)) {
            changeTab(0);
        } else {
            props.setIndex(indexName.indexOf(pathName));
        }

        const handleScroll = () => {
            setIsAtTop(window.scrollY <= 73);
        };

        window.addEventListener("scroll", handleScroll);

        const handleClickOutside = (event: any) => {
            if (!event.target || event.target.id !== "menu-bg") {
                setOpenMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        history.listen(({ location, action }) => {
            const pathName = location.pathname.split("/")[1];
            const index = indexName.indexOf(pathName || "");

            if (index !== -1) {
                props.setIndex(index);
            }
        });

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const changeTab = (index: number) => {
        props.setIndex(index);
        setOpenMenu(false);

        if (index === 0) {
            window.history.pushState({}, "", `/`);
        } else {
            window.history.pushState({}, "", `${indexName[index]}`);
        }
    };

    return (
        <div className={`sticky top-0 flex flex-row justify-between text-lg shadow-lg h-[73px] bg-[#A5BFD8] text-[17px] xl:text-[20px] font-[400] w-full z-50 select-none ${isAtTop ? 'opacity-100' : 'opacity-[96%]'}`} >

            <div className="lg:w-full flex flex-row md:pr-[1vw] 2xl:pr-[2vw] box-border">

                {/* Burger Menu */}
                <div className="flex flex-col items-start justify-center mx-[20px] lg:hidden w-full cursor-pointer" onClick={() => setOpenMenu(!openMenu)} id="menu-bg">
                    <div className="w-8 h-[4px] bg-[#727c8b] border border-[#8894a7]"></div>
                    <div className="w-8 h-[4px] bg-[#727c8b] border border-[#8894a7] mt-[6px]"></div>
                    <div className="w-8 h-[4px] bg-[#727c8b] border border-[#8894a7] mt-[6px]"></div>
                </div>

                {/* Left nav */}
                < div className="flex flex-row pt-1 md:pl-[2vw] 2xl:pl-[2vw] w-full" >

                    {/* Noms longs */}
                    <div className="w-full hidden 2xl:flex flex-row items-center justify-between">
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(1)}>{t('navbar.maison')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(2)}>{t('navbar.cabane')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(3)}>{t('navbar.maisonnette')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(4)}>{t('navbar.soins')}</p>
                    </div>

                    {/* Noms courts */}
                    <div className="w-full flex 2xl:hidden flex-row items-center justify-between">
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(1)}>{t('navbar.short.maison')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(2)}>{t('navbar.short.cabane')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(3)}>{t('navbar.short.maisonnette')}</p>
                        <p className="hidden lg:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(4)}>{t('navbar.short.soins')}</p>
                    </div>
                </div >
            </div>

            {/* Logo */}
            < div className="flex flex-row items-center pb-1 px-6" >
                <img src={Logo} alt="Logo" className="cursor-pointer w-[120px] min-w-[120px] xl:w-[140px] xl:min-w-[140px]" onClick={() => changeTab(0)} />
            </div >

            {/* Right nav */}
            < div className="hidden lg:flex flex-row items-center justify-between md:pl-[1vw] md:pr-[1vw] 2xl:pl-[2vw] 2xl:pr-[2vw] pt-1 w-full" >

                {/* Noms longs */}
                <p className="hidden 2xl:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(5)}>{t('navbar.histoire')}</p>
                <p className="hidden 2xl:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(6)}>{t('navbar.info')}</p>
                <p className="hidden 2xl:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(7)}>{t('navbar.milk')}</p>
                <p className="hidden 2xl:flex cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(8)}>{t('navbar.reseaux')}</p>

                {/* Noms courts */}
                <p className="hidden lg:flex 2xl:hidden cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(5)}>{t('navbar.short.histoire')}</p>
                <p className="hidden lg:flex 2xl:hidden cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(6)}>{t('navbar.short.info')}</p>
                <p className="hidden lg:flex 2xl:hidden cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(7)}>{t('navbar.short.milk')}</p>
                <p className="hidden lg:flex 2xl:hidden cursor-pointer opacity-70 hover:opacity-100 ff-zilla text-center" onClick={() => changeTab(8)}>{t('navbar.short.reseaux')}</p>
            </div >

            {/* Languages */}
            <LangSwitcher className="flex pr-4" />

            {/* Mobile menu */}
            {
                openMenu && <div className="absolute top-[73px] left-0 bg-white ff-king shadow-lg">
                    <ul className="px-3 py-4 min-w-[210px]" id="menu-bg">
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(1)}>{t('navbar.maison')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(2)}>{t('navbar.cabane')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(3)}>{t('navbar.maisonnette')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(4)}>{t('navbar.soins')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(5)}>{t('navbar.histoire')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(6)}>{t('navbar.info')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(7)}>{t('navbar.milk')}</li>
                        <li id="menu-bg" className="px-2 cursor-pointer opacity-80 hover:opacity-100 hover:font-bold" onClick={() => changeTab(8)}>{t('navbar.reseaux')}</li>
                    </ul>
                </div>
            }
        </div >
    );
}

export default Navbar;