import Porche from '../../assets/informations/porche.jpg';
import Terrasse from '../../assets/cabane/terrasse.jpg';
import Glycine from '../../assets/maisonnette/maisonnette.jpg';
import TarifsMaison from "../Shared/Tarifs/TarifsMaison";
import TarifsMas from "../Shared/Tarifs/TarifsMas";
import TarifsCabane from "../Shared/Tarifs/TarifsCabane";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import MaisonModel from '../../models/Maisons';
import InformationLinesMaison from './InformationLinesMaison';
import InformationLinesCabane from './InformationLinesCabane';
import InformationLinesMaisonnette from './InformationLinesMaisonnette';
import TarifsMaisonnette from '../Shared/Tarifs/TarifsMaisonnette';

interface IProps {
    mas: MaisonModel,
    maison: MaisonModel,
    cabane: MaisonModel,
    maisonnette: MaisonModel
}

const Informations = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-start w-full">

            <div className="px-6 sm:px-16 lg:px-32 xl:px-64 ff-king text-[32px] sm:text-[38px] md:text-[48px] xl:text-[50px] self-start pt-12 lg:pt-24 pb-10">{t('informations.titre')}</div>

            {/* Columns photos + information lines*/}
            <div className="w-full lg:px-16 xl:px-32 flex flex-col gap-20 text-[20px]">

                {/* Maison */}
                <div className="w-full flex flex-col xl:flex-row">
                    <div className="w-full px-6 sm:px-16 lg:px-0">
                        <img src={Porche} alt="porche" className="w-full h-full object-cover" />
                    </div>

                    <div className="w-full px-16 lg:pr-0 xl:px-16">
                        <InformationLinesMaison className="flex pt-[60px] lg:pt-5" />
                    </div>
                </div>

                {/* Cabane */}
                <div className="w-full flex flex-col-reverse xl:flex-row">
                    <div className="w-full px-6 sm:px-16 lg:px-0">
                        <InformationLinesCabane className="flex pt-[60px]" />
                    </div>

                    <div className="w-full px-16 lg:pr-0 xl:px-16">
                        <img src={Terrasse} alt="terrasse" className="flex w-[90%] aspect-square h-full object-cover" />
                    </div>
                </div>

                {/* Maisonette */}
                <div className="w-full flex flex-col xl:flex-row">
                    <div className="w-full px-6 sm:px-16 lg:px-0">
                        <img src={Glycine} alt="glycine" className="w-[92%] h-full object-cover" />
                    </div>

                    <div className="w-full px-16 lg:pr-0 xl:px-16">
                        <InformationLinesMaisonnette className="flex pt-[50px]" />
                    </div>
                </div>

            </div>


            {/* Columns texts*/}
            <div className="w-full lg:px-32 xl:px-64 flex flex-col lg:flex-row text-[20px] pt-[50px]">

                {/* Left */}
                <div className="w-full px-6 sm:px-16 lg:px-0">

                    {/* Situation */}
                    <div className="ff-king text-[28px] lg:text-[32px] pt-16 pb-4 lg:pb-0">{t('informations.situation.titre')}</div>
                    <div className="text-justify leading-7 text-[18px] lg:text-[20px]">
                        {t('informations.situation.description')}
                    </div>

                    {/* Commodités */}
                    <div className="ff-king text-[28px] lg:text-[32px] pt-12 pb-4 lg:pb-0 lg:pt-24">{t('informations.commodités.titre')}</div>
                    <div className="text-justify leading-7 text-[18px] lg:text-[20px]">
                        {t('informations.commodités.description')}
                    </div>

                    {/* Règlement */}
                    <div className="ff-king text-[32px] pt-20">{t('informations.annulation.titre2')}</div>
                    <div className="text-justify leading-7 pb-8">{t('informations.annulation.caution')}</div>

                    {/* Button Reservation */}
                    <div className="pt-20">
                        <button className="ff-king text-md sm:text-lg md:text-xl bg-[#A5BFD8] hover:bg-[#82b1dd] py-2 px-3" onClick={() => {
                            navigate('/reservation?house=0');
                        }}>{t('informations.reservation')}</button>
                    </div>

                </div>

                {/* Right */}
                <div className="w-full px-16 lg:pr-0 xl:px-16">

                    {/* Conditions */}
                    <div className="w-full pt-16 ">
                        <div className="ff-king text-[32px]">{t('informations.conditions.titre')}</div>
                        <div className="text-justify leading-7">
                            {t('informations.conditions.description')}
                        </div>
                    </div>

                    {/* Annulation */}
                    <div className="w-full pt-12 ">
                        <div className="ff-king text-[32px] pb-6 lg:pb-0">{t('informations.annulation.titre')}</div>
                        <div className="text-justify leading-7">
                            <ul style={{ listStyleType: 'disc' }} className="lg:pl-8 lg:py-6 xl:py-0">
                                <li className="mb-6">{t('informations.annulation.100')}</li>
                                <li className="mb-6">{t('informations.annulation.50')}</li>
                                <li className="mb-6">{t('informations.annulation.0')}</li>
                            </ul>
                            <p><b>{t('informations.annulation.in')}</b>{t('informations.annulation.heure1')}<b>{t('informations.annulation.out')}</b>{t('informations.annulation.heure2')}</p>
                        </div>
                    </div>

                    {/* Renseignements */}
                    <div className="pt-20">
                        <div className="pb-4">{t('informations.renseignements')}</div>
                        <div className="border-l-4 border-gray-300 ml-4 pl-4 flex flex-col">
                            <div className="pb-2">sorenecosmetiques@gmail.com</div>
                            <div>06 68 71 77 13</div>
                        </div>
                    </div>
                </div>

            </div>





            {/* Columns */}
            <div className="w-full mt-32 px-4 sm:px-8 md:px-16 2xl:px-32 flex flex-col items-center xl:items-start xl:flex-row text-[20px]">
                {/* <p className="self-start px-4 sm:px-16 md:px-32 2xl:px-64 pt-10 text-[18px] lg:text-[20px]"></p> */}

                {/* La Maison */}
                <div className="hidden xl:flex w-1/2 pr-8 pb-10">
                    <TarifsMaison
                        showTitle
                        showTaxes
                        showName
                        nights={props.maison?.periods.map((value) => value.minStay)}
                        prices={props.maison?.periods.map((value) => value.price)}
                    />
                </div>
                <div className="flex xl:hidden w-full lg:w-2/3 pr-1">
                    <TarifsMaison
                        showTitle
                        showTaxes
                        showName
                        nights={props.maison?.periods.map((value) => value.minStay)}
                        prices={props.maison?.periods.map((value) => value.price)}
                    />
                </div>

                {/* Le mas */}
                <div className="hidden xl:flex w-1/2 pr-8">
                    <TarifsMas
                        showName
                        setMinHeight
                        nights={props.mas?.periods.map((value) => value.minStay)}
                        prices={props.mas?.periods.map((value) => value.price)}
                    />
                </div>
                <div className="flex xl:hidden w-full lg:w-2/3 pr-1">
                    <TarifsMas
                        showName
                        nights={props.mas?.periods.map((value) => value.minStay)}
                        prices={props.mas?.periods.map((value) => value.price)}
                    />
                </div>
            </div>

            <div className="w-full px-4 sm:px-8 md:px-16 2xl:px-32 flex flex-col items-center xl:items-start xl:flex-row text-[20px]">
                {/* <p className="self-start px-4 sm:px-16 md:px-32 2xl:px-64 pt-10 text-[18px] lg:text-[20px]"></p> */}

                {/* La Maison */}
                <div className="hidden xl:flex w-1/2 pr-8 pb-10">
                    <TarifsCabane
                        showName
                        nights={props.cabane?.periods.map((value) => value.minStay)}
                        prices={props.cabane?.periods.map((value) => value.price)}
                    />
                </div>
                <div className="flex xl:hidden w-full lg:w-2/3 pr-1">
                    <TarifsCabane
                        showName
                        nights={props.cabane?.periods.map((value) => value.minStay)}
                        prices={props.cabane?.periods.map((value) => value.price)}
                    />
                </div>

                {/* Le mas */}
                <div className="hidden xl:flex w-1/2 pr-8">
                    <TarifsMaisonnette
                        showName
                        nights={props.maisonnette?.periods.map((value) => value.minStay)}
                        prices={props.maisonnette?.periods.map((value) => value.price)}
                    />
                </div>
                <div className="flex xl:hidden w-full lg:w-2/3 pr-1">
                    <TarifsMaisonnette
                        showName
                        nights={props.maisonnette?.periods.map((value) => value.minStay)}
                        prices={props.maisonnette?.periods.map((value) => value.price)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Informations;