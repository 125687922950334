import axios from 'axios';

class MaisonModel {
    _id: number | number;
    name: string;
    houseId: number;
    minCapacity: number;
    maxCapacity: number;
    description: string;
    periods: [{
        start: number,
        end: number,
        price: number,
        minStay: number
    }];

    constructor(raw?: any) {
        this._id = raw?._id || null;
        this.name = raw?.name || '';
        this.houseId = raw?.houseId ?? 0;
        this.minCapacity = raw?.minCapacity || 0;
        this.maxCapacity = raw?.maxCapacity || 0;
        this.description = raw?.description || '';
        this.periods = raw?.periods || [];
    }

    static maisons: MaisonModel[] = [];

    static init = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/maisons`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        this.maisons = [];
        for (let reservation of res.data || []) {
            this.maisons.push(new MaisonModel(reservation));
        }

        return this.maisons;
    }

    save = async () => {
        let res;

        if (this._id) {
            res = await axios.put(`${process.env.REACT_APP_API_URL}/maisons`, this, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        } else {
            const { _id, ...data } = this;
            res = await axios.post(`${process.env.REACT_APP_API_URL}/maisons`, data);
        }

        return res;
    }
}

export default MaisonModel;