interface IProps {
    deselectAll: () => void;
    blockDays: (block: boolean) => void;
}

const CalendarToolbar = (props: IProps) => {

    return (
        <div className="absolute w-full px-20 py-5 bottom-0 left-0 bg-white border-t-2 border-gray-300 flex justify-end items-center">
            <button className="bg-[#79819b] text-white rounded-lg px-8 py-3 mr-4 text-lg" onClick={() => props.deselectAll()}>Désélectionner tout</button>
            <p className="text-xl mr-6 ml-24">Disponible:</p>
            <button className="bg-[#515a75] text-white rounded-full mx-1" onClick={() => props.blockDays(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
            <button className="bg-[#515a75] text-white rounded-full mx-1" onClick={() => props.blockDays(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
        </div>
    );
};

export default CalendarToolbar;
