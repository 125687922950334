import axios from 'axios';

const checkAuth = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "credentials": "include",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });

        return res.data;
    } catch (error) {
        return null;
    }
};

const tryLogin = async (username, password) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "credentials": "include"
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        });
        const data = await res.json();

        localStorage.setItem('token', data.token);

        return true;
    } catch (error) {
        return false;
    }
};

export {
    checkAuth,
    tryLogin
}