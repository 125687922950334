const confidentialite = () => {

    return (
        <div className="px-48">
            <h1 className="ff-king text-4xl">Déclaration de confidentialité</h1>
            <p>Les seules données qu'on garde sont les données de contact pour la réservation</p>
        </div>
    );
};

export default confidentialite;
