import ReservationModel from "../../models/Reservations";
import StatusBadge from "./StatusBadge";

interface IProps {
    reservations: ReservationModel[];
    openDetails: (res: ReservationModel) => void;
}

const BookingLines = (props: IProps) => {
    return (
        <div>
            <div className="flex justify-between mt-4 border-b pb-4">

                {/* Table header */}
                <div className="flex-1 flex justify-around lg:justify-between items-end" >
                    <div className="hidden lg:flex w-1/12">Date de commande</div>
                    <div className="w-1/12">Lieu</div>
                    <div className="w-1/12">Arrivée</div>
                    <div className="w-1/12">Départ</div>
                    {/* <div className="w-2/12 hidden md:flex">Numéro de commande</div> */}
                    <div className="w-1/12 hidden md:flex">Payé</div>
                    <div className="w-1/12">Est un cadeau</div>
                    <div className="w-1/12">Code promo</div>
                    <div className="hidden lg:flex w-1/12">Nombre de personnes</div>
                    <div className="w-1/12">Statut</div>
                </div>
            </div>

            {
                props.reservations.map((res: ReservationModel, index: number) => {
                    return (
                        <div key={index} className="flex-1 flex justify-around lg:justify-between odd:bg-gray-100 items-center py-1 cursor-pointer hover:bg-gray-200" onClick={() => props.openDetails(res)}>
                            <div className="hidden lg:flex w-1/12">{res.formatedCreationDate() || "-"}</div>
                            <div className="w-1/12">{res.houseName() || "-"}</div>
                            <div className="w-1/12">{res.formatedArrivalDate() || "-"}</div>
                            <div className="w-1/12">{res.formatedDepartureDate() || "-"}</div>

                            {/* <div className="hidden lg:flex w-1/12">{`${res.duration()} jour${res.duration() > 1 ? 's' : ''}` || "-"}</div> */}
                            {/* <div className="w-2/12 hidden md:flex overflow-x-hidden">{`${res._id}`}</div> */}
                            <div className="w-1/12 flex overflow-x-hidden">{`€ ${res.totalPrice ?? '-'}`}</div>

                            <div className="w-1/12">{res.hasGift ? 'Oui' : 'Non'}</div>
                            <div className="w-1/12">{res.promocode || "-"}</div>


                            <div className="hidden lg:flex w-1/12">{`Adultes: ${res.adults}\nEnfants: ${res.children + res.babies}\n`}</div>

                            <div className="w-1/12 hidden md:flex">{<StatusBadge res={res} />}</div>
                            <div className="w-1/12 flex md:hidden">{<StatusBadge compact res={res} />}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BookingLines;