import { useTranslation } from 'react-i18next';
import Image from '../../assets/maison/bougeoir.jpg';

const Banner = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full relative">
            <img src={Image} alt="" className="h-[300px] md:h-[600px] lg:h-[100vh] w-full object-cover" />
            <div className='ff-king absolute opacity-80 text-white
                top-3 left-5 text-[22px]
                sm:top-8 sm:left-10 sm:text-3xl 
                lg:top-10 lg:left-16 lg:text-4xl 
                xl:top-16 xl:left-24 xl:text-[42px]
                2xl:top-16 2xl:left-24 2xl:text-5xl'>
                {t('maison.titre')}
            </div>
        </div>
    )
}

export default Banner;