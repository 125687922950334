import { useState } from "react";
import MaisonModel from "../../models/Maisons";
import HouseCard from "./HouseCard";

interface IProps {
    maisons: MaisonModel[];
}

const Tarifs = (props: IProps) => {
    const [index, setIndex] = useState<number>(0);

    return (
        <div className="p-16">
            <div className="text-4xl">Tarifs</div>

            <div className="py-16 flex">

                <div className="flex w-1/6 flex-col">
                    {
                        props.maisons.map((maison, houseIndex) => (
                            <div key={houseIndex} className={`cursor-pointer border-b px-14 py-3 ${houseIndex === index ? 'bg-gray-100' : 'hover:bg-gray-100'}`} onClick={() => setIndex(houseIndex)}>
                                {maison.name}
                            </div>
                        ))
                    }
                </div>

                <HouseCard house={props.maisons[index]} />

            </div>
        </div>
    )
}

export default Tarifs;