import Lac from '../../assets/cabane/lacVillefort.jpg';
import Gif from '../../assets/cabane/cabane.gif';
import Banner from "./Banner";
import TarifsCabane from "../Shared/Tarifs/TarifsCabane";

import Terrasse from '../../assets/cabane/terrasse.jpg';
import Hublot from '../../assets/cabane/litHublot.jpg';
import Champagne from '../../assets/cabane/champagne.jpg';
import Interieur from '../../assets/cabane/interieur.jpg';
import Fauteuil from '../../assets/cabane/fauteuil.jpg';
import TableBasse from '../../assets/cabane/tableBasse.png';
import Chevres from '../../assets/cabane/chevresCabane.jpg';
import Cracq from '../../assets/cabane/cracq.jpg';
import Poulie from '../../assets/cabane/poulie.jpg';

import Neige1 from '../../assets/cabane/neige1.jpeg';
import Affiche1 from '../../assets/cabane/affiche1.jpg';
import Lanterne from '../../assets/cabane/lanterne.jpg';
import Neige2 from '../../assets/cabane/neige2.jpeg';
import Affiche2 from '../../assets/cabane/affiche2.jpg';
import Couronne from '../../assets/cabane/couronne.jpg';


import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Carousel from '../Shared/Carousel';
import MaisonModel from '../../models/Maisons';

interface IProps {
    cabane?: MaisonModel;
}

const Cabane = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [imageIndex, setImageIndex] = useState<number | null>(null);

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner />
            <div className="w-full sm:px-8 lg:px-12 flex flex-col items-center lg:mb-16 ff-zilla leading-7 text-[18px] lg:text-[20px]">

                {/* First section */}
                <div className="flex flex-col xl:flex-row w-full gap-4 mt-10">


                    <div className="w-full xl:w-[62%] h-fit flex flex-col gap-2 md:gap-4 xl:gap-7 pt-8 sm:pt-32 px-4 sm:px-12 xl:pr-0">

                        {/* Introduction Cévennes */}
                        <div className="w-full grid grid-rows-1 grid-cols-2 sm:grid-cols-3 gap-2 md:gap-4 xl:gap-7 pb-8 sm:pb-0">
                            <img src={Lac} alt="Lac" className="hidden sm:flex w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(0)} />
                            <div className="col-span-2 text-justify grid">{t('cabane.description')}</div>
                        </div>

                        {/* Mobile image */}
                        <img src={Lac} alt="Lac" className="flex sm:hidden w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(0)} />

                        {/* Square images */}
                        <div className="w-full h-full grid grid-rows-3 sm:grid-rows-4 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            {/* Mobile valentine square */}

                            <img src={Terrasse} alt="Terrasse" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(1)} />
                            <img src={Hublot} alt="Hublot" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(2)} />
                            <img src={Champagne} alt="Champagne" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(3)} />
                            <img src={Interieur} alt="Interieur" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(4)} />
                            <img src={Fauteuil} alt="Fauteuil" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(5)} />
                            <img src={TableBasse} alt="TableBasse" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(6)} />
                            <img src={Chevres} alt="Chevres" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(7)} />
                            <img src={Cracq} alt="Cracq" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(8)} />
                            <img src={Poulie} alt="Poulie" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(9)} />
                            <img src={Neige2} alt="Neige" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(10)} />
                            <img src={Affiche2} alt="Affiche" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(11)} />
                            <img src={Couronne} alt="Couronne" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex(12)} />
                        </div>
                    </div>


                    {/* Video */}
                    <div className="w-full xl:w-[38%] flex flex-row-reverse xl:flex-col px-4 sm:px-12">
                        {/* <img src={Gif} alt="Gif" className="hidden md:flex w-1/3 xl:w-full pl-3 xl:pl-0 h-full object-cover" /> */}
                        <video muted controls className="hidden md:flex w-1/3 xl:w-full pl-3 xl:pl-0 h-full object-cover">
                            <source src="cabane.mov" type="video/mp4" />
                        </video>

                        <div className="w-full md:w-2/3 xl:w-full pr-3 xl:pr-0">
                            <div className="w-full h-min pt-6 py-2 text-justify">
                                {t('cabane.disposition')} (<a className="hover:underline text-blue-700" href="http://boutique.chevrerie-sorene.fr" target="_blank">boutique.chevrerie-sorene.fr</a>).
                            </div>
                            <div className="w-full h-min pb-3 text-justify">{t('cabane.condiments')}</div>
                            <div className="w-full h-min text-justify">
                                {t('cabane.localisation')}
                            </div>
                        </div>
                    </div>

                </div>
                <button className="self-start ff-king flex-row text-md sm:text-lg md:text-xl bg-[#A5BFD8] hover:bg-[#82b1dd] py-2 px-4 mt-12 mx-12" onClick={() => {
                    navigate('/reservation?house=2');
                }}>{t('cabane.reservation')}</button>

                <div className="w-full flex flex-col text-justify text-[16px] md:text-[20px] px-12 pt-16 " > {t('cabane.tarifs.remarque')} </div>

                {/* Images carousel */}
                {
                    imageIndex !== null &&
                    <Carousel
                        images={[Lac, Terrasse, Hublot, Champagne, Interieur, Fauteuil, TableBasse, Chevres, Cracq, Poulie, Neige2, Affiche2, Couronne]}
                        imageIndex={imageIndex}
                        close={() => { setImageIndex(null) }}
                    />
                }

                {/* Second section */}
                <div className="w-full flex flex-col items-center sm:px-6 md:px-12 lg:px-16 2xl:px-32 box-border pt-28">
                    <div className="w-full md:2/3 xl:w-1/2">
                        <TarifsCabane
                            showTitle
                            opening={t('cabane.tarifs.occupation')}
                            nights={props.cabane?.periods.map((value) => value.minStay)}
                            prices={props.cabane?.periods.map((value) => value.price)}
                        />
                    </div>
                    <div className="pt-12 text-[16px] md:text-[20px] px-4 text-center">{t('cabane.tarifs.menage')}</div>
                </div>
            </div>
        </div>
    );
}

export default Cabane;