import { useRef, useState } from "react";
import Banner from "./Banner";
import Glycine from '../../assets/maisonnette/maisonnette.jpg';
import Separator from "./Separator";

import Vignette1 from '../../assets/maison/vignette1.jpg';
import Vignette2 from '../../assets/maison/vignette2.jpg';
import Vignette3 from '../../assets/maison/vignette3.jpg';

import Rose from '../../assets/maisonnette/chambres/chambre2/rose.jpg';
import Cheminee from '../../assets/maisonnette/chambres/chambre2/cheminee.jpg';
import Douche from '../../assets/maisonnette/chambres/chambre2/douche.jpg';
import Sdb from '../../assets/maisonnette/chambres/chambre2/sallebain.jpg';
import Nuages from '../../assets/maisonnette/chambres/chambre2/nuage.jpg';

import Lit1 from '../../assets/maisonnette/chambres/chambre1/lit_blanc.jpg';
import Ensemble from '../../assets/maisonnette/chambres/chambre1/vue_ensemble.jpg';
import Osier from '../../assets/maisonnette/chambres/chambre1/osier1.jpeg';
import Vue from '../../assets/maisonnette/chambres/chambre1/vue1.jpeg';


import Cuisine from '../../assets/maisonnette/espaces/rdc/cuisine.jpg';
import Rideau from '../../assets/maisonnette/espaces/rdc/salon_rideau.jpeg';
import Miroir from '../../assets/maisonnette/espaces/rdc/miroir.jpg';
import Frigo from '../../assets/maisonnette/espaces/rdc/frigo.jpg';
import Baignoire from '../../assets/maisonnette/espaces/rdc/baignoire.jpg';
import Salle_manger from '../../assets/maisonnette/espaces/rdc/salle_manger.jpeg';
import Cafe from '../../assets/maisonnette/espaces/rdc/cafe.jpeg';
import Vinyle from '../../assets/maisonnette/espaces/rdc/vinyle.jpeg';
import Panier from '../../assets/maisonnette/espaces/rdc/panier.jpeg';


import Grenier from '../../assets/maisonnette/espaces/grenier/grenier_grand.jpg';
import Malle from '../../assets/maisonnette/espaces/grenier/malle.jpg';
import Lit from '../../assets/maisonnette/espaces/grenier/salontv.jpeg';
import Transats from '../../assets/maisonnette/espaces/grenier/chaise_longue.jpg';
import Tele from '../../assets/maisonnette/espaces/grenier/tele1.jpeg';
import Mikado from '../../assets/maisonnette/espaces/grenier/mikado.jpeg';

import Figuier from '../../assets/maisonnette/exterieurs/plante.jpg';
import Terrasse1 from '../../assets/maisonnette/exterieurs/terrasse1-1.jpg';
import Verres from '../../assets/maisonnette/exterieurs/verres.jpg';
import Garage from '../../assets/maisonnette/exterieurs/garage2.jpg';
import Terrasse2 from '../../assets/maisonnette/exterieurs/terrasse2-1.jpg';
import Jardin1 from '../../assets/maisonnette/exterieurs/jardin_grand.jpeg';
import Jardin2 from '../../assets/maisonnette/exterieurs/jardin2.jpeg';
import Abri1 from '../../assets/maisonnette/exterieurs/abri1.jpg';
import Chaise from '../../assets/maisonnette/exterieurs/chaise_jardin1.jpeg';
import Chaise2 from '../../assets/maisonnette/exterieurs/chaise_jardin2.jpeg';
import Visage from '../../assets/maisonnette/exterieurs/visage.jpeg';
import Vans from '../../assets/maisonnette/exterieurs/vans.jpeg';




import TarifsMaisonnette from "../Shared/Tarifs/TarifsMaisonnette";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Carousel from "../Shared/Carousel";
import MaisonModel from "../../models/Maisons";

interface IProps {
    maison?: MaisonModel,
    mas?: MaisonModel
}

const Maisonnette = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const separatorRef = useRef(null);
    const separatorRef2 = useRef(null);
    const separatorRef3 = useRef(null);
    const separatorRef4 = useRef(null);

    const [imageIndex1, setImageIndex1] = useState<number | null>(null);
    const [imageIndex2, setImageIndex2] = useState<number | null>(null);
    const [imageIndex3, setImageIndex3] = useState<number | null>(null);
    const [imageIndex4, setImageIndex4] = useState<number | null>(null);
    const [imageIndex5, setImageIndex5] = useState<number | null>(null);
    const [imageIndex6, setImageIndex6] = useState<number | null>(null);

    // Scroll to ref smoothly
    const scrollToRef = (ref: any) => {
        window.scrollTo({
            top: ref.current.offsetTop - 73,
            behavior: "smooth"
        });
    }

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner />
            <div className="w-full sm:px-8 lg:px-32 flex flex-col items-center lg:mb-16 text-[22px] ff-zilla leading-7 text-justify">

                {/* ======================================================================================= */}
                {/* Index */}
                {/* ======================================================================================= */}
                <div className="flex flex-col lg:flex-row w-full xl:gap-36 mt-16 px-8 md:px-0 xl:px-8 2xl:pl-24 2xl:pr-24">

                    <div className="w-full bg-red-500 lg:w-1/2">
                        <img src={Glycine} alt="Glycine" className="w-full h-full object-cover" />
                    </div>

                    <div className="w-full flex flex-col justify-between lg:w-1/2 pt-12 sm:pt-6 lg:pt-0 xl:pt-12 pl-0 lg:pl-12 xl:p-0 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                        <div>
                            <p>{t('maisonnette.description1')}</p><br />
                            <p>{t('maisonnette.description2')}</p><br />
                            <p>{t('maisonnette.description3')}</p><br />
                            <p>{t('maisonnette.description4')}</p>
                        </div>

                        <div className="flex flex-col ff-king w-full sm:w-2/3 md:w-1/2 lg:w-2/3 md:pl-8">
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef)}>{t('maison.sections.chambres')}</div>
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef2)}>{t('maison.sections.espaces')}</div>
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef4)}>{t('maison.sections.exterieurs')}</div>
                        </div>
                    </div>
                </div>

                {/* Separator */}
                <div ref={separatorRef} className="w-full hidden md:flex">
                    <Separator vignette={Vignette1} />
                </div>

                {/* ======================================================================================= */}
                {/* Des chambres à rêver */}
                {/* Chambre 1 */}
                {/* ======================================================================================= */}
                <div className="hidden xl:flex flex-row w-full mt-24 md:mt-10 px-12">

                    {/* Large image mobile */}
                    {/* <img src={Nuages} alt="Nuages" className="flex xl:hidden w-full object-cover object-center pt-6 cursor-pointer" onClick={() => setImageIndex1(4)} /> */}

                    {/* Square images */}
                    <div className="grid grid-rows-[100px_repeat(2,_13vw)] grid-cols-[repeat(3,_13vw)_0vw_minmax(0,_1000px)] gap-2 md:gap-4 xl:gap-7">
                        <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section1.titre')}</p>
                        <div className="row-start-2 row-span-1 col-start-1 col-span-1 w-full h-full aspect-square">
                            <img src={Rose} alt="Rose" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(0)} />
                        </div>
                        <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                            <img src={Cheminee} alt="cheminée" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(1)} />
                        </div>
                        <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                            <img src={Douche} alt="Douche" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(2)} />
                        </div>
                        <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                            <img src={Sdb} alt="Salle de bain" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(3)} />
                        </div>

                        {/* Large image */}
                        <div className="row-start-1 row-end-4 col-start-5 h-full w-full aspect-square" style={{ contain: 'size' }}>
                            <img src={Nuages} alt="Nuages" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex1(4)} />
                        </div>
                    </div>

                    {/* Carousel chambre 1 */}
                    {
                        imageIndex1 !== null &&
                        <Carousel
                            images={[Rose, Cheminee, Douche, Sdb, Nuages]}
                            imageIndex={imageIndex1}
                            close={() => { setImageIndex1(null) }}
                        />
                    }
                </div>

                {/* Mobile */}
                <div className="grid xl:hidden grid-rows-[100px_minmax(0,_1000px)_21vw)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] gap-2 md:gap-4 xl:gap-7 mt-8">
                    <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section1.titre')}</p>
                    <div className="row-start-2 row-span-1 col-start-1 col-span-3 w-full h-full aspect-square">
                        <img src={Nuages} alt="Nuages" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex1(4)} />
                    </div>
                    <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                        <img src={Cheminee} alt="cheminée" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(1)} />
                    </div>
                    <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                        <img src={Douche} alt="Douche" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(2)} />
                    </div>
                    <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                        <img src={Sdb} alt="Salle de bain" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex1(3)} />
                    </div>
                </div>


                {/* ======================================================================================= */}
                {/* Des chambres à rêver */}
                {/* Chambre 2 */}
                {/* ======================================================================================= */}
                <div className="flex flex-row w-full gap-8 mt-8 lg:mt-20 px-12">

                    {/* Images */}
                    <div className="hidden xl:grid grid-rows-[auto-fill_13vw] grid-cols-[minmax(0,_1000px)_1vw_repeat(3,_13vw)] w-full gap-2 md:gap-5 lg:gap-4">

                        {/* Large image */}
                        <div className="row-start-1 row-span-2 col-start-1 col-span-1 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Vue} alt="Fenetre" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex2(0) }} />
                        </div>

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-3 col-span-3 w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px] 2xl:pr-[30%] 2xl:pl-4">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.chambre2')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.chambre1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.amenities')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Lit1} alt="Lit1" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex2(1) }} />
                        </div>
                        <div className="row-start-2 col-start-4 w-full h-full aspect-square">
                            <img src={Ensemble} alt="Ensemble" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex2(2) }} />
                        </div>
                        <div className="row-start-2 col-start-5 w-full h-full aspect-square">
                            <img src={Osier} alt="Salle de bain" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex2(3) }} />
                        </div>
                    </div>

                    {/* Carousel chambre 2 */}
                    {
                        imageIndex2 !== null &&
                        <Carousel
                            images={[Vue, Lit1, Ensemble, Osier]}
                            imageIndex={imageIndex2}
                            close={() => { setImageIndex2(null) }}
                        />
                    }
                </div>

                {/* Mobile */}
                <div className="flex justify-center">
                    <div className="grid xl:hidden grid-rows-[auto_29vw_minmax(0,_1000px)] md:grid-rows-[auto_21vw_minmax(0,_1000px)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] w-full gap-2 md:gap-4 xl:gap-7">

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-1 col-span-3 w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.chambre2')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.chambre1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section1.amenities')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-1 w-full h-full aspect-square">
                            <img src={Lit1} alt="Lit1" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex2(1) }} />
                        </div>
                        <div className="row-start-2 col-start-2 w-full h-full aspect-square">
                            <img src={Ensemble} alt="Ensemble" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex2(2) }} />
                        </div>
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Osier} alt="Salle de bain" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex2(3) }} />
                        </div>

                        {/* Large image */}
                        <div className="row-start-3 row-span-1 col-start-1 col-span-3 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Vue} alt="Fenetre" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex2(0) }} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                {/* <img src={Nuage} alt="Nuage" className="flex xl:hidden w-full object-cover p-8 pt-4 md:pt-7 pb-0 px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex2(0)} /> */}


                {/* Separator */}
                <div ref={separatorRef2} className="w-full">
                    <Separator vignette={Vignette2} />
                </div>


                {/* ======================================================================================= */}
                {/* Des espaces à vivre */}
                {/* Section 1 */}
                {/* ======================================================================================= */}

                {/* Large image mobile */}
                {/* <img src={Salle_manger} alt="Salle à manger" className="flex lg:hidden w-full object-cover object-center px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex3(0)} /> */}

                <div className="hidden xl:grid grid-rows-[100px_repeat(3,_13vw)] grid-cols-[repeat(3,_13vw)_0vw_minmax(0,_1000px)] gap-2 md:gap-4 xl:gap-7 px-12">

                    {/* Titre */}
                    <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section2.titre')}</p>

                    {/* Image d'en-tête */}
                    <div className="row-start-2 row-span-1 col-start-1 col-span-1 w-full h-full aspect-square">
                        <img src={Cafe} alt="Evier" className="w-full aspect-square object-cover hidden lg:flex cursor-pointer" onClick={() => setImageIndex3(1)} />
                    </div>

                    {/* Square images */}
                    <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                        <img src={Rideau} alt="Cuisine" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(2)} />
                    </div>
                    <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                        <img src={Vinyle} alt="Rideau" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(3)} />
                    </div>
                    <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                        <img src={Miroir} alt="Miroir" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(4)} />
                    </div>
                    <div className="row-start-4 col-start-1 w-full h-full aspect-square">
                        <img src={Cuisine} alt="Frigo" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(5)} />
                    </div>
                    <div className="row-start-4 col-start-2 w-full h-full aspect-square">
                        <img src={Frigo} alt="Baignoire" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(6)} />
                    </div>
                    <div className="row-start-4 col-start-3 h-full w-full aspect-square">
                        <img src={Baignoire} alt="Lavabo" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(7)} />
                    </div>

                    {/* Large image */}
                    <div className="row-start-2 row-end-5 col-start-5 h-full w-full aspect-square" style={{ contain: 'size' }}>
                        <img src={Salle_manger} alt="Salle à manger" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex3(0)} />
                    </div>

                    {/* Carousel */}
                    {
                        imageIndex3 !== null &&
                        <Carousel
                            images={[Salle_manger, Cafe, Rideau, Vinyle, Miroir, Cuisine, Frigo, Baignoire]}
                            imageIndex={imageIndex3}
                            close={() => { setImageIndex3(null) }}
                        />
                    }

                </div>

                {/* Mobile */}
                <div className="grid xl:hidden grid-rows-[100px_minmax(0,_1000px)_repeat(2,_29vw)] md:grid-rows-[100px_minmax(0,_1000px)_repeat(2,_21vw)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] gap-2 md:gap-4 xl:gap-7">
                    <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section2.titre')}</p>
                    <div className="row-start-2 row-span-1 col-start-1 col-span-3 w-full h-full aspect-square">
                        <img src={Salle_manger} alt="Salle à manger" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex3(0)} />
                    </div>

                    <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                        <img src={Rideau} alt="Rideau" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(2)} />
                    </div>
                    <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                        <img src={Vinyle} alt="Vinyle" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(3)} />
                    </div>
                    <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                        <img src={Miroir} alt="Miroir" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(4)} />
                    </div>

                    <div className="row-start-4 col-start-1 w-full h-full aspect-square">
                        <img src={Cuisine} alt="Cuisine" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(5)} />
                    </div>
                    <div className="row-start-4 col-start-2 w-full h-full aspect-square">
                        <img src={Frigo} alt="Frigo" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(6)} />
                    </div>
                    <div className="row-start-4 col-start-3 h-full w-full aspect-square">
                        <img src={Baignoire} alt="Baignoire" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex3(7)} />
                    </div>
                </div>


                {/* ======================================================================================= */}
                {/* Des espaces à vivre */}
                {/* Section 2 */}
                {/* ======================================================================================= */}
                <div className="flex flex-row w-full gap-8 mt-8 lg:mt-20 px-12">

                    {/* Images */}
                    <div className="hidden xl:grid grid-rows-[auto-fill_13vw] grid-cols-[minmax(0,_1000px)_0vw_repeat(3,_13vw)] w-full gap-2 md:gap-5 lg:gap-7">

                        {/* Large image */}
                        <div className="row-start-1 row-span-2 col-start-1 col-span-1 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Tele} alt="Grenier" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex4(0) }} />
                        </div>

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-3 col-span-3 pr-[30%] w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px] 2xl:pr-[30%] 2xl:pl-4">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.rdc')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.sdb')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.etage1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.etage2')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Malle} alt="Malle" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex4(1) }} />
                        </div>
                        <div className="row-start-2 col-start-4 w-full h-full aspect-square">
                            <img src={Mikado} alt="Mikado" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex4(2) }} />
                        </div>
                        <div className="row-start-2 col-start-5 w-full h-full aspect-square">
                            <img src={Lit} alt="Lit" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex4(3) }} />
                        </div>
                    </div>

                    {/* Carousel espaces à vivre section 2 */}
                    {
                        imageIndex4 !== null &&
                        <Carousel
                            images={[Tele, Malle, Mikado, Lit]}
                            imageIndex={imageIndex4}
                            close={() => { setImageIndex4(null) }}
                        />
                    }
                </div>

                {/* Mobile */}
                <div className="flex justify-center">
                    <div className="grid xl:hidden grid-rows-[auto_29vw_minmax(0,_1000px)] md:grid-rows-[auto_21vw_minmax(0,_1000px)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] w-full gap-2 md:gap-4 xl:gap-7">

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-1 col-span-3 w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.rdc')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.sdb')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.etage1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section2.etage2')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-1 w-full h-full aspect-square">
                            <img src={Malle} alt="Malle" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex4(1) }} />
                        </div>
                        <div className="row-start-2 col-start-2 w-full h-full aspect-square">
                            <img src={Mikado} alt="Lit" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex4(2) }} />
                        </div>
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Lit} alt="Transats" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex4(3) }} />
                        </div>

                        {/* Large image */}
                        <div className="row-start-3 row-span-1 col-start-1 col-span-3 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Tele} alt="Grenier" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex4(0) }} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                {/* <img src={Grenier} alt="Grenier" className="flex xl:hidden w-full object-cover p-8 pt-4 md:pt-7 pb-0 px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex4(0)} /> */}

                {/* Separator */}
                <div ref={separatorRef4} className="w-full">
                    <Separator vignette={Vignette3} />
                </div>


                {/* ======================================================================================= */}
                {/* Les exterieurs */}
                {/* Section 1 */}
                {/* ======================================================================================= */}

                <div className="hidden xl:flex flex-row w-full mt-24 md:mt-10 px-12">

                    {/* Large image mobile */}
                    {/* <img src={Nuages} alt="Nuages" className="flex xl:hidden w-full object-cover object-center pt-6 cursor-pointer" onClick={() => setImageIndex1(4)} /> */}

                    {/* Square images */}
                    <div className="grid grid-rows-[100px_repeat(2,_13vw)] grid-cols-[repeat(3,_13vw)_0vw_minmax(0,_1000px)] gap-2 md:gap-4 xl:gap-7">
                        <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section3.titre')}</p>
                        <div className="row-start-2 row-span-1 col-start-1 col-span-1 w-full h-full aspect-square">
                            <img src={Figuier} alt="Figuier" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(1)} />
                        </div>
                        <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                            <img src={Chaise} alt="Terrasse" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(2)} />
                        </div>
                        <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                            <img src={Visage} alt="Barbecue" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(3)} />
                        </div>
                        <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                            <img src={Chaise2} alt="Garage" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(4)} />
                        </div>

                        {/* Large image */}
                        <div className="row-start-1 row-end-4 col-start-5 h-full w-full aspect-square" style={{ contain: 'size' }}>
                            <img src={Jardin1} alt="jardin" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex5(0)} />
                        </div>
                    </div>

                    {/* Carousel chambre 1 */}
                    {
                        imageIndex5 !== null &&
                        <Carousel
                            images={[Jardin1, Figuier, Chaise, Visage, Chaise2]}
                            imageIndex={imageIndex5}
                            close={() => { setImageIndex5(null) }}
                        />
                    }
                </div>

                {/* Mobile */}
                <div className="grid xl:hidden grid-rows-[100px_minmax(0,_1000px)_29vw)] md:grid-rows-[100px_minmax(0,_1000px)_21vw)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] gap-2 md:gap-4 xl:gap-7">
                    <p className="col-start-1 col-span-3 ff-king text-[20px] lg:text-[25px]">{t('maisonnette.section3.titre')}</p>
                    <div className="row-start-2 row-span-1 col-start-1 col-span-3 w-full h-full aspect-square">
                        <img src={Jardin1} alt="Jardin" className="object-cover object-center cursor-pointer w-full h-full" onClick={() => setImageIndex5(0)} />
                    </div>
                    <div className="row-start-3 col-start-1 w-full h-full aspect-square">
                        <img src={Chaise} alt="Chaise" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(2)} />
                    </div>
                    <div className="row-start-3 col-start-2 w-full h-full aspect-square">
                        <img src={Visage} alt="Visage" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(3)} />
                    </div>
                    <div className="row-start-3 col-start-3 h-full w-full aspect-square">
                        <img src={Chaise2} alt="Chaise" className="object-cover cursor-pointer h-full w-full" onClick={() => setImageIndex5(4)} />
                    </div>
                </div>

                {/* ======================================================================================= */}
                {/* Les exterieurs */}
                {/* Section 2 */}
                {/* ======================================================================================= */}
                <div className="flex flex-row w-full gap-8 mt-8 lg:mt-20 px-12">

                    {/* Images */}
                    <div className="hidden xl:grid grid-rows-[auto-fill_13vw] grid-cols-[minmax(0,_1000px)_1vw_repeat(3,_13vw)] w-full gap-2 md:gap-5 lg:gap-4">

                        {/* Large image */}
                        <div className="row-start-1 row-span-2 col-start-1 col-span-1 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Terrasse1} alt="Terrasse" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex6(0) }} />
                        </div>

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-3 col-span-3 w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px] 2xl:pr-[30%] 2xl:pl-4">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.terrasse1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.terrasse2')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.jardin')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Terrasse2} alt="Terrasse" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex6(1) }} />
                        </div>
                        <div className="row-start-2 col-start-4 w-full h-full aspect-square">
                            <img src={Abri1} alt="Abri" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex6(2) }} />
                        </div>
                        <div className="row-start-2 col-start-5 w-full h-full aspect-square">
                            <img src={Garage} alt="Hammac" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex6(3) }} />
                        </div>
                    </div>

                    {/* Carousel chambre 2 */}
                    {
                        imageIndex6 !== null &&
                        <Carousel
                            images={[Terrasse1, Terrasse2, Abri1, Garage]}
                            imageIndex={imageIndex6}
                            close={() => { setImageIndex6(null) }}
                        />
                    }
                </div>

                {/* Mobile */}
                <div className="flex justify-center">
                    <div className="grid xl:hidden grid-rows-[auto_29vw_minmax(0,_1000px)] md:grid-rows-[auto_21vw_minmax(0,_1000px)] grid-cols-[repeat(3,_29vw)] md:grid-cols-[repeat(3,_21vw)] w-full gap-2 md:gap-4 xl:gap-7">

                        {/* Description */}
                        <div className="row-start-1 row-span-1 col-start-1 col-span-3 w-full h-full text-justify pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.terrasse1')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.terrasse2')}</p>< br />
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maisonnette.section3.jardin')}</p>
                        </div>

                        {/* Square images */}
                        <div className="row-start-2 col-start-1 w-full h-full aspect-square">
                            <img src={Terrasse2} alt="Terrasse" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex6(1) }} />
                        </div>
                        <div className="row-start-2 col-start-2 w-full h-full aspect-square">
                            <img src={Abri1} alt="Abri" className="w-full h-full object-cover cursor-pointer" onClick={() => { setImageIndex6(2) }} />
                        </div>
                        <div className="row-start-2 col-start-3 w-full h-full aspect-square">
                            <img src={Garage} alt="Hammac" className="w-full h-full object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex6(3) }} />
                        </div>

                        {/* Large image */}
                        <div className="row-start-3 row-span-1 col-start-1 col-span-3 w-full h-full max-w-full max-h-full" style={{ contain: 'size' }}>
                            <img src={Terrasse1} alt="Terrasse" className="w-full h-full overflow-y-hidden object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex6(0) }} />
                        </div>
                    </div>
                </div>

                <button className="self-start ff-king text-md sm:text-lg md:text-xl bg-[#A5BFD8] hover:bg-[#82b1dd] px-4 py-2 mt-20 mx-8 lg:mx-12" onClick={() => {
                    navigate('/reservation?house=3');
                }}>{t('maisonnette.reservation')}</button>

                <div className="w-full flex flex-col items-center sm:px-6 md:px-12 lg:px-16 2xl:px-32 box-border">
                    <div className="mt-28 px-8">
                        <div className="ff-king text-[25px]">{t('maisonnette.tarifs.titre')}</div>
                        <div className="text-[16px] md:text-[20px]">{t('maisonnette.tarifs.occupation')}</div>
                    </div>

                    <div className="w-full flex flex-col xl:flex-row gap-2 justify-center">
                        <div className="w-full md:2/3 xl:w-1/2">
                            <TarifsMaisonnette
                                showName
                                showSurface
                                nights={props.maison?.periods.map((value) => value.minStay)}
                                prices={props.maison?.periods.map((value) => value.price)}
                            />
                        </div>
                    </div>

                    <div className="pt-12 text-[16px] md:text-[20px] px-4 text-center">{t('maisonnette.tarifs.menage')}</div>
                </div>
            </div >
        </div >
    );
}

export default Maisonnette;