import { useTranslation } from "react-i18next";

interface IProps {
    className?: string
}

const InformationLinesMaison = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={`w-full ${props.className} flex-col`}>
            <p><b>{t('informations.lignes.maison')}</b>{t('informations.lignes.surface1')}</p>
            <div className="border-l-4 border-gray-300 ml-4 pl-4">
                <p><b>{t('informations.lignes.mas')}</b>{t('informations.lignes.surface2')}</p>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.rdc')}</div>
                    <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                        <div>{t('informations.lignes.salon')}</div>
                        <div>{t('informations.lignes.baignoire')}</div>
                        <div>{t('informations.lignes.wc')}</div>
                    </div>
                    <div className="pt-4">{t('informations.lignes.etage')}</div>
                    <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                        <div>{t('informations.lignes.douche')}</div>
                        <div>{t('informations.lignes.cinéma')}</div>
                    </div>
                    <div className="pt-4">{t('informations.lignes.exterieur')}</div>
                </div>

                <p className="pt-2"><b>{t('informations.lignes.studio')}</b>{t('informations.lignes.surface3')}</p>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.cuisine')}</div>
                    <div>{t('informations.lignes.sdb')}</div>
                </div>

                <div className="pt-4">{t('informations.lignes.terrasse')}</div>
            </div>

            <p className="pt-4 pb-6"><b>{t('informations.lignes.location')}</b></p>

            <p className="pt-4"><b>{t('informations.lignes.capacite1')}</b></p>
            <p className=""><b>{t('informations.lignes.capacite2')}</b></p>
            <p className="pb-4">{t('informations.lignes.enfants')}</p>

            <div className="">{t('informations.lignes.parking1')}</div>
            <div>{t('informations.lignes.animaux1')}</div>
            <div>{t('informations.lignes.wifi1')}</div>
        </div>
    )
}

export default InformationLinesMaison;