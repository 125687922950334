import axios from 'axios';
import { DateTime } from "luxon";

class ReservationModel {
    _id: number | number;
    arrival: DateTime;
    departure: DateTime;
    adults: number;
    children: number;
    babies: number;
    animals: number;
    house: number;
    totalPrice: number;
    contact: {
        name: string,
        email: string,
        phone: string
    };
    comments: string;
    validated: boolean;
    cancelled: boolean;
    external: boolean;
    external_src: string;
    hasGift: Boolean;
    promocode: string;
    gift: {
        isGift: boolean;
        name: string;
        email: string;
        message: string;
        nights: number;
    };
    blocked: boolean;
    createdAt: DateTime;

    constructor(raw?: any) {
        this._id = raw?._id || null;
        this.arrival = DateTime.fromISO(raw?.arrival) || DateTime.now();
        this.departure = DateTime.fromISO(raw?.departure) || DateTime.now();
        this.adults = raw?.adults || 0;
        this.children = raw?.children || 0;
        this.babies = raw?.babies || 0;
        this.animals = raw?.animals || 0;
        this.house = raw?.house || 0;
        this.totalPrice = raw?.totalPrice;
        this.contact = {
            name: raw?.contact?.name || '',
            email: raw?.contact?.email || '',
            phone: raw?.contact?.phone || ''
        };
        this.comments = raw?.comments || '';
        this.validated = raw?.validated || false;
        this.cancelled = raw?.cancelled || false;
        this.external = raw?.external || false;
        this.external_src = raw?.external_src || '';
        this.hasGift = raw?.hasGift || false;
        this.promocode = raw?.promocode || '';
        this.gift = {
            isGift: raw?.gift?.isGift || false,
            name: raw?.gift?.name || '',
            email: raw?.gift?.email || '',
            message: raw?.gift?.message || '',
            nights: raw?.gift?.nights || 0
        }
        this.blocked = raw?.blocked || false;
        this.createdAt = DateTime.fromISO(raw?.createdAt) || DateTime.now();
    }

    static reservations: ReservationModel[] = [];

    static init = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/reservations`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        this.reservations = [];
        for (let reservation of res.data || []) {
            this.reservations.push(new ReservationModel(reservation));
        }

        return this.reservations;
    }

    static get = async (id: string) => {
        if (!id) {
            return null;
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/reservations/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        return new ReservationModel(res.data);
    }

    dateToMongo = (date: DateTime) => {
        return `${date.year}-${date.month}-${date.day}`;
    }

    save = async () => {
        console.log("this", this);
        let res;

        if (this._id) {
            res = await axios.put(`${process.env.REACT_APP_API_URL}/reservations`, {
                ...this,
                arrival: this.dateToMongo(this.arrival),
                departure: this.dateToMongo(this.departure),
                createdAt: this.dateToMongo(this.createdAt)
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        } else {
            const { _id, ...data } = this;
            res = await axios.post(`${process.env.REACT_APP_API_URL}/reservations`, {
                ...data,
                arrival: this.dateToMongo(this.arrival),
                departure: this.dateToMongo(this.departure),
                createdAt: this.dateToMongo(DateTime.now())
            });
        }

        return res;
    }

    formatedCreationDate = () => {
        if (!this.createdAt) return null;
        const dt = DateTime.fromISO(this.createdAt.toString());

        return dt.toLocaleString(DateTime.DATE_MED);
    }

    formatedArrivalDate = () => {
        if (!this.arrival) return null;
        const dt = DateTime.fromISO(this.arrival.toString());

        return dt.toLocaleString(DateTime.DATE_MED);
    }

    formatedDepartureDate = () => {
        if (!this.departure) return null;
        const dt = DateTime.fromISO(this.departure.toString());

        return dt.toLocaleString(DateTime.DATE_MED);
    }

    houseName = () => {
        return this.house === 0 ? 'Le Mas' : this.house === 1 ? 'La Maison' : 'La Cabane';
    }

    duration = () => {
        if (!this.arrival || !this.departure) return 0;

        const start = this.arrival.startOf('day');
        const end = this.departure.startOf('day');

        return end.diff(start, 'days').days;
    }

    cancel = async () => {
        const updated = { ...this, cancelled: true, validated: false };
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/reservations`, updated, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status === 200) {
            this.cancelled = true;
            this.validated = false;
        }

        return res;
    }

    validate = async () => {
        const updated = { ...this, cancelled: false, validated: true };
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/reservations`, updated, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status === 200) {
            this.cancelled = false;
            this.validated = true;
        }

        return res;
    }
}

export default ReservationModel;