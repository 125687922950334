import { useRef } from "react";
import Banner from "./Banner";
import Separator from "../Maison/Separator";

import Reveil from "../../assets/soins/reveil.jpg";
import Proj from "../../assets/soins/projecteurRECAD.png";
import Marquise from "../../assets/soins/marquise.jpg";
import Confitures from "../../assets/soins/confitures.jpg";
import Aline from "../../assets/soins/aline.jpg";
import Dessert from "../../assets/soins/dessert.jpg";
import Yoga from "../../assets/soins/yoga.jpg";
import Savon from "../../assets/soins/cosmetiques/savon.jpg";
import Shampoing from "../../assets/soins/cosmetiques/shampoing.png";
import Baumes from "../../assets/soins/cosmetiques/baumes.jpeg";
import Jour from "../../assets/soins/cosmetiques/jour.jpg";
import Nuit from "../../assets/soins/cosmetiques/cremes.png";
import Lait from "../../assets/soins/cosmetiques/lait.png";
import { useTranslation } from "react-i18next";

const Soins = () => {
    const { t, i18n } = useTranslation();
    const separatorRef = useRef(null);
    const separatorRef2 = useRef(null);
    const separatorRef3 = useRef(null);
    const separatorRef4 = useRef(null);
    const separatorRef5 = useRef(null);

    // Scroll to ref smoothly
    const scrollToRef = (ref: any) => {
        window.scrollTo({
            top: ref.current.offsetTop - 73,
            behavior: "smooth"
        });
    }

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner scrollToRef={scrollToRef} ref1={separatorRef} ref2={separatorRef2} ref3={separatorRef3} ref4={separatorRef4} ref5={separatorRef5} />
            <div className="w-full px-4 sm:px-8 lg:px-32 2xl:px-[350px] flex flex-col items-center lg:mb-16">

                {/* Mobile menu */}
                <div className="flex flex-col w-[230px] sm:w-[280px] sm:text-[20px] ff-king self-start py-4">
                    <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef)}>{t('soins.sections.lecture')}</div>
                    <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef4)}>{t('soins.sections.massages')}</div>
                    <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef5)}>{t('soins.sections.yoga')}</div>
                    <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef2)}>{t('soins.sections.chevre')}</div>
                    {/* <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef3)}>{t('soins.sections.aline')}</div> */}
                    <div className="flex md:hidden py-3 sm:py-4 cursor-pointer hover:font-bold border-b border-black whitespace-nowrap select-none" onClick={() => scrollToRef(separatorRef3)}>{t('soins.sections.sorene')}</div>
                </div>

                {/* <Titre /> */}
                <div className="flex flex-row justify-start w-full pt-8 ff-king text-[36px] md:text-[50px]" >
                    {t('soins.titre')}
                </div>
                <div className="flex flex-row justify-start w-full pt-10 ff-king text-[24px] md:text-[25px]" >
                    {t('soins.sous-titre')}
                </div>
                <div className="flex flex-row justify-start text-justify w-full pt-8 ff-king text-[18px] md:text-[22px]" >
                    {t('soins.description')}
                </div>


                {/* <Lecture et Cinéma /> */}
                <div ref={separatorRef} className="flex flex-row justify-start w-full pt-16 md:pt-32 md:px-12 lg:px-16">
                    <img src={Reveil} alt="Reveil" className="hidden md:flex w-1/4 object-cover opacity-90 object-bottom" />
                    <img src={Proj} alt="Projecteur" className="w-full md:w-3/4 h-3/4 object-cover md:pl-8 lg:pl-16" />
                </div>
                <div className="w-full pt-8 mb-[-100px] text-[18px] md:text-[22px] text-justify" >

                    {i18n.language !== "de" && i18n.language !== "en" && <p><span className="ff-king">Des livres</span> pour tous les goûts (du polar à l’essai en passant par la poésie), ainsi qu’un <span className="whitespace-nowrap">« vrai »</span> <span className="ff-king">espace cinéma</span>, avec rétro-projecteur à ultra-courte focale.</p>}
                    {i18n.language === "de" && <p>Für Momente der Entspannung sind eine <span className="ff-king">Bibliothek</span> mit Bücher aller Genres und eine <span className="ff-king">Videothek</span> mit einem Ultra-Kurzdistanzprojektor zur Verfügung.</p>}
                    {i18n.language === "en" && <p><span className="ff-king">Books</span> for all tastes (from thrillers to essays to poetry), as well as a "real" <span className="ff-king">cinema space</span>, with ultra-short-throw overhead projector.</p>}

                </div>

                {/* <Separator /> */}
                <div className="w-full mt-16 md:mt-0">
                    <Separator />
                </div>

                {/* <Les Massages nomades/> */}
                <div ref={separatorRef4} className="flex flex-row justify-start w-full ff-king text-[22px]">{t('soins.section4.titre')}</div>
                <div className="flex flex-row justify-start w-full py-8 text-[20px]">{t('soins.section4.fanny')}</div>
                <div className="flex flex-col md:flex-row justify-start w-full">
                    <div className="flex flex-col text-justify w-full md:w-1/3 justify-start">
                        <div className="text-[20px] ff-king pr-8 pb-8 w-full">{t('soins.section4.massage1.titre')}</div>
                        <div className="text-[20px] md:pr-8 w-full">{t('soins.section4.massage1.description')}</div>
                    </div>
                    <div className="flex flex-col justify-start text-justify w-full mt-8 md:mt-0 md:w-1/3 md:pl-8 md:pr-8 md:border-l-[3px] md:border-w-full border-grey">
                        <div className="text-[20px] ff-king pb-8 w-full">{t('soins.section4.massage2.titre')}</div>
                        <div className="text-[20px] w-full">{t('soins.section4.massage2.description')}</div>
                    </div>
                    <div className="flex flex-col justify-start text-justify w-full mt-8 md:mt-0 md:w-1/3 md:pl-8 md:border-l-[3px] md:border-w-full border-grey">
                        <div className="text-[20px] ff-king pb-8 w-full">{t('soins.section4.massage3.titre')}</div>
                        <div className="flex flex-row justify-start" >
                            <div className="text-[20px] w-full">{t('soins.section4.massage3.description')}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-start w-full pt-8 text-[20px]">{t('soins.section4.table')}</div>
                <div className="flex flex-row justify-start w-full pt-8 font-bold text-[20px] pb-12 md:pb-0">{t('soins.section4.contact')} 06&nbsp;89&nbsp;18&nbsp;31&nbsp;72 / fanny.castaing@gmail.com</div>


                {/* <Separator /> */}
                <div className="w-full -mt-16">
                    <Separator />
                </div>

                {/* <Les cours de Yoga/> */}
                <div ref={separatorRef5} className="flex flex-row justify-start w-full  ff-king text-[22px]">{t('soins.section5.titre')}</div>
                <div className="flex flex-row justify-start text-justify Sw-full py-8 text-[20px]">{t('soins.section5.description1')}</div>
                <div className="flex flex-col md:flex-row justify-start w-full">
                    <div className="flex flex-col text-justify w-full md:w-[30%] justify-center">
                        <div className="text-[20px] w-full">{t('soins.section5.description2')}</div>
                    </div>
                    <div className="flex flex-col justify-center items-center text-justify w-full md:w-[40%] px-4 py-8 md:py-0 md:px-0 md:pl-0 xl:pl-8">
                        <div className="flex w-3/4 pt-6 px-6 pb-12 md:my-8 md:pt-4 md:px-6 md:pb-12 lg:my-16 lg:pt-8 lg:pb-16 justify-center bg-[#F6F6F6]" style={{ boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.3)" }}>
                            <img src={Yoga} alt="Yoga" className="w-full object-cover mx-8 aspect-square" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center text-justify w-full md:w-[30%] md:pl-0 xl:pl-8">
                        <div className="flex flex-row justify-start" >
                            <div className="text-[20px] w-full">{t('soins.section5.description3')}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-rox w-full">
                    <div className="flex flex-col text-justify justify-start w-full md:w-[77%] lg:mr-32">
                        <div className="flex flex-col justify-start ml-8 md:ml-0 w-full lg:mr-32 pt-8 text-[20px] pr-[23%] md:pr-0">{t('soins.section5.tarif1')}</div>
                        <div className="flex flex-col justify-start ml-8 md:ml-0 w-full lg:mr-32 pt-8 text-[20px] pr-[23%] md:pr-0">{t('soins.section5.tarif2')}</div>
                        <div className="w-full pt-8 text-[20px]">
                            <b>{t('soins.section5.reservation')}</b>
                            {t('soins.section5.disponibilités')}
                        </div>
                    </div>
                    <div className="hidden md:flex w-[23%]"></div>
                </div>

                {/* <Separator /> */}
                <div className="w-full">
                    <Separator />
                </div>

                {/* Découverte des chèvres/> */}
                <div ref={separatorRef2} className="flex flex-row justify-start w-full ff-king text-[20px] md:text-[22px] lg:whitespace-nowrap">{t('soins.section2.titre')}</div>
                <div className="flex flex-col md:flex-row items-center md:items-start md:justify-start w-full">
                    <div className="flex flex-col text-justify w-full md:w-[50%]">
                        <div className="text-[20px] md:text-[22px] w-full text-justify">{t('soins.section2.rare')}</div>
                        <div className="text-[20px] md:text-[20px] w-full text-justify md:pt-32">{t('soins.section2.visite')}</div>
                    </div>
                    <div className="w-5/6 md:w-[384px] mt-8 md:m-8 md:ml-16 p-8 pb-16 bg-[#F6F6F6]" style={{ boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.3)" }}>
                        <img src={Marquise} alt="Marquise" className="w-full aspect-square object-cover object-center border-[#a1a1a1] border-[1px]" />
                    </div>
                </div>


                {/* <Separator /> */}
                <div className="w-full md:-mt-[150px]">
                    <Separator />
                </div>

                {/* <Les jolis Plats d'Aline/> */}
                {/* <div ref={separatorRef3} className="flex flex-row justify-start w-full ff-king text-[22px]">{t('soins.section3.titre')}</div>
                <div className="flex flex-col md:flex-row justify-start w-full ">
                    <div className="flex flex-col text-justify w-full md:w-[50%] justify-between">
                        <div className="text-[20px] md:pr-8 w-full my-6 md:my-0 md:-mb-32">{t('soins.section3.pots')}</div>
                        <img src={Confitures} alt="Confitures" className="w-1/2 aspect-square md:w-3/4 h-full md:h-1/2 object-cover" />
                    </div>
                    <div className="flex flex-col justify-between text-justify w-full md:w-1/2 md:pl-8 md:border-l-[3px] md:border-w-full md:border-grey">
                        <div className="text-[20px] w-full my-8 md:my-0">{t('soins.section3.livraison')}</div>
                        <div className="flex flex-row text-justify items-start md:items-end w-full">
                            <div className="w-full md:w-1/2 mx-0 md:mr-4 px-4">
                                <img src={Dessert} alt="Dessert" className="object-cover" />
                            </div>
                            <div className="hidden md:flex w-full md:w-1/2 mb-16 justify-center bg-[#F6F6F6] px-4 md:px-0 pb-6 md:pb-12 lg:pb-10" style={{ boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.3)" }}>
                                <img src={Aline} alt="Aline" className="w-full px-0 md:px-4 object-cover pt-4" />
                            </div> */}

                {/* mobile image */}
                {/* <div className="flex md:hidden w-full md:w-1/2 mx-0 md:mr-8 px-4">
                                <img src={Aline} alt="Dessert" className="object-cover" />
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <Sorene cosmetiques /> */}
                <div ref={separatorRef3} className="flex flex-row justify-start w-full ff-king text-[22px]">{t('soins.section3bis.titre')}</div>
                <div className="flex flex-row justify-start w-full py-8 text-[22px]">{t('soins.section3bis.description')}</div>

                <div className="flex flex-col lg:flex-row justify-start text-justify w-full items-center pt-8 text-[20px]">
                    <div className="w-full lg:w-[75%]">
                        <p>{t('soins.section3bis.savon')}</p>
                        <p>{t('soins.section3bis.shampoing')}</p>
                    </div>
                    <div className="w-full lg:w-[25%] flex lg:flex-col justify-around lg:justify-start pt-8 lg:pt-0">
                        <img src={Savon} alt="Savon" className="h-[40vw] lg:h-[8vw] lg:w-[8vw] object-cover lg:ml-4 mb-4 aspect-square" />
                        <img src={Shampoing} alt="Shampoing" className="h-[40vw] lg:h-[8vw] lg:w-[8vw] object-cover lg:ml-16 aspect-square" />
                    </div>
                </div>

                <div className="flex flex-col-reverse lg:flex-row justify-start text-justify w-full items-center pt-8 lg:py-8 text-[20px]">
                    <img src={Baumes} alt="Baume" className="h-[40vw] lg:h-[8vw] object-cover mr-4 aspect-square pt-8 lg:pt-0" />
                    <p>{t('soins.section3bis.baumes')}</p>
                </div>

                {/* <div className="flex flex-col lg:flex-row justify-start text-justify w-full items-center pt-8 text-[20px]">
                    <div className="w-full lg:w-[75%]">
                        <p>{t('soins.section3bis.jour')}</p>
                        <p>{t('soins.section3bis.nuit')}</p>
                        <p>{t('soins.section3bis.lait')}</p>
                    </div>
                    <div className="w-full flex justify-around lg:justify-start lg:flex-col lg:w-[25%]">
                        <img src={Jour} alt="Jour" className="h-[40vw] lg:h-[8vw] lg:w-[8vw] object-cover lg:ml-16 mb-4 aspect-square" />
                        <img src={Nuit} alt="Nuit" className="h-[40vw] lg:h-[8vw] lg:w-[8vw] object-cover lg:ml-4 mb-4 aspect-square" />
                    </div>
                </div> */}

                <div className="w-full pt-8 text-[20px]">{t('soins.section3bis.contact1')}<a className="hover:underline text-blue-700" href="http://boutique.chevrerie-sorene.fr" target="_blank">boutique.chevrerie-sorene.fr</a>{t('soins.section3bis.contact2')}</div>

            </div >
        </div >
    );
}

export default Soins;