import { useTranslation } from "react-i18next";

interface IProps {
    className?: string
}

const InformationLinesMaisonnette = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={`w-full ${props.className} flex-col`}>
            <p className="pt-4"><b>{t('informations.lignes.maisonnette')}</b>{t('informations.lignes.surface5')}</p>

            <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-2 flex-col">
                <div>{t('informations.lignes.rdc')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.garage')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.etage')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.1etage')}</div>
                    <div>{t('informations.lignes.sdb1')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.terrasse3')}</div>
                <div className="pt-4">{t('informations.lignes.etage2')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.chambre1')}</div>
                    <div>{t('informations.lignes.chambre2')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.terrasse4')}</div>
                <div className="pt-4">{t('informations.lignes.etage3')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.salon2')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.jardin')}</div>
            </div>
            <p className="pt-8">{t('informations.lignes.capacite4')}</p>
            <p className="pb-4">{t('informations.lignes.enfants')}</p>
            <div className="pt-4">
                {t('informations.lignes.parking3')}
                <div>{t('informations.lignes.animaux3')}</div>
                <div>{t('informations.lignes.wifi3')}</div>
            </div>
        </div>
    )
}

export default InformationLinesMaisonnette;