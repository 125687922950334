import { useTranslation } from "react-i18next";
import Banner from "./Banner";

const Histoire = () => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner />
            <div className="flex flex-row py-[45px] lg:py-[92px] justify-center">
                <div className="w-3/4 xl:w-1/2">
                    <p className="ff-king text-[30px] lg:text-[40px] 2xl:text-[50px] pb-[40px] lg:pb-[73px]">{t('histoire.titre')}</p>

                    <p className="ff-zilla text-[17px] lg:text-[20px] 2xl:text-[22px] text-justify">
                        {t('histoire.description')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Histoire;