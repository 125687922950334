import { Ref, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ReservationModel from '../models/Reservations';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { DateRange } from 'react-date-range';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Select from 'react-select';
import Loading from "../components/Loading";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Cabane from '../assets/cabane.jpg';
import Maison from '../assets/accueil_porte.jpg';
import Mas from '../assets/maison/espaces/manger.jpg';
import Maisonnette from '../assets/maisonnette/maisonnette.jpg';
import { useNavigate } from 'react-router-dom';
import MaisonModel from '../models/Maisons';

interface ICounterProps {
    name: string;
    full?: boolean;
    increment: () => void;
    decrement: () => void;
    set: (value: number) => void;
    value: number;
}

const Counter = (props: ICounterProps) => {
    return (
        <div className={`flex items-center justify-between ${props.full ? 'w-full' : ''}`}>
            <p className='select-none'>{props.name}</p>
            <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-gray-500 hover:text-black" onClick={() => props.value > 0 ? props.decrement() : props.set(0)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <input type="text" className='w-8 border border-gray-300 text-center mx-1 focus:outline-none focus:border-black' onChange={(value) => Number(value.target.value) >= 0 ? props.set(Number(value.target.value)) : 0} value={props.value} />

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-gray-500 hover:text-black" onClick={() => props.value < 100 ? props.increment() : props.set(100)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </div>
    )
}

// const customDayContent = (day: any) => {
//     console.log('day: ', day);
//     return (
//         <div className='text-center bg-red-500'>
//             <p className='text-lg text-[15px] text-center absolute top-0'>{format(day, 'd')}</p>
//             <p className='text-lg text-[13px] text-center absolute top-3'>50€</p>
//         </div>
//     )
// }

const Reservation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [submitBtnRef, setSubmitBtnRef] = useState<HTMLInputElement | null>(null);
    const [reservations, setReservations] = useState<ReservationModel[]>([]);
    const [maisons, setMaisons] = useState<MaisonModel[]>([]);
    const [form, setForm] = useState<any>(null);
    const [houseChoice, setHouseChoice] = useState<number>(0);
    const [disabledDates, setDisabledDates] = useState<Date[]>([]);
    const [prices, setPrices] = useState<any[]>([]);

    // Promocode
    const [promocode, setPromocode] = useState<string>('');
    const [promoCodeValid, setPromoCodeValid] = useState<boolean>(true);
    const [promoCodeNights, setPromoCodeNights] = useState<number>(0);
    const [promoCodeGift, setPromoCodeGift] = useState<boolean>(false);
    const [promoCodeReduction, setPromoCodeReduction] = useState<number>(0);
    const promocodeRef = useRef<HTMLInputElement>(null);

    // Gift
    const [isGift, setIsGift] = useState<boolean>(false);
    const [giftName, setGiftName] = useState<string>('');
    const [giftMail, setGiftMail] = useState<string>('');
    const [giftMessage, setGiftMessage] = useState<string>('');
    const [giftNights, setGiftNights] = useState<number>(0);
    const [giftToken, setGiftToken] = useState<string>('');
    const [offeredNights, setOfferedNights] = useState<number>(0);
    const [isASecret, setIsASecret] = useState<boolean>(false);

    // Loading
    const [loading, setLoading] = useState<boolean>(false);

    // Reservation infos
    const [numberOfAdults, setNumberOfAdults] = useState<number>(0);
    const [numberOfChildren, setNumberOfChildren] = useState<number>(0);

    // Form customer infos
    const [mail, setMail] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    // Check fields validity
    const [mailValid, setMailValid] = useState<Boolean>(true);
    const [giftMailValid, setGiftMailValid] = useState<Boolean>(true);
    const [numberOfPersonsValid, setNumberOfPersonsValid] = useState<Boolean>(true);
    const [numberOfDaysValid, setNumberOfDaysValid] = useState<Boolean>(true);
    const [childrenValid, setChildrenValid] = useState<Boolean>(true);
    const [childrenCorrectAge, setChildrenCorrectAge] = useState<Boolean>(false);

    // Countries
    const [countries, setCountries] = useState<any>([]);

    // Calendar state
    const [rangeState, setRangeState] = useState<{
        startDate?: Date,
        endDate?: Date,
        key?: string
    }[]>([
        {
            startDate: DateTime.now().plus({ days: 1 }).toJSDate(),
            endDate: DateTime.now().plus({ days: 2 }).toJSDate(),
            key: 'selection'
        }
    ]);

    // Select options
    const [options, setOptions] = useState<{ value: number, label: string }[]>([]);

    const initReservations = async () => {
        const res = await ReservationModel.init();

        setReservations(res);
        initDisabledDates(res);
    }

    const initMaisons = async () => {
        const res = await MaisonModel.init();

        setMaisons(res);

        const opt = res.map((maison: MaisonModel) => {
            return { value: maison.houseId, label: maison.name };
        })
        setOptions(opt);
        initHouseChoice(opt.length);
    }

    const initDisabledDates = (reservations: ReservationModel[]) => {
        const disabledDates: Date[] = [];

        reservations.forEach(reservation => {
            if ((reservation.house === 2 && houseChoice === 2)
                || (reservation.house < 2 && houseChoice < 2)
                || (reservation.house === 3 && houseChoice === 3)) {

                let startDate = reservation.arrival.startOf('day').plus({ days: reservation.external ? 1 : 1 });
                const endDate = reservation.departure.startOf('day').minus({ days: reservation.external ? 1 : 1 });

                while (startDate <= endDate) {
                    disabledDates.push(startDate.toJSDate());
                    startDate = startDate.set({ day: startDate.day + 1 });
                }
            }
        });

        disabledDates.push(DateTime.now().toJSDate());
        disabledDates.push(DateTime.now().plus({ days: 1 }).toJSDate());

        setDisabledDates(disabledDates);
    }

    const initCountries = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/checkout/countries`);

        setCountries(res.data);
    }

    useEffect(() => {
        setLoading(!(maisons && maisons.length > 0 && reservations && countries && countries.length > 0 && ((giftToken && offeredNights) || !giftToken)));
    }, [maisons, reservations, countries]);

    const initHouseChoice = (optionsLength: number) => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        let house: string | number | null = url.searchParams.get("house");
        let token = url.searchParams.get("token");
        if (house && !token) {
            house = parseInt(house);
            if (house >= 0 && house < optionsLength) {
                setHouseChoice(house);
            }
        }
    }

    const initToken = async () => {
        //parse url and look for the token parameter to set gift token
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const token = url.searchParams.get("token");

        if (token) {
            setGiftToken(token);

            //get gift infos
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/gifts/verify/${token}`);

            if (res.data) {
                if (res.data.used) {
                    alert("Ce cadeau a déjà été utilisé");
                    navigate('/');
                }
                setOfferedNights(res.data.nights);
                setHouseChoice(res.data.house);
            }
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0
        });

        initReservations();
        initCountries();
        initMaisons();
        initToken();
    }, [])

    useEffect(() => {
        initDisabledDates(reservations);
    }, [reservations, houseChoice])

    const checkMail = (mail: String) => {
        if (!mail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/g)) {
            return false;
        }
        return true;
    }

    const checkForm = () => {
        if (!firstname || !lastname || !mail || !address1 || !zipCode || !city || !phone || (isGift && !giftMail && !isASecret) || (isGift && !giftName)) {
            return false;
        }

        // Check if mail is valid
        if (!checkMail(mail)) {
            return false;
        }

        // If gift, check if gift mail is valid
        if (isGift && !checkMail(giftMail) && !isASecret) {
            return false;
        }

        return true;
    }

    const sendReservation = async () => {
        if (!checkForm()) {
            window.alert('Veuillez remplir tous les champs');
            return;
        };
        try {
            const reservation = new ReservationModel({
                house: houseChoice,
                arrival: rangeState[0].startDate?.toISOString(),
                departure: rangeState[0].endDate?.toISOString(),
                contact: {
                    name: `${firstname} ${lastname}`,
                    email: mail,
                    phone: phone
                },
                comments: comment,
                adults: numberOfAdults,
                children: numberOfChildren,
                promocode: promocode || '',
                gift: {
                    isGift: isGift,
                    name: giftName,
                    email: isASecret ? mail : giftMail,
                    message: giftMessage,
                    nights: giftNights,
                    house: houseChoice
                }
            });
            const res = await reservation.save();

            if (res?.data?._id) {

                // If this reservation is a gift
                if (offeredNights > 0) {
                    const session = await axios.post(`${process.env.REACT_APP_API_URL}/gifts/use`, {
                        token: giftToken,
                        reservationId: res.data._id
                    });

                    if (session.status === 200) {
                        navigate(`/checkout/validated?Ref=${res.data._id}&Gift=true`);
                    } else {
                        window.alert('Une erreur est survenue');
                    }

                    // If user needs to checkout
                } else {
                    const data = res.data;
                    const session = await axios.post(`${process.env.REACT_APP_API_URL}/checkout`,
                        {
                            reservationId: data._id,
                            customer: {
                                mail: mail,
                                firstName: firstname,
                                lastName: lastname,
                                address1: address1,
                                address2: address2,
                                zipCode: zipCode,
                                city: city,
                                country: country,
                                phone: phone,
                                comment: comment
                            },
                            promocode: promocode
                        }
                    );

                    if (session.status === 200) {
                        setForm(
                            <form method="POST" action={process.env.REACT_APP_ETRANSACTION_URL}>
                                <input type="hidden" name="PBX_SITE" value={session.data.PBX_SITE} />
                                <input type="hidden" name="PBX_RANG" value={session.data.PBX_RANG} />
                                <input type="hidden" name="PBX_IDENTIFIANT" value={session.data.PBX_IDENTIFIANT} />
                                <input type="hidden" name="PBX_TOTAL" value={session.data.PBX_TOTAL} />
                                <input type="hidden" name="PBX_DEVISE" value={session.data.PBX_DEVISE} />
                                <input type="hidden" name="PBX_CMD" value={session.data.PBX_CMD} />
                                <input type="hidden" name="PBX_PORTEUR" value={session.data.PBX_PORTEUR} />
                                <input type="hidden" name="PBX_RETOUR" value={session.data.PBX_RETOUR} />
                                <input type="hidden" name="PBX_HASH" value={session.data.PBX_HASH} />
                                <input type="hidden" name="PBX_TIME" value={session.data.PBX_TIME} />
                                <input type="hidden" name="PBX_REPONDRE_A" value={session.data.PBX_REPONDRE_A} />
                                <input type="hidden" name="PBX_EFFECTUE" value={session.data.PBX_EFFECTUE} />
                                <input type="hidden" name="PBX_ANNULE" value={session.data.PBX_ANNULE} />
                                <input type="hidden" name="PBX_REFUSE" value={session.data.PBX_REFUSE} />
                                <input type="hidden" name="PBX_SHOPPINGCART" value={session.data.PBX_SHOPPINGCART} />
                                <input type="hidden" name="PBX_BILLING" value={session.data.PBX_BILLING} />
                                <input type="hidden" name="PBX_HMAC" value={session.data.PBX_HMAC} />
                                <input type="submit" className="cursor-pointer text-white" ref={setSubmitBtnRef} />
                            </form>
                        );
                    }
                }
            } else {
                // console.log("sendReservation: ", res);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (form && submitBtnRef) {
            submitBtnRef.click();
        }
    }, [form, submitBtnRef])

    const calculateReduction = (localPrices: { days: number, price: number }[]) => {
        let reduction = 0;

        if (promoCodeValid && promoCodeNights > 0) {
            let tmp_days = promoCodeNights;
            let tmp_reduction = 0;
            let giftPrice = meanPrice();
            let sortedPrices = localPrices.slice(0).sort((a, b) => a.price - b.price);

            for (let i = 0; i < sortedPrices.length; i++) {
                if (tmp_days > 0) {
                    if (tmp_days >= sortedPrices[i].days) {
                        tmp_reduction += sortedPrices[i].days * (isGift ? giftPrice : sortedPrices[i].price);
                        tmp_days -= sortedPrices[i].days;
                    } else {
                        tmp_reduction += tmp_days * (isGift ? giftPrice : sortedPrices[i].price);
                        tmp_days = 0;
                    }
                }
            }

            reduction = tmp_reduction;
        }

        return reduction;
    }

    const calculatePrice = () => {
        if (!maisons[houseChoice]) return [];

        let localPrices = [];
        let tmp_days = 0;
        let tmp_price = 0;

        if (rangeState && rangeState[0].startDate && rangeState[0].endDate) {
            let startDate = DateTime.fromJSDate(rangeState[0].startDate);
            const endDate = DateTime.fromJSDate(rangeState[0].endDate);

            while (startDate < endDate) {
                const month = startDate.month;
                const period = maisons[houseChoice].periods.find((period) => {
                    return (
                        period.start <= period.end ? month >= period.start && month <= period.end : (month > 0 && month <= period.end) && (month <= 12 && month >= period.start)
                    )
                }) || maisons[houseChoice].periods[0];

                if (tmp_days > 0 && tmp_price !== period.price) {
                    localPrices.push({ days: tmp_days, price: tmp_price });
                    tmp_days = 0;
                }

                tmp_price = period.price;
                tmp_days += 1;

                startDate = startDate.set({ day: startDate.day + 1 });
            }
        }
        localPrices.push({ days: tmp_days, price: tmp_price });

        setPromoCodeReduction(calculateReduction(localPrices));

        return localPrices;
    }

    useEffect(() => {
        setPrices(calculatePrice());
    }, [rangeState, houseChoice, promoCodeValid, promoCodeNights, isGift])

    const getReservationPeriod = () => {
        if (!rangeState || !rangeState[0].startDate || !maisons || !maisons[houseChoice]) return null;

        let startDate = DateTime.fromJSDate(rangeState[0].startDate);
        const month = startDate.month;
        const period = maisons[houseChoice].periods.find((period) => {
            return (
                period.start <= period.end ? month >= period.start && month <= period.end : (month > 0 && month <= period.end) && (month <= 12 && month >= period.start)
            )
        }) || maisons[houseChoice].periods[0];

        return period;
    }

    const maxPersons = () => {
        const house = maisons[houseChoice];
        return house ? house.maxCapacity : 0;
    }

    const minStay = () => {
        const period = getReservationPeriod();
        return period ? period.minStay : 0;
    }

    const numberOfDays = () => {
        return prices.reduce((acc, price) => {
            return acc + price.days;
        }, 0);
    }

    const checkNumberOfDays = () => {
        const totalDays = numberOfDays();
        const currentPeriod = getReservationPeriod();

        const min = minStay();

        if (offeredNights > 0 && totalDays !== offeredNights) {
            return false;
        }
        if (currentPeriod && totalDays >= min) {
            return true;
        }
        return false;
    }

    const checkNumberOfPersons = () => {
        const persons = numberOfAdults + numberOfChildren;
        if (persons >= 0 && persons <= maxPersons()) {
            return true;
        }
        return false;
    }

    const checkChildren = () => {
        if ((numberOfChildren === 0) || (numberOfChildren >= 0 && childrenCorrectAge)) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        setNumberOfPersonsValid(checkNumberOfPersons());
        setChildrenValid(checkChildren());
    }, [numberOfAdults, numberOfChildren, houseChoice, childrenCorrectAge])

    useEffect(() => {
        if (loading) return;
        setNumberOfDaysValid(checkNumberOfDays());
    }, [prices, rangeState, loading, houseChoice])

    useEffect(() => {
        if (loading) return;

        const min = minStay();

        setRangeState([
            {
                startDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                endDate: DateTime.now().plus({ days: 1 + min }).toJSDate(),
                key: 'selection'
            }
        ]);
    }, [loading])

    useEffect(() => {
        if (isGift) {
            setIsASecret(false);
        }
    }, [isGift])

    const meanPrice = () => {
        if (!maisons[houseChoice]) return 0;

        const mean_price = maisons[houseChoice].periods.reduce((acc, period) => acc + period.price, 0) / maisons[houseChoice].periods.length;

        return mean_price;
    }

    const deletePromocode = async () => {
        setPromoCodeNights(0);
        setPromoCodeGift(false);
        setPromoCodeReduction(0);
        setPromoCodeValid(false);
        setPromocode('');
        if (promocodeRef && promocodeRef.current) {
            promocodeRef.current.value = '';
        }
    }

    const applyPromocode = async () => {
        if (!promocode) {
            setPromoCodeNights(0);
            setPromoCodeGift(false);
            setPromoCodeReduction(0);
            return;
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/promocodes/check-promocode`, {
            code: promocode
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (!res.data) {
            setPromoCodeValid(false);
            setPromoCodeNights(0);
            setPromoCodeGift(false);
            setPromoCodeReduction(0);
            return;
        }

        setPromoCodeValid(true);
        setPromoCodeNights(res?.data?.nights || 0);
        setPromoCodeGift(res?.data?.gift || false);

        return;
    }

    return loading || !options || options.length === 0 ? (<Loading loading={loading || !options || options.length === 0} />) : (
        <div className='w-full h-full'>
            <div className='h-[78px] w-full bg-[#A5BFD8] flex items-center justify-center'></div>
            <div className="flex flex-col h-full justify-center items-center pb-16">

                <div className='flex flex-col w-full max-w-[1200px] h-full'>
                    <button className="flex items-center gap-2 w-fit hover:bg-[#e5ecff] pr-2 my-6 py-2" onClick={() => navigate("/")}>
                        <div className="hover:bg-[#e1e9f0] flex justify-center items-center p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <p className="text-xl">Retour au site</p>
                    </button>

                    <div className='flex flex-col-reverse lg:flex-row lg:border lg:rounded-xl'>
                        {/* Left */}
                        <div className='w-full lg:w-3/5 p-8 flex flex-col lg:justify-start'>

                            {
                                offeredNights == 0 && <Select
                                    options={options}
                                    onChange={(value) => value && setHouseChoice(value.value)}
                                    isMulti={false}
                                    defaultValue={options[houseChoice]}
                                    className='pb-4'
                                />
                            }

                            {!isGift &&
                                <div className='w-full flex flex-col md:flex-row gap-8 relative'>
                                    <div className='w-fit'>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={item => setRangeState([item.selection])}
                                            moveRangeOnFirstSelection={false}
                                            ranges={rangeState}
                                            locale={fr}
                                            showDateDisplay={false}
                                            className='w-full rounded-xl border'
                                            disabledDates={disabledDates}
                                            minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
                                        // dayContentRenderer={customDayContent}
                                        />
                                    </div>
                                    <div className='w-full flex flex-col gap-4 py-4'>

                                        <Counter name="Adultes"
                                            increment={() => setNumberOfAdults(numberOfAdults + 1)}
                                            decrement={() => setNumberOfAdults(numberOfAdults - 1)}
                                            set={(value: number) => setNumberOfAdults(value)}
                                            value={numberOfAdults}
                                            full
                                        />

                                        <Counter name="Enfants"
                                            increment={() => setNumberOfChildren(numberOfChildren + 1)}
                                            decrement={() => setNumberOfChildren(numberOfChildren - 1)}
                                            set={(value: number) => setNumberOfChildren(value)}
                                            value={numberOfChildren}
                                            full
                                        />

                                        <div className={`flex justify-start items-center -mt-2 ${numberOfChildren > 0 ? '' : 'hidden'}`}>
                                            <input type="checkbox" id="childrenAge" name="childrenAge" onChange={() => setChildrenCorrectAge(!childrenCorrectAge)} />
                                            <div className='pl-2 cursor-pointer'>{numberOfChildren === 1 ? 'L\'' : 'Les'} enfant{numberOfChildren === 1 ? ' a' : 's ont'} plus de 10 ans.</div>
                                        </div>

                                        {/* Wrong criteria for reservation */}
                                        <div className='flex flex-col'>

                                            {
                                                !numberOfDaysValid && offeredNights == 0 && <div className='flex items-center'>
                                                    <div className='h-6 flex items-end'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="w-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                        </svg>
                                                    </div>
                                                    <p className='text-red-500 pl-1'>{minStay()} nuits minimum</p>
                                                </div>
                                            }

                                            {
                                                !numberOfDaysValid && offeredNights > 0 && <div className='flex items-center'>
                                                    <div className='h-6 flex items-end'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="w-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                        </svg>
                                                    </div>
                                                    <p className='text-red-500 pl-1'>Votre cadeau vous offre {offeredNights} nuits.</p>
                                                </div>
                                            }

                                            {
                                                !numberOfPersonsValid && <div className='flex items-center'>
                                                    <div className='h-6 flex items-end'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="w-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                        </svg>
                                                    </div>
                                                    <p className='text-red-500 pl-1'>{maxPersons()} personnes maximum</p>
                                                </div>
                                            }

                                            {
                                                numberOfChildren > 0 && <div className='flex items-center'>
                                                    <p className='pl-1 italic'>Les enfants de moins de 10 ans ne sont pas autorisés sans accord préalable avec les propriétaires. Merci de les contacter par mail à sorenecosmetiques@gmail.com ou par téléphone au 0657487568.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* is gift checkbox */}
                            {
                                offeredNights == 0 && <div className='flex flex-col justify-end'>
                                    <div className='flex items-center justify-end w-full gap-2 pt-5'>
                                        <div className='text-md text-bold'>Cette réservation est un cadeau </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M14 6a2.5 2.5 0 00-4-3 2.5 2.5 0 00-4 3H3.25C2.56 6 2 6.56 2 7.25v.5C2 8.44 2.56 9 3.25 9h6V6h1.5v3h6C17.44 9 18 8.44 18 7.75v-.5C18 6.56 17.44 6 16.75 6H14zm-1-1.5a1 1 0 01-1 1h-1v-1a1 1 0 112 0zm-6 0a1 1 0 001 1h1v-1a1 1 0 00-2 0z" clipRule="evenodd" />
                                            <path d="M9.25 10.5H3v4.75A2.75 2.75 0 005.75 18h3.5v-7.5zM10.75 18v-7.5H17v4.75A2.75 2.75 0 0114.25 18h-3.5z" />
                                        </svg>
                                        <input type="checkbox" name="isGift" id="isGift" className='w-5 h-5 cursor-pointer' onChange={(event) => setIsGift(event.target.checked)} />
                                    </div>

                                    {
                                        isGift && <p className='w-full flex justify-end italic pt-2 pb-3 text-gray-600'>Une surprise sera offerte aux hôtes bénéficiaires du cadeau</p>
                                    }
                                </div>
                            }

                            {/* Gift form */}
                            {
                                isGift && <div className=''>

                                    {/* Name */}
                                    <div className='flex gap-2 pb-2 w-full'>
                                        <div className='w-full'>
                                            <div className='px-2 py-1 flex gap-1'><p className='font-semibold'>Prénom du destinataire</p></div>
                                            <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setGiftName(event.target.value)}></input>
                                        </div>
                                    </div>

                                    {/* Email address */}
                                    <div className='flex gap-2 pb-4 w-full'>
                                        <div className='w-full'>
                                            <p className={`px-2 py-1 font-semibold ${isASecret ? 'text-gray-400' : ''}`}>Adresse mail du destinataire</p>
                                            <input type='text' disabled={isASecret} className={`border rounded-md w-full py-2 px-2 ${!giftMailValid && !isASecret ? 'border-red-600' : ''} ${isASecret ? 'bg-gray-100' : ''}`} onChange={(event) => setGiftMail(event.target.value)} onBlur={(event) => setGiftMailValid(checkMail(event.target.value))}></input>
                                            <p className='font-normal text-red-600'>{!giftMailValid && !isASecret ? 'Adresse mail invalide' : ''}</p>
                                        </div>
                                    </div>

                                    {/* Number of gifted nights */}
                                    <div className='flex gap-2 pb-4 w-full'>
                                        <p className='font-semibold px-2'>Combien de nuits voulez-vous offrir ?</p>
                                        <Counter name=""
                                            increment={() => setGiftNights(giftNights + 1)}
                                            decrement={() => setGiftNights(giftNights - 1)}
                                            set={(value: number) => setGiftNights(value)}
                                            value={giftNights}
                                        />
                                    </div>

                                    {/* Checkbox is a secret */}
                                    <div className='flex justify-end w-full items-center'>
                                        <p className='pr-2'>Je veux garder le secret</p>
                                        <input type="checkbox" name="isSecret" id="isSecret" className='w-5 h-5 cursor-pointer' onChange={(event) => setIsASecret(event.target.checked)} />
                                    </div>

                                    {
                                        isASecret && <div className='w-full flex justify-end'>
                                            <p className='italic text-gray-700 pl-2'>Vous recevrez le mail de réservation et pourrez le transférer quand vous le désirerez</p>
                                        </div>
                                    }
                                </div>
                            }

                            {
                                offeredNights == 0 && <div className={`${promoCodeValid && promoCodeNights ? 'border-b pb-2' : ''}`}>
                                    <div className='border-t-2 border-gray-200 mt-8 font-bold text-gray-600 text-lg pt-4 pb-2'>Résumé de votre réservation</div>

                                    <div className='flex flex-col justify-between'>
                                        {
                                            !isGift && prices.map((price, index) => {
                                                return (
                                                    <div className='flex' key={index}>
                                                        <div className='flex gap-1'>
                                                            <div>{price.days}</div>
                                                            <p>nuits x </p>
                                                            <div>{price.price}</div>
                                                            <p>€</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                        {
                                            isGift && <div className='flex'>
                                                <div className='flex gap-1'>
                                                    <div>{giftNights}</div>
                                                    <p>nuits x </p>
                                                    <div>{meanPrice()}</div>
                                                    <p>€</p>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='flex justify-between border-t pt-2 mt-2'>
                                        <div>{promoCodeValid && promoCodeNights ? 'SOUS-TOTAL' : 'TOTAL'}</div>
                                        {
                                            !isGift && <div>{prices.reduce((sum, price) => sum += price.days * price.price, 0)}€</div>
                                        }
                                        {
                                            isGift && <div>{giftNights * meanPrice()}€</div>
                                        }
                                    </div>
                                    {
                                        promoCodeValid && promoCodeNights > 0 && <div className='flex justify-between'>
                                            <p>{promoCodeNights} nuits offertes</p>
                                            <p>-{promoCodeReduction}€</p>
                                        </div>
                                    }
                                </div>
                            }

                            {/* Price with reduction */}
                            {
                                promoCodeValid && promoCodeNights > 0 && <div>
                                    {
                                        !isGift && <div className='flex justify-between'>
                                            <p>TOTAL</p>
                                            <p>{prices.reduce((sum, price) => sum += price.days * price.price, 0) - promoCodeReduction}€</p>
                                        </div>
                                    }
                                    {
                                        isGift && <div className='flex justify-between'>
                                            <p>TOTAL</p>
                                            <p>{giftNights * meanPrice() - promoCodeReduction}€</p>
                                        </div>
                                    }
                                </div>
                            }

                            <div className='flex flex-col md:flex-row justify-end py-8 mt-2 md:items-center select-all'>
                                <div className='pr-4 pl-2 md:pl-0 pb-2 md:pb-0'>J'ai un code promo: </div>
                                <input ref={promocodeRef} type='text' className='border rounded-md w-full md:w-48 py-2 px-2' onChange={(event) => setPromocode(event.target.value)}></input>
                                {
                                    <button className='bg-blue-500 text-white rounded-md px-4 py-2 md:ml-2 disabled:opacity-60 mt-2 md:mt-0' onClick={() => applyPromocode()}>Appliquer</button>
                                }
                                {
                                    // promoCodeValid && <button className='bg-red-500 text-white rounded-md px-4 py-2 md:ml-2 disabled:opacity-60 mt-2 md:mt-0' onClick={() => deletePromocode()}>Supprimer</button>
                                }
                            </div>

                            {
                                promocode && !promoCodeValid && <p className='flex justify-end -mt-4'>Nous n'avons pas trouvé de code promo correspondant</p>
                            }
                            {
                                promoCodeValid && promoCodeGift && <p className='flex justify-end -mt-4'>Ce code promo vous donne le droit à un petit cadeau pendant votre séjour !</p>
                            }

                            <div className='flex flex-col justify-between border-t mt-6 py-4'>

                                <div className='flex gap-2 pb-4 w-full'>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>Prénom</p>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setFirstname(event.target.value)}></input>
                                    </div>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>Nom</p>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setLastname(event.target.value)}></input>
                                    </div>
                                </div>

                                <div className='flex gap-2 pb-4 w-full'>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>Téléphone</p>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setPhone(event.target.value)}></input>
                                    </div>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>E-mail</p>
                                        <input type='text' className={`border rounded-md w-full py-2 px-2 ${!mailValid ? 'border-red-600' : ''}`} onChange={(event) => setMail(event.target.value)} onBlur={(event) => setMailValid(checkMail(event.target.value))}></input>
                                        <p className='font-normal text-red-600'>{!mailValid ? 'Adresse mail invalide' : ''}</p>
                                    </div>
                                </div>

                                <div className='flex gap-2 pb-4 w-full'>
                                    <div className='w-full'>
                                        <div className='px-2 py-1 flex gap-1'><p className='font-semibold'>Adresse</p><p className='text-gray-600 font-normal'>(ligne 1)</p></div>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setAddress1(event.target.value)}></input>
                                    </div>
                                </div>

                                <div className='flex gap-2 pb-4 w-full'>
                                    <div className='w-full'>
                                        <div className='px-2 py-1 flex gap-1'><p className='font-semibold'>Adresse</p><p className='text-gray-600 font-normal'>(ligne 2)</p></div>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setAddress2(event.target.value)}></input>
                                    </div>
                                    <div className='w-2/8'>
                                        <p className='px-2 py-1 font-semibold'>Code postal</p>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setZipCode(event.target.value)}></input>
                                    </div>
                                </div>

                                <div className='flex gap-2 pb-4 w-full'>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>Ville</p>
                                        <input type='text' className='border rounded-md w-full py-2 px-2' onChange={(event) => setCity(event.target.value)}></input>
                                    </div>
                                    <div className='w-full'>
                                        <p className='px-2 py-1 font-semibold'>Pays</p>
                                        <select id="countries" className="border rounded-md w-full pt-3 pb-2 px-2 bg-white" defaultValue='250' onChange={(event) => setCountry(event.target.value)}>
                                            {
                                                countries.map((country: any, index: number) => {
                                                    return (
                                                        <option key={index} value={country.numeric}>
                                                            {country.country}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='flex items-center'>
                                    <p className='px-2 py-1 font-semibold'>Commentaire</p>
                                    <p className='italic text-gray-600'>{"(facultatif)"}</p>
                                </div>
                                <textarea className='w-full h-24 border rounded-xl p-4 resize-none' placeholder='Ajouter un message pour le propriétaire' maxLength={500} onChange={(event) => setComment(event.target.value)} />
                                <button disabled={(!checkForm() || (!isGift && (!numberOfDaysValid || !numberOfPersonsValid || !childrenValid)))} className='bg-[#3D91FF] text-white px-4 py-2 mt-2 rounded-md disabled:opacity-60 disabled:cursor-not-allowed' onClick={() => sendReservation()}>{offeredNights == 0 ? 'Passer au paiement' : 'Valider la réservation'}</button>

                                {/* Error message for wrong criterias */}
                                {
                                    (!isGift && (!numberOfDaysValid || !numberOfPersonsValid || !childrenValid)) && <p className='italic text-justify text-gray-600 pt-4'>Les critères concernant le nombre de personnes ou le nombre de nuits souhaitées ne sont pas correctes. Si ce n'est pas une erreur et que vous souhaitez nous contacter pour discuter de conditions d'accueil spécifiques vous pouvez nous joindre à l'adresse email sorenecosmetiques@gmail.com ou par téléphone au 0657487568.</p>
                                }
                            </div>

                        </div>

                        {/* Right */}
                        <div className='flex w-full lg:w-2/5 flex-col md:flex-row lg:flex-col justify-start px-8 border-l my-8'>
                            <img src={houseChoice === 0 ? Mas : houseChoice === 1 ? Maison : houseChoice === 2 ? Cabane : Maisonnette} className='w-full object-cover aspect-video'></img>
                            <div>
                                <div className='italic px-2 py-4 text-justify'>
                                    {maisons[houseChoice]?.description || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {form}
        </div>
    )
}

export default Reservation;