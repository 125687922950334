import ReservationModel from "../../models/Reservations";

interface IProps {
    res: ReservationModel,
    compact?: boolean
}

const StatusBadge = (props: IProps) => {
    return (
        <div className={`flex flex-row items-center ${props.compact ? 'w-full justify-center' : ''}`}>
            <div className="w-2 h-2 rounded-full mr-1" style={{ backgroundColor: props.res.validated ? '#00B87C' : props.res.cancelled ? '#FF0000' : '#FFC107' }}></div>
            <div className={`text-sm ${props.compact ? 'hidden' : ''}`}>{props.res.validated ? 'Validée' : props.res.cancelled ? 'Annulée' : 'En attente'}</div>
        </div>
    )
}

export default StatusBadge;