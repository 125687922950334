import "../styles/loading.css";
import Logo from "../assets/round_logo.png";
import { useEffect, useState } from "react";

interface IProps {
    loading: boolean;
}

const Loading = (props: IProps) => {

    useEffect(() => {
        const loadingScreen = document.querySelector('#loading-screen');

        if (!loadingScreen) return;

        loadingScreen.addEventListener('transitionend', () => {
            loadingScreen.classList.add('hidden');
        });
    }, [])

    return (
        <div id="loading-screen" className={`w-screen h-screen bg-white flex justify-center items-center absolute z-40 ${props.loading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000`}>
            <img src={Logo} alt="" className="w-28 h-28 animate-pulse" />
        </div>
    );
}

export default Loading;