import { useTranslation } from 'react-i18next';
import Image from '../../assets/bergers.jpg';

const Banner = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full">
            <img src={Image} alt="" className="w-full" />
            <p className="w-full flex flex-row justify-end px-8 py-1 text-[12px]">© Renaud Wailliez</p>
        </div>
    )
}

export default Banner