import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ITarifs {
    showTitle?: boolean;
    name?: string;
    opening?: string;
    showTaxes?: boolean;
    setMinHeight?: boolean;
    winter?: boolean;
    nights: number[];
    prices: number[];
    capacity: string;
    surface?: string;
}

const Tarifs = (props: ITarifs) => {
    const { t } = useTranslation();

    return (
        <div className="w-full h-full flex flex-row justify-center">
            <div className="w-full h-full flex flex-col items-start">
                {
                    (props.showTitle && <div className="ff-king md:pl-4 text-[25px]">{t('cabane.tarifs.titre')}</div>) || <div className="min-h-[37px]"></div>
                }
                {
                    (props.showTaxes || props.setMinHeight) && <div className="lg:whitespace-nowrap text-[18px] lg:text-[20px] pt-6 min-h-[60px]">{props.showTaxes ? t('cabane.tarifs.menage') : " "}</div>
                }
                {
                    (props.opening && <div className={`text-[18px] lg:text-[20px] pt-2 md:pl-4 lg:whitespace-nowrap ${props.name ? 'pb-2' : 'pb-8'}`}>{props.opening}</div>) || <div className={`text-[18px] lg:text-[20px] pt-2 md:pl-4 ${props.name ? 'pb-2' : 'pb-8'}`}>&nbsp;</div>
                }
                {
                    (props.name && <div className="ff-king pl-4 text-[22px] pb-4">{props.name} {props.surface ? `(${props.surface} m²)` : ''}</div>) || <div className="min-h-[44px]"></div>
                }

                <div className="w-full h-full grid grid-rows-4 grid-cols-3 px-1 text-[12px] sm:text-[17px] 2xl:text-[20px] text-start">
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0">{props.winter ? t('maison.tarifs.période.novembre') : t('cabane.tarifs.période.avril')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0">{props.prices[0]}€{props.capacity}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center">{props.nights[0]} {t('maison.tarifs.période.nuits')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{t('maison.tarifs.période.mai')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{props.prices[1]}€{props.capacity}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-t-0">{props.nights[1]} {t('maison.tarifs.période.nuits')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{t('maison.tarifs.période.juillet')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{props.prices[2]}€{props.capacity}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-t-0">{props.nights[2]} {t('maison.tarifs.période.nuits')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{t('maison.tarifs.période.septembre')}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-r-0 border-t-0">{props.prices[3]}€{props.capacity}</div>
                    <div className="h-full whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 border-4 border-gray-300 flex items-center border-t-0">{props.nights[3]} {t('maison.tarifs.période.nuits')}</div>
                </div>
            </div>
        </div>
    )
}

export type {
    ITarifs
};

export default Tarifs;