import { useEffect, useState } from "react";
import PromocodeModel from "../../models/Promocodes";

interface IProps {
    promocodes: PromocodeModel[];
    updatePromocodes: () => void;
}

const Header = () => {
    return (
        <div className="flex flex-col lg:flex-row justify-between items-center py-4 border-b">
            <div className="flex w-full flex-col lg:flex-row items-center">
                <div className="text-md mr-4 w-3/12">Code</div>
                <div className="text-md mr-4 w-3/12">Nuits offertes</div>
                <div className="text-md mr-4 w-3/12">Cadeau offert</div>
            </div>
            <div className="flex lg:flex-row items-center justify-around w-1/6">
            </div>
        </div>
    )
}

interface ILineProps {
    promocode: PromocodeModel;
    online?: boolean;
    deletePromocode: () => void;
    updatePromocodes: () => void;
}

const Line = (props: ILineProps) => {
    const changeCodeStatus = async () => {
        props.promocode.online = !props.promocode.online;

        await props.promocode.save();

        props.updatePromocodes();
    }

    return (
        <div className="flex flex-col lg:flex-row justify-between items-center py-4 odd:bg-gray-100">
            <div className="flex w-full flex-col lg:flex-row items-center">
                <div className="text-md mr-4 w-3/12">{props.promocode.code}</div>
                <div className="text-md mr-4 w-3/12">{props.promocode.nights} nuits</div>
                <div className="text-md mr-4 w-3/12">{props.promocode.gift ? 'Oui' : 'Non'}</div>
            </div>
            <div className="flex lg:flex-row items-center justify-around w-1/6">
                <button className="text-md mr-4" onClick={() => changeCodeStatus()}>{props.online ? 'Désactiver' : 'Activer'}</button>
                <button className="text-md mr-4" onClick={() => props.deletePromocode()}>Supprimer</button>
            </div>
        </div>
    )
}

const Promocode = (props: IProps) => {
    const [code, setCode] = useState<string>('');
    const [nights, setNights] = useState<number>(-1);
    const [gift, setGift] = useState<boolean>(false);

    const createPromocode = async () => {
        if (!code || nights < 0) return;

        const newCode = new PromocodeModel({
            code: code,
            nights: nights,
            gift: gift,
            online: true
        });

        if (!newCode) return;

        const res = await newCode.save();

        if (!res) return;

        console.log(res);

        props.updatePromocodes();
    };

    const deletePromocode = async (code: PromocodeModel) => {
        await code.delete();

        props.updatePromocodes();
    };

    return (
        <div className="py-16 px-2 lg:p-16">
            <div className="text-4xl">Codes promo</div>

            <div className="text-xl pb-8 pt-12">Ajouter un code promo</div>


            <div className="flex flex-col lg:flex-row justify-between items-center py-4 pb-12">
                <div className="flex w-full flex-col lg:flex-row items-center">
                    <input type="text" placeholder="Code" className="text-md mr-4 w-1/6 border border-gray-300 rounded-md px-2 py-1" onChange={(e) => setCode(e.target.value)} />
                    <input type="number" placeholder="Nuits offertes" className="text-md mr-4 w-1/6 border border-gray-300 rounded-md px-2 py-1" onChange={(e) => setNights(parseInt(e.target.value))} />
                    <div className="pl-2 mr-4 w-1/6 flex items-center text-lg">
                        <input type="checkbox" className="mr-2" onChange={(e) => setGift(e.target.checked)} />
                        <p>Cadeau offert</p>
                    </div>
                    <button className={`bg-[#515a75] text-white rounded-lg px-8 py-2`} onClick={() => createPromocode()}>Sauvegarder</button>
                </div>
            </div>

            <Header />
            {
                props.promocodes.filter((promocode) => promocode.online).map((promocode, index) => {
                    return (
                        <Line key={index} promocode={promocode} deletePromocode={() => deletePromocode(promocode)} updatePromocodes={() => props.updatePromocodes()} online />
                    )
                })
            }
            <div className="py-4 px-2 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                </svg>
                <p>Codes désactivés</p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                </svg>
            </div>
            {
                props.promocodes.filter((promocode) => !promocode.online).map((promocode, index) => {
                    return (
                        <Line key={index} promocode={promocode} deletePromocode={() => deletePromocode(promocode)} updatePromocodes={() => props.updatePromocodes()} />
                    )
                })
            }
        </div>
    )
}

export default Promocode;