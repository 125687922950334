import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReservationModel from "../../models/Reservations";
import Loading from "../../components/Loading";
import border from "../../assets/border.svg";
import Corner from "../../assets/corner.svg"
import Side from "../../assets/side.svg"
import '../../styles/index.css';
import Bougie from '../../assets/maison/chambres/suite1/bougie.jpg';
import Separator from "../../components/Maison/Separator";
const CheckoutValidated = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState<ReservationModel | null>(null);
    const [error, setError] = useState<string>("");
    const [price, setPrice] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [isGift, setIsGift] = useState<boolean>(false);
    const [isGiftReservation, setIsGiftReservation] = useState<boolean>(false);
    const borderStyle = {
        borderImage: `url('//s3-us-west-2.amazonaws.com/s.cdpn.io/4273/frame.png') 93 92 87 92 stretch stretch`,
        padding: "20px" // Ajoutez du padding pour que le contenu ne soit pas trop proche de la bordure
    };

    const initReservation = async () => {
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get("Ref");
        const reservation = await ReservationModel.get(idParam?.replace("CMD", "") || "");

        if (!reservation) {
            return;
        }
        setReservation(reservation);
        setError(url.searchParams.get("Error") || "");
        setPrice(Number(parseInt(url.searchParams.get("Mt") || "0") / 100).toFixed(2));
        setIsGift(url.searchParams.get("Gift") === "true");
        setIsGiftReservation(reservation?.gift?.isGift && !url.searchParams.get("Gift"));
        setLoading(false);
    }

    useEffect(() => {
        initReservation();
    }, []);

    return loading ? <Loading loading={loading} /> : (
        <div className='w-full h-full'>
            <div className='h-[78px] w-full bg-[#A5BFD8] flex items-center justify-center'></div>
            <div className="flex flex-col h-full justify-center items-center pb-16">

                <div className='flex flex-col w-full max-w-[1200px] h-full'>
                    <button className="flex items-center gap-2 w-fit hover:bg-[#e5ecff] pr-2 my-6 py-2" onClick={() => navigate("/")}>
                        <div className="hover:bg-[#e1e9f0] flex justify-center items-center p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <p className="text-xl">Retour au site</p>
                    </button>
                </div>


                <div className="ff-king text-[22px] pb-12">Réservation validée ! </div>

                {/* <Separator /> */}
                <div className="w-full lg:w-7/12 md:-mt-[150px]">
                    <Separator />
                </div>


                <div className="w-full lg:w-3/5 p-8 gap-4 pl-32 flex flex-col lg:justify-start">
                    <div className="ff-king text-[20px] underline pb-6"> Récapitulatif de la commande : </div>

                    <p><span className="ff-king text-[16px]">Numéro de commande :</span> {reservation?._id}</p>
                    {
                        !isGift && <p><span className="ff-king text-[16px]">Prix total :</span> {price}€</p>
                    }
                    {
                        !isGiftReservation && <>
                            <p><span className="ff-king text-[16px]">Date d'arrivée :</span> {reservation?.formatedArrivalDate()}</p>
                            <p><span className="ff-king text-[16px]">Date de départ :</span> {reservation?.formatedDepartureDate()}</p>
                        </>
                    }
                    <div className="ff-king w-10/12 text-[16px] text-justify pt-6">Un mail a été envoyé à l'adresse donnée dans le formulaire. Il contient un lien avec le récapitulatif des informations de la réservation et des indications pour vous aider à vous guider jusqu'à votre lieu de séjour. </div>


                </div >
            </div >
        </div >
    );
};

export default CheckoutValidated;
