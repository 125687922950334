import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cabane from '../../Cabane/Cabane';
import Tarifs from './Tarifs';
import { ITarifs } from './Tarifs';

interface IProps {
    showTitle?: boolean;
    opening?: string;
    showTaxes?: boolean;
    setMinHeight?: boolean;
    showSurface?: boolean;
    showName?: boolean;
    nights?: number[];
    prices?: number[];
}

const TarifsCabane = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const [tarifs, setTarifs] = useState<ITarifs>();

    useEffect(() => {
        // Fetch tarifs

        setTarifs({
            showTitle: props.showTitle,
            opening: props.opening,
            showTaxes: props.showTaxes,
            setMinHeight: props.setMinHeight,
            name: props.showName ? 'La Cabane' : undefined,
            winter: false,
            nights: props.nights || [2, 2, 5, 2],
            prices: props.prices || [90, 100, 100, 90],
            capacity: t('cabane.tarifs.personne'),
            surface: props.showSurface ? "20" : undefined
        });
    }, [i18n.language]);

    return (
        <div className='w-full h-full'>
            {
                tarifs && <Tarifs {...tarifs} />
            }
        </div>
    );
}

export default TarifsCabane;