import { useEffect, useState } from "react";
import { DateTime, Interval } from "luxon";
import ReservationModel from "../../models/Reservations";
import ExternalCalendarHandler from "./ExternalCalendarHandler";
import CalendarToolbar from "./CalendarToolbar";
import axios from "axios";
import CreateReservation from "./CreateReservation";
import MaisonModel from "../../models/Maisons";

interface HouseInterval {
    interval: Interval;
    house: number;
    external: boolean;
    external_src: string;
    blocked: boolean;
}

interface DayBooking {
    start: boolean;
    end: boolean;
    booked: number;
    firstDay: boolean;
}

interface IDayProps {
    index: number;
    currentDateTime: any;
    intervals: HouseInterval[];
    selecting: boolean;
    setSelectedDate: (adding: boolean, date: DateTime) => void;
    forceDeselect: boolean | null;
}

const Day = (props: IDayProps) => {
    const [startOfTheMonth, setStartOfTheMonth] = useState<number>(0);
    const [daysInMonth, setDaysInMonth] = useState<number>(0);
    const [bookings, setBookings] = useState<DayBooking[]>([]);
    const [isExternal, setIsExternal] = useState<boolean>(false);
    const [isBlocked, setIsBlocked] = useState<boolean>(false);
    const [externalSrc, setExternalSrc] = useState<string>('');
    const [hovered, setHovered] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<DateTime | null>(null);

    useEffect(() => {
        setStartOfTheMonth(Number(props.currentDateTime.startOf('month').weekday) - 1);
        setDaysInMonth(props.currentDateTime.daysInMonth);

        const currentDate = Number(props.currentDateTime.startOf('month').weekday) - 1 > props.index || (props.currentDateTime.daysInMonth + Number(props.currentDateTime.startOf('month').weekday) - 1) <= props.index ? null : props.currentDateTime.set({ day: props.index - Number(props.currentDateTime.startOf('month').weekday) + 2 });

        if (!currentDate) return;

        setCurrentDate(currentDate);

        const tmp_bookings = [];

        for (const i of props.intervals) {
            const tmp_booking: DayBooking = {
                start: false,
                end: false,
                booked: -1,
                firstDay: currentDate.day === 1
            };

            if (i.interval.set({
                start: i.interval.start.startOf('day'),
                end: i.interval.end.endOf('day')
            }).contains(currentDate)) {
                if (i.external || i.blocked) {
                    setIsExternal(i.external);
                    setIsBlocked(i.blocked);
                    setExternalSrc(i.blocked ? "" : i.external_src);
                    continue;
                }
                tmp_booking.booked = i.house;
                if (i.interval.start.day === currentDate.day && i.interval.start.month === currentDate.month) {
                    tmp_booking.start = true;
                } else if (i.interval.end.day === currentDate.day && i.interval.end.month === currentDate.month) {
                    tmp_booking.end = true;
                }
            }

            tmp_bookings.push(tmp_booking);
        }
        setBookings(tmp_bookings);
    }, []);

    const selectDay = () => {
        setSelected(!selected);

        if (!currentDate) return;
        props.setSelectedDate(!selected, currentDate);
    };

    useEffect(() => {
        if (!props.forceDeselect || isExternal || startOfTheMonth > props.index || (daysInMonth + startOfTheMonth) <= props.index) return;
        setSelected(false);
    }, [props.forceDeselect]);

    return (
        <div className={`relative border border-grey-200 aspect-square px-2 row-start-0 col-start-0 ${startOfTheMonth > props.index || (daysInMonth + startOfTheMonth) <= props.index ? 'bg-gray-100' : (isExternal || isBlocked) ? 'striped' : ''} ${selected ? 'border-gray-400' : ''} ${props.selecting ? 'cursor-pointer' : ''}`} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => props.selecting && !isExternal && startOfTheMonth <= props.index && (daysInMonth + startOfTheMonth) > props.index ? selectDay() : ''}>

            {/* Day */}
            <div className="flex justify-between items-center mt-2">

                {/* Day number */}
                <div>
                    {startOfTheMonth > props.index && <p className="opacity-40">{Number(props.currentDateTime.minus(1000 * 60 * 60 * 24).daysInMonth) - (startOfTheMonth - props.index - 1)}</p>}
                    {startOfTheMonth <= props.index && (daysInMonth + startOfTheMonth) > props.index && <p>{props.index - startOfTheMonth + 1}</p>}
                    {(daysInMonth + startOfTheMonth) <= props.index && <p className="opacity-40">{props.index - daysInMonth - startOfTheMonth + 1}</p>}
                </div>

                {/* Selection button */}
                <div>
                    {((hovered || selected || props.selecting) && !isExternal && startOfTheMonth <= props.index && (daysInMonth + startOfTheMonth) > props.index) &&
                        <button className={`rounded-full w-5 h-5 flex justify-center items-center ${selected ? 'bg-[#0b2564] border-3 text-white' : 'border border-gray-600'}`} onClick={() => selectDay()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={selected ? 3.5 : 1.5} stroke="currentColor" className="w-3 h-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </button>
                    }
                </div>
            </div>

            {/* {isExternal &&
                <div className={`absolute h-full w-full ${externalSrc.toUpperCase().match(/.*CABANE$/) ? '-top-6' : ''} left-0 flex flex-col justify-center items-center ${externalSrc.toUpperCase().match(/.*MAS$/) ? 'text-[#515a75]' : externalSrc.toUpperCase().match(/.*MAISON$/) ? 'text-[#7997e4]' : externalSrc.toUpperCase().match(/.*MAISONNETTE$/) ? 'text-[#90b796]' : 'text-[#9d8dc2]'} text-xl font-bold z-50`}>
                    {externalSrc.toUpperCase()}
                </div>
            } */}

            {/* Booking */}
            <div className="h-full -mx-[calc(0.5rem+1px)] flex flex-col justify-center gap-1">
                <div className={`text-[12px] xl:text-[16px] py-1 pl-4 text-white ${bookings.filter((b) => b.booked === 0)[0] ? 'bg-[#515a75]' : ''} ${bookings.filter((b) => b.booked === 0 && b.start)[0] ? 'rounded-l-2xl' : ''} ${bookings.filter((b) => b.booked === 0 && b.end)[0] ? 'rounded-r-2xl' : ''}`}><p className="hidden lg:flex">{bookings.filter((b) => b.booked === 0 && (b.start || b.firstDay))[0] ? 'LE MAS' : ''}&nbsp;</p></div>
                <div className={`text-[12px] xl:text-[16px] py-1 pl-4 text-white ${bookings.filter((b) => b.booked === 1)[0] ? 'bg-[#7997e4]' : ''} ${bookings.filter((b) => b.booked === 1 && b.start)[0] ? 'rounded-l-2xl' : ''} ${bookings.filter((b) => b.booked === 1 && b.end)[0] ? 'rounded-r-2xl' : ''}`}><p className="hidden lg:flex">{bookings.filter((b) => b.booked === 1 && (b.start || b.firstDay))[0] ? 'LA MAISON' : ''}&nbsp;</p></div>
                <div className={`text-[12px] xl:text-[16px] py-1 pl-4 text-white ${bookings.filter((b) => b.booked === 2)[0] ? 'bg-[#90b796]' : ''} ${bookings.filter((b) => b.booked === 2 && b.start)[0] ? 'rounded-l-2xl' : ''} ${bookings.filter((b) => b.booked === 2 && b.end)[0] ? 'rounded-r-2xl' : ''}`}><p className="hidden lg:flex">{bookings.filter((b) => b.booked === 2 && (b.start || b.firstDay))[0] ? 'LA CABANE' : ''}&nbsp;</p></div>
                <div className={`text-[12px] xl:text-[16px] py-1 pl-4 text-white ${bookings.filter((b) => b.booked === 3)[0] ? 'bg-[#9d8dc2]' : ''} ${bookings.filter((b) => b.booked === 3 && b.start)[0] ? 'rounded-l-2xl' : ''} ${bookings.filter((b) => b.booked === 3 && b.end)[0] ? 'rounded-r-2xl' : ''}`}><p className="hidden lg:flex">{bookings.filter((b) => b.booked === 3 && (b.start || b.firstDay))[0] ? 'LA MAISONNETTE' : ''}&nbsp;</p></div>
            </div>
        </div>
    )
}

interface IProps {
    reservations: ReservationModel[];
    maisons: MaisonModel[];
    updateReservations: () => void;
}

interface IDayElement {
    key: number;
    index: number;
    currentDateTime: DateTime;
    intervals: HouseInterval[];
}

const Calendar = (props: IProps) => {
    const [baseDateTime] = useState(DateTime.now());
    const [currentDateTime, setCurrentDateTime] = useState(DateTime.now().set({ day: 1 }));
    const [currentMonthOffset, setCurrentMonthOffset] = useState<number>(0);
    const [days, setDays] = useState<IDayElement[]>([]);
    const [daySelected, setDaySelected] = useState<DateTime[]>([]);
    const [deselectAll, setDeselectAll] = useState<boolean | null>(null);

    const initDays = (date?: DateTime) => {
        const local_intervals = initIntervals();
        const milli = DateTime.now().toMillis();
        let arr: IDayElement[] = [];

        for (let i = 0; i < 42; i++) {
            arr.push({
                key: milli + i,
                index: i,
                currentDateTime: date || currentDateTime,
                intervals: local_intervals
            })
        }

        setDays(arr);
    };

    const initIntervals = () => {
        const intervals = [];

        for (const reservation of props.reservations) {
            if (reservation.arrival && reservation.departure) {
                let interval = {
                    interval: Interval.fromISO(`${reservation.arrival}/${reservation.departure.minus({ days: reservation.external ? 1 : 0 })}`),
                    house: reservation.house.valueOf(),
                    external: reservation.external,
                    external_src: reservation.external_src,
                    blocked: reservation.blocked
                };

                intervals.push(interval);
            }
        }
        return intervals;
    };

    useEffect(() => {
        initDays();
    }, [props.reservations]);

    const updateOffset = (new_offset: number) => {
        setCurrentDateTime(currentDateTime.set({ month: (baseDateTime.month + currentMonthOffset + new_offset) % 12 }).set({ year: baseDateTime.year + (Math.floor((baseDateTime.month - 1 + currentMonthOffset + new_offset) / 12)) }));
        initDays(currentDateTime.set({ month: (baseDateTime.month + currentMonthOffset + new_offset) % 12 }).set({ year: baseDateTime.year + (Math.floor((baseDateTime.month - 1 + currentMonthOffset + new_offset) / 12)) }));
        setCurrentMonthOffset(currentMonthOffset + new_offset);
    }

    const blockDays = async (value: boolean) => {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/reservations/blocked`, {
            dates: daySelected.map((d) => d.toISODate()),
            shouldBlock: value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status === 200) {
            props.updateReservations();
        }
    }

    return (
        <div className="w-full px-8 md:px-16 2xl:px-32 py-16 overflow-scroll">
            <div className="w-full flex justify-between items-center text-3xl">
                <p className="px-4 pb-8 select-none">{currentDateTime.monthLong} {currentDateTime.year}</p>
                <div className="flex gap-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => updateOffset(-1)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => updateOffset(1)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
            </div>
            <div className="border border-grey-200 grid grid-rows-6 grid-cols-7">
                {
                    days.map((day: IDayElement) =>
                        <Day key={day.key} index={day.index} currentDateTime={day.currentDateTime} intervals={day.intervals} selecting={daySelected.length > 0} setSelectedDate={(adding: boolean, value: DateTime) => { setDeselectAll(false); adding ? setDaySelected([...daySelected, value]) : setDaySelected(daySelected.filter((date: DateTime) => date != value)) }} forceDeselect={deselectAll} />
                    )
                }
            </div>

            {/* Legende */}
            <div className="flex flex-col lg:hidden py-4">
                <div className="flex items-center">
                    <div className="rounded-full bg-[#515a75] w-8 h-2" />
                    <p className="text-[12px] pl-2">LE MAS</p>
                </div>
                <div className="flex items-center">
                    <div className="rounded-full bg-[#7997e4] w-8 h-2" />
                    <p className="text-[12px] pl-2">LA MAISON</p>
                </div>
                <div className="flex items-center">
                    <div className="rounded-full bg-[#90b796] w-8 h-2" />
                    <p className="text-[12px] pl-2">LA CABANE</p>
                </div>
                <div className="flex items-center">
                    <div className="rounded-full bg-[#9d8dc2] w-8 h-2" />
                    <p className="text-[12px] pl-2">LA MAISONNETTE</p>
                </div>
            </div>

            {daySelected.length > 0 && <CalendarToolbar deselectAll={() => { setDeselectAll(true); setDaySelected([]); }} blockDays={(value) => blockDays(value)} />}

            {/* Manually create reservations */}
            <CreateReservation maisons={props.maisons} />

            {/* External calendar links */}
            {/* <ExternalCalendarHandler /> */}
        </div>
    )
}

export default Calendar;