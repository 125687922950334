import { useEffect, useState } from "react";
import Banner from "./Banner";
import Hobbes from '../../assets/hobbes.jpg';
import Savons from '../../assets/savons.jpg';
import Savon from '../../assets/savon.jpg';
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner />
            <div className="w-full md:px-16 flex flex-col items-center mb-16">

                <div className="flex flex-row w-full">

                    {/* Container left */}
                    <div className="hidden lg:flex flex-row w-1/3 justify-center items-start py-20">
                        <div className="w-[476px] h-[418px] p-8 pb-16 bg-[#F6F6F6]" style={{ boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.3)" }}>
                            <img src={Hobbes} alt="Hobbes" className="w-[415px] h-[305px] object-cover object-center" />
                        </div>
                    </div>

                    {/* Container right */}
                    <div className="flex flex-col w-full lg:w-2/3">

                        {/* Text description + first image */}
                        <div className="flex flex-col lg:flex-row w-full mt-4">

                            {/* Text description */}
                            <div className="flex flex-col w-full 2xl:w-1/2 sm:px-16 pt-6 my-16 lg:my-32 2xl:my-0">
                                <p className="text-3xl ff-king text-[49px] pl-4">
                                    Sorène,
                                </p>
                                <div className="text-[22px] text-justify sm:pl-8 pr-4 sm:pr-16 leading-7 mt-2 pl-4">
                                    {t('milk.description')}
                                </div>
                                <button className="self-center sm:self-start ff-king text-[25px] bg-[#A5BFD8] py-2 px-4 sm:ml-8 mt-10" onClick={() => window.open("http://boutique.chevrerie-sorene.fr", "_blank")}>
                                    {t('milk.boutique')}
                                </button>
                            </div>

                            {/* Mobile Hobbes */}
                            <div className="flex lg:hidden self-center mx-4 sm:w-[476px] sm:h-[418px] p-8 pb-16 bg-[#F6F6F6]" style={{ boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.3)" }}>
                                <img src={Hobbes} alt="Hobbes" className="w-[415px] h-[305px] object-cover object-center" />
                            </div>

                            {/* First image */}
                            <div className="hidden 2xl:flex flex-row w-1/2">
                                <img src={Savons} alt="Savons" className="w-[389px] h-[372px] aspect-square object-cover object-center" />
                            </div>

                        </div>

                        {/* Second image */}
                        <div className="flex-row justify-center hidden 2xl:flex">
                            <img src={Savon} alt="Savon" className="w-[389px] h-[372px] aspect-square object-cover object-center mt-8" />
                        </div>
                    </div>
                </div>

                {/* Mobile images */}
                <div className="md:gap-16 lg:gap-0 flex flex-col items-center md:flex-row justify-evenly px-6 sm:px-0 w-full sm:w-1/2 md:w-full 2xl:hidden">
                    <img src={Savon} alt="Savon" className="w-full md:w-1/2 lg:w-[389px] lg:h-[372px] aspect-square object-cover object-center mt-8" />

                    {/* Mobile first image */}
                    <img src={Savons} alt="Savons" className="w-full md:w-1/2 lg:w-[389px] lg:h-[372px] aspect-square object-cover object-center mt-8" />
                </div>
            </div>
        </div>
    );
}

export default Home;