import axios from 'axios';

class PromocodeModel {
    _id: number | number;
    code: string;
    nights: number;
    gift: boolean;
    online: boolean;

    constructor(raw?: any) {
        this._id = raw?._id || null;
        this.code = raw?.code || '';
        this.nights = raw?.nights || 0;
        this.gift = raw?.gift || false;
        this.online = raw?.online || false;
    }

    static promocodes: PromocodeModel[] = [];

    static init = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/promocodes`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        this.promocodes = [];
        for (let reservation of res.data || []) {
            this.promocodes.push(new PromocodeModel(reservation));
        }

        return this.promocodes;
    }

    save = async () => {
        let res;

        if (this._id) {
            res = await axios.put(`${process.env.REACT_APP_API_URL}/promocodes/${this._id}`, this, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        } else {
            const { _id, ...data } = this;
            res = await axios.post(`${process.env.REACT_APP_API_URL}/promocodes`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        }

        return res;
    }

    delete = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/promocodes/${this._id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        return true;
    }
}

export default PromocodeModel;