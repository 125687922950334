const confidentialite = () => {

    return (
        <div className="px-8 md:px-48 text-justify">
            <h1 className="ff-king text-2xl md:text-4xl mt-20 mb-10 text-center md:text-start">Mentions légales et Conditions Générales de Vente</h1>

            <p className="font-bold text-[18px] pt-4 pb-2">1 - Dispositions de la loi "Informatique et Libertés"</p>
            <p>Pour les besoins de la vente en ligne, la Chèvrerie Sorène enregistre les coordonnées de ses clients. Elle s'engage à ne pas utiliser ces données à d'autres fins, quelles qu'elles soient. Le client dispose d'un droit d'accès, de modification et de suppression des données qui le concernent (art. 34 de la Loi Informatique et Libertés du 06/01/78). Il peut exercer ce droit en envoyant un mail à sorenecosmetiques@gmail.com ou bien en envoyant un courrier à l'adresse suivante : Chèvrerie Sorène - Les Aydons - 48800 Pied de Borne.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">2 – Acceptation des conditions de vente</p>
            <p>Le présent contrat détermine les droits et obligations des parties dans le cadre des locations proposées sur le site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a></p>

            <p>En validant la commande, le client reconnaît avoir pris connaissance des conditions générales de vente et les accepter sans réserve. La Maison Sorène se réserve le droit de modifier à tout moment les présentes conditions générales de vente.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">3 - Informations sur les tarifs</p>
            <p>Tous les prix sont exprimés en Euros, toutes taxes comprises. La Maison Sorène se réserve le droit de modifier ses prix à tout moment, mais s'engage à appliquer les tarifs en vigueur sur le site au moment de la commande.</p>
            <p>Le réglement se fait à la réservation.</p>
            <p>La carte bancaire est le seul moyen de paiement accepté.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">4 - Annulation</p>
            <p>Remboursement intégral si la date d’arrivée intervient plus de 14 jours après la demande d’annulation</p>
            <p>Remboursement de 50% pour une annulation intervenant moins de 14 jours avant l’arrivée</p>
            <p>Aucun remboursement si l’annulation intervient moins de 7 jours avant l’arrivée</p>

            <p className="font-bold text-[18px] pt-4 pb-2">5 - Utilisation des lieux</p>
            <p>Les locations sont accessibles à partir de 16h00 le jour d’arrivée et doivent être libérées à 11h00 le jour du départ.</p>
            <p>Les locataires doivent respecter les règles de sécurité et de bon voisinage.</p>
            <p>Dans le cas d'une dégradation ou d'un objet cassé, nous estimerons ensemble le préjudice à réparer.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">6 - Responsabilité</p>
            <p>La Maison Sorène ne pourra être tenue responsable des dommages directs et indirects causés au matériel du client, lors de l’utilisation des lieux.</p>
            <p>Les locations ne sont pas accessibles aux enfants de moins de 10 ans sans l'accord préalable des propriétaires.</p>
            <p>Avec ou sans accord préalable, la Maison Sorène ne pourra être tenue responsable des dommages directs et indirects causés par ou à un enfant de moins de 10 ans</p>

            <p className="font-bold text-[18px] pt-4 pb-2">7 - Accès au site</p>
            <p>La Maison Sorène s’efforce de fournir sur le site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a> des informations les plus précises possibles. Les renseignements figurant sur le site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a> ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
            <p>Toutefois, il peut arriver que les informations sur le site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a> soient incomplètes, erronées ou ne soient plus à jour. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par mail à sorenecosmetiques@gmail.com</p>

            <p className="font-bold text-[18px] pt-4 pb-2">8 - Propriété intellectuelle</p>
            <p>Tout le contenu du présent site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la Maison Sorène à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.</p>
            <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de la Maison Sorène.</p>
            <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">9 - Gestion des données personnelles</p>
            <p>En France, les données personnelles sont notamment protégées par la loi n°78-87 du 6 janvier 1978, la loi n°2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
            <p>En tout état de cause la Maison Sorène ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a>. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a> l’obligation ou non de fournir ces informations.</p>
            <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
            <p>Aucune information personnelle de l’utilisateur du site <a href="http://maisonsorene.com" className="underline">http://maisonsorene.com</a> n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.</p>
            <p>Le site n’est pas déclaré à la CNIL car il ne recueille pas d’informations personnelles.</p>
            <p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>

            <p className="font-bold text-[18px] pt-4 pb-2">10 - Généralités</p>
            <p>Exploitation individuelle : François Garnier - Chèvrerie Sorène</p>
            <p>N° Siret : 517 903 910 00019</p>
            <p>Nom commercial : François Garnier - Chèvrerie Sorène</p>
            <p>Email : sorenecosmetiques@gmail.com</p>
            <p>Adresse postale : Chèvrerie Sorène - Les Aydons - 48800 Pied de Borne</p>
            <p>Téléphone : 06 68 71 77 13</p>
        </div>
    );
};

export default confidentialite;
