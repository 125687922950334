import Logo from "../assets/logo.png";
import Facebook from "../assets/fb-logo.png";
import Instagram from "../assets/ig-logo.png";
import { useTranslation } from "react-i18next";

interface IProps {
    setIndex: (index: number) => void;
}

const Footer = (props: IProps) => {
    const { t } = useTranslation();
    const indexName = ["home", "maison", "cabane", "soins", "histoire", "informations", "milknlove", "reseaux", "confidentialite", "mentionslegales"];

    const changeTab = (index: number) => {
        props.setIndex(index);

        if (index === 0) {
            window.location.href = "/";
        } else {
            window.history.pushState({}, "", `${indexName[index]}`);
        }
    };

    return (
        <div className="footer bg-[#E3EAEC] flex flex-col mt-[70px] ff-zilla">

            {/* First line */}
            <div className="h-full flex flex-row items-center justify-between py-3">

                {/* Logo */}
                <div className="lg:w-full pl-6">
                    <img src={Logo} alt="Logo" className="cursor-pointer w-[120px] md:w-[160px] lg:w-[180px]" onClick={() => changeTab(0)} />
                </div>

                {/* Boutons */}
                <div className="hidden w-full md:flex flex-row justify-center items-center text-[12px] lg:text-[15px] whitespace-nowrap">
                    <div className="flex flex-col items-start">
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(1)}>{t('navbar.maison')}</p>
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(2)}>{t('navbar.cabane')}</p>
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(4)}>{t('navbar.soins')}</p>
                    </div>

                    {/* Separator */}
                    <div className="w-1 h-[60px] bg-[#297596] mx-[52px]"></div>

                    <div className="flex flex-col items-start">
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(5)}>{t('navbar.histoire')}</p>
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(6)}>{t('navbar.info')}</p>
                        <p className="cursor-pointer hover:underline" onClick={() => changeTab(7)}>{t('navbar.milk')}</p>
                    </div>
                </div>

                {/* Social */}
                <div className="lg:w-full flex flex-row justify-end pr-10 lg:pr-36 items-center">
                    <img src={Facebook} alt="Facebook" className="cursor-pointer w-[23px] min-w-[23px] min-h-[23px] h-[23px]" onClick={() => window.open("https://www.facebook.com/sorene.cosmetiques", "_blank")} />
                    <img src={Instagram} alt="Instagram" className="cursor-pointer w-[23px] min-w-[23px] h-[23px] min-h-[23px] ml-6 -mr-1" onClick={() => window.open("https://www.instagram.com/sorene_en_cevennes/", "_blank")} />
                </div>
            </div>

            {/* Second line */}
            <div className="text-[12px] h-full flex flex-row items-center justify-between py-1 border-2 bg-[#EDF2F3] border-y-[#297596]/50">
                <div className="w-full md:w-[160px] lg:w-full pl-2 md:pl-6">
                    <p>Sorène, Les Aydons 48800 Pied de Borne</p>
                    <p>06 68 71 77 13</p>
                    <p>sorenecosmetiques@gmail.com</p>
                </div>
                <div className="w-fit whitespace-nowrap lg:w-full flex flex-row justify-end pr-10 lg:pr-36">Clem et Flo</div>
            </div>

            {/* Third line */}
            <div className="h-full flex flex-row justify-center">
                <div className="h-full w-full flex flex-row items-center justify-around max-w-2xl text-center text-[12px]">
                    <p>© Sorène en Cévennes 2022</p>
                    <p className="cursor-pointer hover:underline" onClick={() => changeTab(10)}>Mentions légales et CGV</p>
                    {/* <p className="cursor-pointer hover:underline" onClick={() => changeTab(9)}>Déclaration de confidentialité</p> */}
                </div>
            </div>
        </div>
    )
}

export default Footer;