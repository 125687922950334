import { useTranslation } from 'react-i18next';
import Image from '../../assets/chevre.jpg';

const Banner = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full relative">
            <img src={Image} alt="" className="w-full" />
        </div>
    )
}

export default Banner