import { useTranslation } from 'react-i18next';
import Proj from '../../assets/soins/projecteurRECAD.png';

interface IProps {
    scrollToRef: (ref: any) => void;
    ref1: any;
    ref2: any;
    ref3: any;
    ref4: any;
    ref5: any;
}

const Banner = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className="w-full relative">
            <img src={Proj} alt="" className="w-full object-cover lg:h-[100vh]" />
            <div className='ff-king absolute opacity-80 text-white w-[337px] 
                top-3 left-5 text-[22px]
                sm:top-8 sm:left-10 sm:text-[25px]
                lg:top-10 lg:left-16 lg:text-[25px]
                xl:top-16 xl:left-24 xl:text-[25px]
                2xl:top-16 2xl:left-24 2xl:text-[25px]
                flex flex-col justify-evenly h-[90%]'>
                <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref1)}>{t('soins.sections.lecture')}</div>
                <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref4)}>{t('soins.sections.massages')}</div>
                <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref5)}>{t('soins.sections.yoga')}</div>
                <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref2)}>{t('soins.sections.chevre')}</div>
                {/* <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref3)}>{t('soins.sections.aline')}</div> */}
                <div className="hidden md:flex pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-white whitespace-nowrap" onClick={() => props.scrollToRef(props.ref3)}>{t('soins.sections.sorene')}</div>
            </div>
        </div>
    )
}

export default Banner