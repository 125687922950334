import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './views/App';
import Reservation from './views/Reservation';
import Auth from './views/Auth';
import Admin from './views/Admin';
import CheckoutValidated from './views/checkout/CheckoutValidated';
import CheckoutCancelled from './views/checkout/CheckoutCancelled';
import CheckoutRefused from './views/checkout/CheckoutRefused';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import "./assets/fonts/kingthings_trypewriter_2.ttf";
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Routes>
      <Route path="/*" element={<App />} />
      <Route path="/reservation" element={<Reservation />} />
      <Route path="/reservation/:house" element={<Reservation />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/checkout/validated" element={<CheckoutValidated />} />
      <Route path="/checkout/cancelled" element={<CheckoutCancelled />} />
      <Route path="/checkout/refused" element={<CheckoutRefused />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
);
