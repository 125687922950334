import { useEffect, useState } from "react";
import { checkAuth } from "../services/auth";
import Loading from "../components/Loading";
import Logo from "../assets/round_logo.png"
import Calendar from "../components/Admin/Calendar";
import ReservationModel from "../models/Reservations";
import MaisonModel from "../models/Maisons";
import PromocodeModel from "../models/Promocodes";
import Booking from "../components/Admin/Booking";
import Tarifs from "../components/Admin/Tarifs";
import { useTranslation } from "react-i18next";
import Promocodes from "../components/Admin/Promocodes";

const Admin = () => {
    const { t } = useTranslation();
    const [auth, setAuth] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(1);
    const [reservations, setReservations] = useState<ReservationModel[] | null>(null);
    const [maisons, setMaisons] = useState<MaisonModel[] | null>(null);
    const [promocodes, setPromocodes] = useState<PromocodeModel[] | null>(null);

    const checkAuthentication = async () => {
        const res = await checkAuth()

        if (res) {
            setAuth(true);
        } else {
            window.location.href = "/auth";
        }
    }

    const logout = () => {
        localStorage.removeItem("token");
        window.location.href = "/auth";
    }

    const initReservations = async () => {
        const res = await ReservationModel.init();

        setReservations(res);
    }

    const initMaisons = async () => {
        const res = await MaisonModel.init();

        setMaisons(res);
    }

    const initPromocodes = async () => {
        const res = await PromocodeModel.init();

        setPromocodes(res);
    }

    useEffect(() => {
        checkAuthentication();
        initReservations();
        initMaisons();
        initPromocodes();
    }, [])

    return auth ? (
        <div className="flex h-[100vh] w-full">

            {/* Sidebar */}
            <div className="hidden md:flex flex-col justify-between items-start bg-white w-3/6 lg:w-4/12 xl:w-3/12 2xl:w-2/12 border-r border-[#e4e4ec] select-none">

                {/* Logo */}
                <div className="w-full flex items-center py-4 border-b border-[#e4e4ec] pl-4">

                    <img src={Logo} alt="Logo" className="w-12 h-12 border-2 border-[#8d8d8d] rounded-full mr-4" />
                    <div>
                        <p className="text-2xl">Sorène</p>
                        <p className="text-xl -mt-2">Dashboard</p>
                    </div>
                </div>

                {/* Buttons */}
                <div className="w-full h-full flex flex-col items-center py-8">

                    {/* Calendrier */}
                    <div className={`flex items-center cursor-pointer hover:bg-[#edecff] rounded-lg w-[90%] px-6 py-4 my-1 ${index === 1 ? 'bg-[#edecff]' : ''}`} onClick={() => setIndex(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                        </svg>
                        <p className="pl-2 text-xl">Calendrier</p>
                    </div>

                    {/* Réservations */}
                    <div className={`flex items-center cursor-pointer hover:bg-[#edecff] rounded-lg w-[90%] px-6 py-4 my-1 ${index === 2 ? 'bg-[#edecff]' : ''}`} onClick={() => setIndex(2)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                        <p className="pl-2 text-xl">Réservations</p>
                    </div>

                    {/* Tarifs */}
                    <div className={`flex items-center cursor-pointer hover:bg-[#edecff] rounded-lg w-[90%] px-6 py-4 my-1 ${index === 3 ? 'bg-[#edecff]' : ''}`} onClick={() => setIndex(3)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                        </svg>
                        <p className="pl-2 text-xl">Tarifs</p>
                    </div>

                    {/* Codes promo */}
                    <div className={`flex items-center cursor-pointer hover:bg-[#edecff] rounded-lg w-[90%] px-6 py-4 my-1 ${index === 4 ? 'bg-[#edecff]' : ''}`} onClick={() => setIndex(4)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p className="pl-2 text-xl">Codes promo</p>
                    </div>
                </div>

                {/* Logout */}
                <div className="w-full py-6 flex justify-center">
                    <button className="flex items-center hover:bg-[#e2e2e4] px-4 py-2 rounded-full" onClick={async () => logout()}>
                        <p className="pb-1 pr-2">Se déconnecter</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Content */}
            {
                reservations && <div className={`w-full overflow-scroll ${index === 1 ? '' : 'hidden'}`}><Calendar key={index} maisons={maisons || []} reservations={reservations.filter((res: ReservationModel) => res.validated || res.external || res.blocked)} updateReservations={() => initReservations()} /></div>
            }

            {
                reservations && <div className={`w-full overflow-scroll ${index === 2 ? '' : 'hidden'}`}><Booking key={index + 10} reservations={reservations} /></div>
            }

            {
                maisons && <div className={`w-full overflow-scroll ${index === 3 ? '' : 'hidden'}`}><Tarifs key={index + 20} maisons={maisons} /></div>
            }

            {
                promocodes && <div className={`w-full overflow-scroll ${index === 4 ? '' : 'hidden'}`}><Promocodes key={index + 30} promocodes={promocodes} updatePromocodes={() => initPromocodes()} /></div>
            }
        </div>
    ) : (
        <Loading loading={!auth} />
    )
}

export default Admin