import { useEffect, useState } from "react";
import ReservationModel from "../../models/Reservations";
import { Interval, DateTime } from "luxon";
import BookingLines from "./BookingLines";
import BookingDetails from "./BookingDetails";

interface IProps {
    reservations: ReservationModel[];
}

const Booking = (props: IProps) => {
    const [filteredRes, setFilteredRes] = useState<ReservationModel[]>([]);
    const [houseTypeFilter, setHouseTypeFilter] = useState<number>(-1);
    const [statusFilter, setStatusFilter] = useState<number>(-1);
    const [intervalFilter, setIntervalFilter] = useState<Interval | null>(null);
    const [resDetails, setResDetails] = useState<ReservationModel | null>(null);
    const [toCome, setToCome] = useState<boolean>(true);
    const [currently, setCurrently] = useState<boolean>(true);
    const [passed, setPassed] = useState<boolean>(false);

    useEffect(() => {
        setFilteredRes(props.reservations
            .sort((a: ReservationModel, b: ReservationModel) => {
                const status_a = a.validated ? 0 : a.cancelled ? 2 : 1;
                const status_b = b.validated ? 0 : b.cancelled ? 2 : 1;

                if (status_a !== status_b) {
                    return status_a - status_b;
                } else {
                    return a.arrival.valueOf() - b.arrival.valueOf();
                }
            })
            .filter((res: ReservationModel) =>
                !res.external
                && !res.blocked
                && (houseTypeFilter === -1 || res.house === houseTypeFilter)
                && ((!toCome && !currently && !passed) || (toCome && res.arrival.valueOf() > DateTime.now().valueOf()) || (currently && res.arrival.valueOf() <= DateTime.now().valueOf() && res.departure.valueOf() >= DateTime.now().valueOf()) || (passed && res.departure.valueOf() < DateTime.now().valueOf()))
                && (statusFilter === -1
                    || (res.validated && statusFilter === 0)
                    || (res.cancelled && statusFilter === 2)
                    || (!res.validated && !res.cancelled && statusFilter === 1)
                ) && (
                    intervalFilter === null
                    || ((res.arrival && intervalFilter.contains(res.arrival)) || (res.departure && intervalFilter.contains(res.departure)))
                )
            ));
    }, [houseTypeFilter, statusFilter, intervalFilter, toCome, currently, passed, resDetails]);

    return (
        <div className="py-16 px-2 lg:p-16">
            <div className="text-4xl">Réservations</div>

            {/* Search / Filters */}
            <div className="flex justify-between my-4 border-b pb-4">

                {/* Filters */}
                <div className={`flex-1 flex flex-col 2xl:flex-row justify-end gap-4 ${resDetails ? 'hidden' : ''}`}>

                    {/* Checkboxes */}
                    <div className="flex gap-4">
                        {/* Passed reservations checkbox */}
                        <div className="flex items-center">
                            <input type="checkbox" className="mr-2" checked={passed} onChange={(e) => setPassed(e.target.checked)} />
                            <label className="text-xl">Passées</label>
                        </div>

                        {/* Currently reservations checkbox */}
                        <div className="flex items-center">
                            <input type="checkbox" className="mr-2" checked={currently} onChange={(e) => setCurrently(e.target.checked)} />
                            <label className="text-xl">En cours</label>
                        </div>

                        {/* To come reservations checkbox */}
                        <div className="flex items-center">
                            <input type="checkbox" className="mr-2" checked={toCome} onChange={(e) => setToCome(e.target.checked)} />
                            <label className="text-xl">À venir</label>
                        </div>
                    </div>

                    {/* Dropdowns */}
                    <div className="flex gap-4">
                        {/* House type filter */}
                        <select className="px-2 py-1 w-full lg:w-fit" onChange={(e) => setHouseTypeFilter(parseInt(e.target.value))}>
                            <option value={-1}>Lieu</option>
                            <option value={0}>Le Mas</option>
                            <option value={1}>La Maison</option>
                            <option value={2}>La Cabane</option>
                            <option value={3}>La Maisonnette</option>
                        </select>

                        {/* Status filter */}
                        <select className="px-2 py-1 w-full lg:w-fit" onChange={(e) => setStatusFilter(parseInt(e.target.value))}>
                            <option value={-1}>Statut</option>
                            <option value={0}>Validées</option>
                            <option value={1}>En attente</option>
                            <option value={2}>Cancelled</option>
                        </select>
                    </div>

                    {/* Date inputs */}
                    <div className="flex gap-4">
                        {/* Date interval picker */}
                        <div className="h-full flex items-center w-full lg:w-fit">
                            <p className="text-lg pr-4 lg:px-4">De:</p>
                            <input className="h-full text-lg focus:outline-none bg-gray-200 px-2 w-full" type="date" onChange={(value: any) => { setIntervalFilter(Interval.fromDateTimes(DateTime.fromISO(value.target.value || DateTime.local().minus({ years: 10 }).toISO()), intervalFilter?.end || DateTime.local().plus({ years: 10 }))) }} />
                        </div>

                        <div className="h-full flex items-center w-full lg:w-fit">
                            <p className="text-lg pr-4 lg:px-4">à:</p>
                            <input className="h-full text-lg focus:outline-none bg-gray-200 px-2 w-full" type="date" onChange={(value: any) => { setIntervalFilter(Interval.fromDateTimes(intervalFilter?.start || DateTime.local().minus({ years: 10 }), DateTime.fromISO(value.target.value || DateTime.local().plus({ years: 10 }).toISO()))) }} />
                        </div>
                    </div>
                </div>
            </div>

            {
                (!resDetails && <BookingLines reservations={filteredRes} openDetails={(res: ReservationModel) => setResDetails(res)} />) || (resDetails && <BookingDetails reservation={resDetails} closeDetails={() => setResDetails(null)} />)
            }
        </div>
    )
}

export default Booking;