import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { fr } from 'date-fns/locale';
import MaisonModel from "../../models/Maisons";
import Select from 'react-select';
import ReservationModel from "../../models/Reservations";

interface IProps {
    maisons: MaisonModel[];
}

const CreateReservation = (props: IProps) => {
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [houseChoice, setHouseChoice] = useState<number>(props.maisons[0]?.houseId);
    const [options, setOptions] = useState<{ value: number, label: string }[]>(
        props.maisons.map((maison: MaisonModel, index: number) => {
            return { value: maison.houseId, label: maison.name };
        })
    );
    const [rangeState, setRangeState] = useState<{
        startDate?: Date,
        endDate?: Date,
        key?: string
    }[]>([
        {
            startDate: DateTime.now().plus({ days: 1 }).toJSDate(),
            endDate: DateTime.now().plus({ days: 2 }).toJSDate(),
            key: 'selection'
        }
    ]);

    const createReservation = async () => {
        const reservation = new ReservationModel({
            house: houseChoice,
            arrival: rangeState[0].startDate?.toISOString(),
            departure: rangeState[0].endDate?.toISOString(),
            validated: true,
            contact: {
                name: name,
                phone: phone
            }
        });
        const res = await reservation.save();

        if (res.status === 200) {
            window.location.reload();
        }
    }

    return (
        <div>
            <p className="text-3xl mt-16 mb-10">Ajouter une réservation</p>

            <div className='w-full flex flex-col lg:flex-row gap-8'>
                <div className='w-fit'>
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => setRangeState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={rangeState}
                        locale={fr}
                        showDateDisplay={false}
                        className='w-full rounded-xl border'
                        minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
                    />
                </div>

                <div className="w-full h-full flex flex-col justify-between">
                    <Select
                        options={options}
                        onChange={(value) => value && setHouseChoice(value.value)}
                        isMulti={false}
                        defaultValue={options[0]}
                        className='w-full h-fit'
                    />

                    <div className="w-full flex flex-col xl:flex-row pt-4 gap-2 lg:gap-4">
                        <input type="text" className="w-full xl:w-1/2 rounded-lg border px-4 py-2" placeholder="Nom" onChange={(e) => setName(e.target.value)} />
                        <input type="text" className="w-full xl:w-1/3 rounded-lg border px-4 py-2" placeholder="Téléphone" onChange={(e) => setPhone(e.target.value)} />
                        <div className="w-1/3 hidden xl:flex"></div>
                    </div>

                    <div className="flex justify-end w-full">
                        <button className={`bg-[#515a75] text-white rounded-lg px-8 py-2 mt-8`} onClick={() => createReservation()}>Sauvegarder</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateReservation;