import { useRef, useState } from "react";
import Banner from "./Banner";
import Escabeau from '../../assets/maison/escabeau.jpg';
import Separator from "./Separator";

import Vignette1 from '../../assets/maison/vignette1.jpg';
import Vignette2 from '../../assets/maison/vignette2.jpg';
import Vignette3 from '../../assets/maison/vignette3.jpg';

import Bougie from '../../assets/maison/chambres/suite1/bougie.jpg';
import Radiateur from '../../assets/maison/chambres/suite1/radiateur.jpg';
import Lavabo from '../../assets/maison/chambres/suite1/lavabo.jpg';
import Lit from '../../assets/maison/chambres/suite1/lit.jpg';
import BainLavabo from '../../assets/maison/chambres/suite1/bain_lavabo.jpg';
import Baignoire from '../../assets/maison/chambres/suite1/baignoire.jpg';
import Fenetre from '../../assets/maison/chambres/suite1/fenetre.jpg';
import Chambre from '../../assets/maison/chambres/suite1/chambre.jpg';

import Lit2 from '../../assets/maison/chambres/suite2/lit.jpg';
import Fenetre2 from '../../assets/maison/chambres/suite2/fenetre.jpg';
import Chambre2 from '../../assets/maison/chambres/suite2/chambre.jpg';
import Lavabo2 from '../../assets/maison/chambres/suite2/lavabo.jpg';
import Bains from '../../assets/maison/chambres/suite2/bains.jpg';
import Luminaire from '../../assets/maison/chambres/suite2/luminaire.jpg';
import Rame from '../../assets/maison/chambres/suite2/rame.jpg';

import Balcon from '../../assets/maison/exterieurs/balcon.jpg';
import Figuier from '../../assets/maison/exterieurs/figuier.jpg';
import Transats from '../../assets/maison/exterieurs/transats_bis.jpg';
import Chemin from '../../assets/maison/exterieurs/chemin.jpg';
import Riviere1 from '../../assets/maison/exterieurs/riviere1.jpg';
import Riviere2 from '../../assets/maison/exterieurs/riviere2.jpg';
import Riviere3 from '../../assets/maison/exterieurs/riviere3.jpg';
import Miroir2 from '../../assets/maison/exterieurs/miroir.jpg';
import Pierres from '../../assets/maison/exterieurs/pierres.jpg';
import Arbres from '../../assets/maison/exterieurs/arbres.jpg';
import Muret from '../../assets/maison/exterieurs/muret.jpg';
import Baldaquin from '../../assets/maison/exterieurs/baldaquin.jpg';
import VueEnsemble from '../../assets/maison/exterieurs/vueEnsemble.jpg';

import Animaux from '../../assets/maison/espaces/animaux.jpg';
import Boule from '../../assets/maison/espaces/boule.jpg';
import Bureau from '../../assets/maison/espaces/bureau.jpg';
import Chat from '../../assets/maison/espaces/chat.jpg';
import Cheminee from '../../assets/maison/espaces/cheminee.jpg';
import Ensemble from '../../assets/maison/espaces/ensemble.jpg';
import Etagere from '../../assets/maison/espaces/etagere.jpg';
import Lit3 from '../../assets/maison/espaces/lit.jpg';
import Manger from '../../assets/maison/espaces/manger.jpg';
import Miroir from '../../assets/maison/espaces/miroir.jpg';
import Porte from '../../assets/maison/espaces/porte.jpg';
import Projecteur from '../../assets/maison/espaces/projecteur.jpg';
import Table from '../../assets/maison/espaces/table.jpg';
import Tupperware from '../../assets/maison/espaces/tupperware.jpg';

import Baignoire2 from '../../assets/maison/studio/baignoire.png';
import ChaisesJour from '../../assets/maison/studio/chaises_jour.png';
import ChaisesNuit from '../../assets/maison/studio/chaises_nuit.jpeg';
import Cuisine from '../../assets/maison/studio/cuisine.png';
import Fenetre3 from '../../assets/maison/studio/fenetre.jpg';
import Lit4 from '../../assets/maison/studio/lit.png';
import Table2 from '../../assets/maison/studio/table.jpg';
import Tableau from '../../assets/maison/studio/tableau.jpg';
import Terrasse from '../../assets/maison/studio/terrasse.png';
import Transats2 from '../../assets/maison/studio/transats.png';
import VueTerrasse from '../../assets/maison/studio/vue_terrasse.jpg';

import Sapin from '../../assets/maison/noel/sapin.jpg';
import Creche2 from '../../assets/maison/noel/creche2.jpg';
import Creche1 from '../../assets/maison/noel/creche1.jpg';
import Couronne from '../../assets/cabane/couronne.jpg';

import TarifsMaison from "../Shared/Tarifs/TarifsMaison";
import TarifsMas from "../Shared/Tarifs/TarifsMas";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Carousel from "../Shared/Carousel";
import MaisonModel from "../../models/Maisons";

interface IProps {
    maison?: MaisonModel,
    mas?: MaisonModel
}

const Maison = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const separatorRef = useRef(null);
    const separatorRef2 = useRef(null);
    const separatorRef3 = useRef(null);
    const separatorRef4 = useRef(null);

    const [imageIndex1, setImageIndex1] = useState<number | null>(null);
    const [imageIndex2, setImageIndex2] = useState<number | null>(null);
    const [imageIndex3, setImageIndex3] = useState<number | null>(null);
    const [imageIndex4, setImageIndex4] = useState<number | null>(null);
    const [imageIndex5, setImageIndex5] = useState<number | null>(null);

    // Scroll to ref smoothly
    const scrollToRef = (ref: any) => {
        window.scrollTo({
            top: ref.current.offsetTop - 73,
            behavior: "smooth"
        });
    }

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>
            <Banner />
            <div className="w-full sm:px-8 lg:px-32 flex flex-col items-center lg:mb-16 text-[22px] ff-zilla leading-7 text-justify">

                {/* Index */}
                <div className="flex flex-col lg:flex-row w-full xl:gap-36 mt-16 px-8 md:px-0 xl:px-8 2xl:pl-24 2xl:pr-24">
                    <div className="w-full lg:w-1/2">
                        <img src={Escabeau} alt="Escabeau" className="w-full object-cover" />
                    </div>
                    <div className="w-full lg:w-1/2 pt-12 sm:pt-6 lg:pt-0 xl:pt-12 pl-0 lg:pl-12 xl:p-0 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                        <p>{t('maison.description')}</p>
                        <p>{t('maison.capacité')}</p>

                        <div className="flex flex-col ff-king w-full sm:w-2/3 md:w-1/2 lg:w-2/3 pt-12 lg:pt-24 md:pl-8">
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef)}>{t('maison.sections.chambres')}</div>
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef2)}>{t('maison.sections.espaces')}</div>
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef4)}>{t('maison.sections.exterieurs')}</div>
                            <div className="pt-5 xl:pt-8 2xl:pt-16 pb-3 xl:pb-6 2xl:pb-8 cursor-pointer hover:font-bold border-b-2 border-black whitespace-nowrap" onClick={() => scrollToRef(separatorRef3)}>{t('maison.sections.studio')}</div>
                        </div>
                    </div>
                </div>

                {/* Separator */}
                <div ref={separatorRef} className="w-full hidden md:flex">
                    <Separator vignette={Vignette1} />
                </div>

                {/* Des chambres à rêver */}
                {/* First section */}
                <div className="flex flex-row w-full mt-24 md:mt-10">

                    <div className="w-full xl:w-[55%] flex flex-col gap-2 md:gap-4 xl:gap-7 px-8 lg:pl-12">

                        {/* Des chambres à rêver */}
                        <p className="ff-king text-[20px] lg:text-[25px]">{t('maison.section1.titre')}</p>

                        {/* Large image mobile */}
                        <img src={Chambre} alt="Chambre" className="flex xl:hidden w-full object-cover object-center pt-6 cursor-pointer" onClick={() => setImageIndex1(7)} />

                        {/* Header image */}
                        <div className="w-full hidden xl:grid grid-rows-1 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Bougie} alt="Bougie" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(0)} />
                        </div>

                        {/* Square images */}
                        <div className="w-full h-full grid grid-rows-2 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Radiateur} alt="Radiateur" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(1)} />
                            <img src={Lavabo} alt="Lavabo" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(2)} />
                            <img src={Lit} alt="Lit" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(3)} />
                            <img src={BainLavabo} alt="Bain Lavabo" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(4)} />
                            <img src={Baignoire} alt="Baignoire" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(5)} />
                            <img src={Fenetre} alt="Fenetre" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex1(6)} />
                        </div>
                    </div>


                    {/* Large image desktop */}
                    <div className="hidden w-[45%] xl:flex flex-col pr-8 lg:px-10 xl:px-12">
                        <img src={Chambre} alt="Chambre" className="h-full object-cover object-center cursor-pointer" onClick={() => setImageIndex1(7)} />
                    </div>

                </div>

                {/* First section carousel */}
                {
                    imageIndex1 !== null &&
                    <Carousel
                        images={[Bougie, Radiateur, Lavabo, Lit, BainLavabo, Baignoire, Fenetre, Chambre]}
                        imageIndex={imageIndex1}
                        close={() => { setImageIndex1(null) }}
                    />
                }

                {/* Des chambres à rêver */}
                {/* Second section */}
                <div className="flex flex-row w-full gap-8 mt-8 lg:mt-20">

                    {/* Large image */}
                    <div className="hidden w-[40%] xl:flex flex-col px-12">
                        <img src={Lit2} alt="Lit2" className="h-full object-cover object-[40%_0%] cursor-pointer" onClick={() => { setImageIndex2(0) }} />
                    </div>

                    {/* Square images */}
                    <div className="w-full xl:w-[55%] flex flex-col gap-2 md:gap-5 lg:gap-7 px-8 md:px-12 xl:pl-0">

                        <div className="w-full text-justify 2xl:px-[25%] pb-8 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maison.section1.description')}</p>
                            <p className="pb-4 lg:pb-6 xl:p-0">{t('maison.section1.amenities')}</p>
                            <p>{t('maison.section1.dimensions')}</p>
                        </div>

                        <div className="w-full h-full grid grid-rows-2 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Fenetre2} alt="Fenetre2" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => { setImageIndex2(1) }} />
                            <img src={Chambre2} alt="Chambre2" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => { setImageIndex2(2) }} />
                            <img src={Rame} alt="Rame" className="w-full h-full aspect-square object-cover object-bottom cursor-pointer" onClick={() => { setImageIndex2(3) }} />
                            <img src={Lavabo2} alt="Lavabo2" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => { setImageIndex2(4) }} />
                            <img src={Luminaire} alt="Luminaire" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => { setImageIndex2(5) }} />
                            <img src={Bains} alt="Bains" className="w-full h-full aspect-square object-cover cursor-pointer" onClick={() => { setImageIndex2(6) }} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                <img src={Lit2} alt="Lit2" className="flex xl:hidden w-full object-cover p-8 pt-4 md:pt-7 pb-0 px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex2(0)} />

                {/* Second section carousel */}
                {
                    imageIndex2 !== null &&
                    <Carousel
                        images={[Lit2, Fenetre2, Chambre2, Rame, Lavabo2, Luminaire, Bains]}
                        imageIndex={imageIndex2}
                        close={() => { setImageIndex2(null) }}
                    />
                }

                {/* Separator */}
                <div ref={separatorRef2} className="w-full">
                    <Separator vignette={Vignette2} />
                </div>


                {/* Des espaces à vivre */}
                <div className="w-full flex flex-row">
                    <div className="w-full xl:w-[55%] gap-7 pl-8 md:pl-12">
                        <p className="ff-king pb-8 text-[20px] lg:text-[25px]">{t('maison.section2.titre')}</p>
                        <div className="w-full hidden lg:grid grid-rows-1 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Chat} alt="Chat" className="w-full aspect-square object-cover hidden lg:flex cursor-pointer" onClick={() => setImageIndex3(1)} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                <img src={Cheminee} alt="Cheminee" className="flex lg:hidden w-full object-cover object-center px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex3(0)} />

                <div className="flex flex-col xl:flex-row w-full gap-8 mt-2 md:mt-5 lg:mt-7">

                    {/* Square images */}
                    <div className="w-full xl:w-[55%] flex flex-col gap-2 md:gap-5 lg:gap-7 px-8 md:px-12 xl:pr-0">

                        <div className="w-full h-full grid grid-rows-5 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Manger} alt="Manger" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(2)} />
                            <img src={Porte} alt="Porte" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(3)} />
                            <img src={Tupperware} alt="Tupperware" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(4)} />
                            <img src={Miroir} alt="Miroir" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(5)} />
                            <img src={Animaux} alt="Animaux" className="w-full aspect-square object-cover object-bottom cursor-pointer" onClick={() => setImageIndex3(6)} />
                            <img src={Table} alt="Table" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(7)} />
                            <img src={Etagere} alt="Etagere" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(8)} />
                            <img src={Lit3} alt="Lit3" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(9)} />
                            <img src={Boule} alt="Boule" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(10)} />
                            <img src={Bureau} alt="Bureau" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(11)} />
                            <img src={Projecteur} alt="Projecteur" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(12)} />
                            <img src={Ensemble} alt="Ensemble" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(13)} />
                            <img src={Sapin} alt="Sapin" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(14)} />
                            <img src={Creche2} alt="Creche" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(15)} />
                            <img src={Couronne} alt="Couronne" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex3(16)} />
                        </div>
                    </div>

                    {/* Large image */}
                    <div className="w-full xl:w-[40%] flex flex-row lg:flex-col px-8 md:px-12 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                        <div className="h-fit flex flex-col gap-6 pb-8">
                            <div>
                                {t('maison.section2.mobilier')}
                            </div>
                            <div>
                                {t('maison.section2.disposition')}
                            </div>
                            <div>
                                {t('maison.section2.animaux')}
                            </div>
                        </div>
                        <div className="hidden lg:flex h-full cursor-pointer" onClick={() => setImageIndex3(0)} style={{ backgroundImage: `url("${Cheminee}")`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                    </div>
                </div>

                {/* Third section carousel */}
                {
                    imageIndex3 !== null &&
                    <Carousel
                        images={[Cheminee, Chat, Manger, Porte, Tupperware, Miroir, Animaux, Table, Etagere, Lit3, Boule, Bureau, Projecteur, Ensemble, Sapin, Creche2, Couronne]}
                        imageIndex={imageIndex3}
                        close={() => { setImageIndex3(null) }}
                    />
                }

                {/* Separator */}
                <div ref={separatorRef4} className="w-full">
                    <Separator vignette={Vignette3} />
                </div>


                {/* Les Extérieurs */}
                <div className="w-full flex flex-row">
                    <div className="w-full xl:w-[55%] gap-7 pl-8 md:pl-12">
                        <p className="ff-king pb-8 text-[20px] lg:text-[25px]">{t('maison.section2bis.titre')}</p>
                        <div className="w-full hidden lg:grid grid-rows-1 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Figuier} alt="Figuier" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(1)} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                <img src={Riviere2} alt="Rivere" className="flex lg:hidden w-full object-cover object-center px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex5(0)} />

                <div className="flex flex-col xl:flex-row w-full gap-8 mt-2 md:mt-5 lg:mt-7">

                    {/* Square images */}
                    <div className="w-full xl:w-[55%] flex flex-col gap-2 md:gap-5 lg:gap-7 px-8 md:px-12 xl:pr-0">

                        <div className="w-full h-full grid grid-rows-[minmax(0,_1fr)_minmax(0,_1fr)_minmax(0,_1fr)_100px_minmax(0,_1fr)] grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Transats} alt="Transats" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(2)} />
                            <img src={Transats2} alt="Transats2" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(3)} />
                            <img src={Terrasse} alt="Terrasse" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(4)} />

                            <img src={Miroir2} alt="Miroir" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(5)} />
                            <img src={Pierres} alt="Pierres" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(6)} />
                            <img src={Arbres} alt="Arbres" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(7)} />

                            <img src={Muret} alt="Muret" className="w-full aspect-square object-cover object-[0%_65%] cursor-pointer" onClick={() => setImageIndex5(8)} />
                            <img src={VueEnsemble} alt="Vue d'ensemble" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(9)} />
                            <img src={Baldaquin} alt="Baldaquin" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(10)} />

                            <div className='col-span-3 flex items-end text-[18px] 2xl:text-[22px]'> {t('maison.section2bis.nature')}</div>

                            <img src={Riviere3} alt="Riviere3" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(11)} />
                            <img src={Chemin} alt="Chemin" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(12)} />
                            <img src={Riviere1} alt="Riviere1" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex5(13)} />

                        </div>

                    </div>



                    {/* Large image */}
                    <div className="w-full xl:w-[40%] flex flex-row lg:flex-col px-8 md:px-12 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                        <div className="h-fit flex flex-col gap-6 pb-8">
                            <div className="">
                                {t('maison.section2bis.description')}
                            </div>
                        </div>
                        <div className="hidden lg:flex h-full cursor-pointer" onClick={() => setImageIndex5(0)} style={{ backgroundImage: `url("${Riviere2}")`, backgroundPosition: '0% 20%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} />
                    </div>
                </div>

                {/* Fith section carousel */}
                {
                    imageIndex5 !== null &&
                    <Carousel
                        images={[Riviere2, Figuier, Transats, Transats2, Terrasse, Miroir2, Pierres, Arbres, Muret, VueEnsemble, Baldaquin, Riviere3, Chemin, Riviere1]}
                        imageIndex={imageIndex5}
                        close={() => { setImageIndex5(null) }}
                    />
                }

                {/* Separator */}
                <div ref={separatorRef3} className="w-full">
                    <Separator vignette={Vignette3} />
                </div>


                {/* Le studio indépendant */}
                <div className="w-full flex flex-row">
                    <div className="w-full xl:w-[55%] gap-7 pl-8 md:pl-12">
                        <p className="ff-king pb-8 text-[20px] lg:text-[25px]">{t('maison.section3.titre')}</p>
                        <div className="w-full hidden lg:grid grid-rows-1 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={Tableau} alt="Tableau" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(0)} />
                        </div>
                    </div>
                </div>

                {/* Large image mobile */}
                <img src={VueTerrasse} alt="Vue depuis la terrasse" className="flex lg:hidden w-full object-cover object-center px-8 md:px-12 cursor-pointer" onClick={() => setImageIndex4(10)} />

                <div className="flex flex-col xl:flex-row w-full gap-8 mt-2 md:mt-5 lg:mt-7">

                    {/* Square images */}
                    <div className="w-full xl:w-[55%] flex flex-col gap-2 md:gap-5 lg:gap-7 px-8 md:px-12 xl:pr-0">

                        <div className="w-full h-full grid grid-rows-3 grid-cols-3 gap-2 md:gap-4 xl:gap-7">
                            <img src={ChaisesJour} alt="ChaisesJour" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(1)} />
                            <img src={Table2} alt="Table2" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(2)} />
                            <img src={Fenetre3} alt="Fenetre3" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(3)} />
                            <img src={Lit4} alt="Lit4" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(4)} />
                            <img src={Transats2} alt="Transats2" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(5)} />
                            <img src={Baignoire2} alt="Baignoire2" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(6)} />
                            <img src={Terrasse} alt="Terrasse" className="w-full aspect-square object-cover cursor-pointer" onClick={() => setImageIndex4(7)} />
                            <img src={ChaisesNuit} alt="ChaisesNuit" className="w-full aspect-square object-cover object-bottom cursor-pointer" onClick={() => setImageIndex4(8)} />
                            <img src={Cuisine} alt="Cuisine" className="w-full aspect-square object-cover object-[65%_50%] cursor-pointer" onClick={() => setImageIndex4(9)} />
                        </div>
                    </div>

                    {/* Large image */}
                    <div className="w-full xl:w-[40%] flex flex-row lg:flex-col px-8 md:px-12 leading-6 2xl:leading-7 text-[18px] 2xl:text-[22px]">
                        <div className="h-fit flex flex-col gap-6 pb-8">
                            <div className="">
                                {t('maison.section3.mobilier')}
                            </div>
                            <div>
                                {t('maison.section3.chambre')}
                            </div>
                        </div>
                        <div className="hidden lg:flex h-full cursor-pointer" onClick={() => setImageIndex4(10)} style={{ backgroundImage: `url("${VueTerrasse}")`, backgroundPosition: '50% 70%', backgroundRepeat: 'no-repeat' }} />
                    </div>
                </div>

                {/* Fourth section carousel */}
                {
                    imageIndex4 !== null &&
                    <Carousel
                        images={[Tableau, ChaisesJour, Table2, Fenetre3, Lit4, Transats2, Baignoire2, Terrasse, ChaisesNuit, Cuisine, VueTerrasse]}
                        imageIndex={imageIndex4}
                        close={() => { setImageIndex4(null) }}
                    />
                }

                <button className="self-start ff-king text-md sm:text-lg md:text-xl bg-[#A5BFD8] hover:bg-[#82b1dd] px-4 py-2 mt-20 mx-8 lg:mx-12" onClick={() => {
                    navigate('/reservation?house=1');
                }}>{t('maison.reservation')}</button>

                <div className="w-full flex flex-col items-center sm:px-6 md:px-12 lg:px-16 2xl:px-32 box-border">
                    <div className="mt-28 px-8">
                        <div className="ff-king text-[25px]">{t('maison.tarifs.titre')}</div>
                        <div className="text-[16px] md:text-[20px]">{t('maison.tarifs.occupation')}</div>
                    </div>

                    <div className="w-full flex flex-col xl:flex-row gap-2 justify-center">
                        <div className="w-full md:2/3 xl:w-1/2">
                            <TarifsMaison
                                showName
                                showSurface
                                nights={props.maison?.periods.map((value) => value.minStay)}
                                prices={props.maison?.periods.map((value) => value.price)}
                            />
                        </div>
                        <div className="w-full md:2/3 xl:w-1/2">
                            <TarifsMas
                                showName
                                showSurface
                                nights={props.mas?.periods.map((value) => value.minStay)}
                                prices={props.mas?.periods.map((value) => value.price)}
                            />
                        </div>
                    </div>

                    <div className="pt-12 text-[16px] md:text-[20px] px-4 text-center">{t('maison.tarifs.menage')}</div>
                </div>
            </div >
        </div >
    );
}

export default Maison;