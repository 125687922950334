import { useTranslation } from "react-i18next";

interface IProps {
    className?: string
}

const InformationLinesCabane = (props: IProps) => {
    const { t } = useTranslation();


    return (
        <div className={`w-full ${props.className} flex-col`}>
            <p className="pt-4"><b>{t('informations.lignes.cabane')}</b></p>
            <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-2 flex-col">
                <div>{t('informations.lignes.rdc')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.kitchenette')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.etage')}</div>
                <div className="border-l-4 border-gray-300 ml-4 pl-4 flex pt-4 flex-col">
                    <div>{t('informations.lignes.mezzanine')}</div>
                </div>
                <div className="pt-4">{t('informations.lignes.terrasse')}</div>
            </div>

            <p className="pt-8">{t('informations.lignes.capacite3')}</p>
            <p className="pb-4">{t('informations.lignes.enfants')}</p>
            <div className="pt-4">
                {t('informations.lignes.parking2')}
                <div>{t('informations.lignes.animaux2')}</div>
                <div>{t('informations.lignes.wifi2')}</div>
            </div>
        </div>
    )
}

export default InformationLinesCabane;