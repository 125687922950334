import Instagram from '../../assets/ig-logo.png';
import Facebook from '../../assets/fb-logo.png';
import Toulouse from '../../assets/toulouse.jpg';
import Basile from '../../assets/reseaux/basile.jpg';
import Berlioz from '../../assets/reseaux/berlioz.jpg';
import Jodie from '../../assets/reseaux/jodie.jpg';
import Louis from '../../assets/reseaux/louis.jpg';
import Neige from '../../assets/reseaux/neige.jpg';
import Noushka from '../../assets/reseaux/noushka.jpg';
import Peel from '../../assets/reseaux/peel.jpg';
import Poules from '../../assets/reseaux/poules.jpg';
import Riviere from '../../assets/reseaux/riviere.jpg';
import Swarovski from '../../assets/reseaux/swarovski.jpg';
import Valentine from '../../assets/reseaux/valentine.jpg';

import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Carousel from '../Shared/Carousel';

interface ILinkProps {
    image: string;
    text: JSX.Element;
    link: string;
}

interface IImageProps {
    image: string;
    text: string;
    onClick?: () => void;
}


const SocialLink = (props: ILinkProps) => {
    return (
        <div className="h-full flex flex-row sm:flex-col w-full sm:justify-between items-center text-center cursor-pointer hover:underline">
            <img src={props.image} alt={String(props.text)} className="h-[40px] lg:h-[70px] pr-4 sm:pr-0" onClick={() => window.open(props.link, "_blank")} />
            <div>{props.text}</div>
        </div>
    )
}

const Image = (props: IImageProps) => {
    return (
        <div className="flex flex-col items-center text-center mx-1 hover:font-bold">
            <img src={props.image} alt={props.text} className="w-full lg:border-4 border-[#A5BFD8] object-cover cursor-pointer aspect-square md:aspect-auto md:h-[259px] mb-2 sm:mb-0" onClick={props.onClick} />
            <div className="hidden sm:flex ff-king mt-4 text-[12px] mb-4">{props.text}</div>
        </div>
    )
}

const Social = () => {
    const { t } = useTranslation();
    const [imageIndex, setImageIndex] = useState<number | null>(null);

    return (
        <div className="flex flex-col items-center justify-start w-full h-full md:px-16 lg:px-32 xl:px-60">

            {/* Links */}
            <div className="w-full">

                {/* Title */}
                <p className="text-3xl ff-king my-12 text-start sm:text-start px-6 md:px-0 lg:px-40">{t('social.titre')}</p>

                <div className='flex justify-center w-full px-6'>
                    <div className="flex flex-col sm:flex-row items-start justify-start w-full gap-4 sm:gap-0 xl:gap-24 h-fit lg:h-[120px] max-w-[800px] pb-6 sm:pb-0">

                        {/* Instagram */}
                        {/* <SocialLink link="https://www.instagram.com/sorene_en_cevennes" image={Instagram} text={<p>@sorene_en_cevennes</p>} /> */}

                        {/* Facebook */}
                        <SocialLink link="https://www.facebook.com/sorene.cosmetiques" image={Facebook} text={<p>Sorène -<br className='hidden sm:flex' />Cosmétiques bio en Cévennes</p>} />

                        {/* Instagram */}
                        <SocialLink link="https://www.instagram.com/sorene_cosmetiques" image={Instagram} text={<p>@sorene_cosmetiques</p>} />
                    </div>
                </div>

                <p className="text-[18px] md:text-xl ff-king mt-4 text-justify lg:text-start px-6 md:px-0 lg:px-40">{t('social.sous-titre')}</p>
            </div>

            {/* Photos */}
            <div className="w-full my-12 sm:my-32">
                <p className="text-[18px] md:text-xl text-justify sm:text-start px-6 md:px-0 lg:pl-40 ff-king mb-12">{t('social.photos')}</p>

                {/* Images */}
                <div className='grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 grid-rows-2'>
                    <Image image={Noushka} onClick={() => { setImageIndex(0) }} text="Noushka en balade" />
                    <Image image={Toulouse} onClick={() => { setImageIndex(1) }} text="Toulouse et Iphigénie" />
                    <Image image={Valentine} onClick={() => { setImageIndex(2) }} text="Bébé Valentine" />
                    <Image image={Peel} onClick={() => { setImageIndex(3) }} text="Emma Peel, Valentine et Louis" />
                    <Image image={Basile} onClick={() => { setImageIndex(4) }} text="Basile, chat de berger" />
                    <Image image={Riviere} onClick={() => { setImageIndex(5) }} text="Les chèvres à la rivière" />
                    <Image image={Poules} onClick={() => { setImageIndex(6) }} text="Valentine et ses poules" />
                    <Image image={Jodie} onClick={() => { setImageIndex(7) }} text="Jodie en bruyères" />
                    <Image image={Berlioz} onClick={() => { setImageIndex(8) }} text="Le berger et Berlioz" />
                    <Image image={Swarovski} onClick={() => { setImageIndex(9) }} text="La bergère et Swarovski" />
                    <Image image={Louis} onClick={() => { setImageIndex(10) }} text="Louis" />
                    <Image image={Neige} onClick={() => { setImageIndex(11) }} text="Neige, parfait border-collie" />
                </div>
            </div>

            {/* Videos */}
            <div className="w-full px-2 lg:px-0 lg:pl-40">
                <p className="text-[18px] md:text-xl text-start ff-king px-4">{t('social.vidéos')}</p>

                {/* Videos */}
                <div className="flex lg:hidden flex-col justify-start gap-4 my-16">
                    <iframe width="100%" height="242" src="https://www.youtube-nocookie.com/embed/ZLHGsyvlEf8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <iframe width="100%" height="242" src="https://www.youtube-nocookie.com/embed/NEADJFhgdgc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>

                <div className="hidden lg:flex flex-coljustify-start gap-16 my-16">
                    <iframe width="382" height="242" src="https://www.youtube-nocookie.com/embed/ZLHGsyvlEf8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <iframe width="382" height="242" src="https://www.youtube-nocookie.com/embed/NEADJFhgdgc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>

            {/* Full image */}
            {/* {fullImage && <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col items-center justify-center z-20">
                <div className='w-full max-w-[1000px] flex justify-between items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width={3} stroke="white" className="h-16 cursor-pointer" onClick={() => imageIndex !== null && setImageIndex(imageIndex - 1)}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    <img src={fullImage} alt="Full size" className="w-[80%] h-[80%] object-contain cursor-pointer" onClick={() => { setFullImage(null); setImageIndex(null) }} />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="h-16 cursor-pointer" onClick={() => imageIndex !== null && setImageIndex(imageIndex + 1)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>

                </div>
            </div>} */}
            {
                imageIndex !== null &&
                <Carousel
                    images={[Noushka, Toulouse, Valentine, Peel, Basile, Riviere, Poules, Jodie, Berlioz, Swarovski, Louis, Neige]}
                    imageIndex={imageIndex}
                    close={() => { setImageIndex(null) }}
                />
            }
        </div>
    );
}

export default Social;