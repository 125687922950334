import { useEffect, useState } from "react";
import MaisonModel from "../../models/Maisons";

const HouseCard = (props: { house: MaisonModel }) => {
    const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
    const [isEditingInfos, setIsEditingInfos] = useState<boolean>(false);
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    useEffect(() => {
        console.log(props.house);
    }, [])

    const saveChanges = async () => {
        const res = await props.house.save();

        if (res) {
            setHasChanged(false);
            console.log("Saved");
        }
    }

    return (
        <div className="h-96 border-l w-5/6 px-20">

            {/* Description */}
            <div className="font-bold text-xl">Description</div>
            <div className="w-4/5 text-lg py-4 flex">

                {
                    !isEditingDescription ? <div className="w-5/6 text-justify">
                        {props.house.description}
                    </div> : <textarea className="w-5/6 h-48 border-2 border-[#e4e4ec] rounded-md p-4" onChange={(e) => props.house.description = e.target.value} defaultValue={props.house.description} />
                }

                <div className="flex justify-end w-1/6 pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => { setIsEditingDescription(!isEditingDescription); setHasChanged(true); }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
                </div>
            </div>

            {/* Informations */}
            <div className="font-bold text-xl pt-8">Informations</div>
            <div className="w-4/5 text-lg py-4 flex flex-col gap-6">

                <div className="w-full flex items-end justify-between">
                    <div className="w-5/6 flex items-center">
                        <div className="font-bold pr-4 w-56">Capacité :&nbsp;</div>
                        <div>de</div>
                        {!isEditingInfos ? <div className="px-4">{props.house.minCapacity}</div> : <input type="number" className="px-1 mx-4 w-24 border-2 border-[#e4e4ec] rounded-md" defaultValue={props.house.minCapacity} onChange={(e) => { props.house.minCapacity = parseInt(e.target.value); setHasChanged(true); }} />}
                        <div>à</div>
                        {!isEditingInfos ? <div className="px-4">{props.house.maxCapacity}</div> : <input type="number" className="px-1 mx-4 w-24 border-2 border-[#e4e4ec] rounded-md" defaultValue={props.house.maxCapacity} onChange={(e) => { props.house.maxCapacity = parseInt(e.target.value); setHasChanged(true); }} />}
                        <div>personnes</div>
                    </div>

                    <div className="flex w-1/6 justify-end pt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => setIsEditingInfos(!isEditingInfos)}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                    </div>
                </div>

                {
                    props.house.periods.map((period, index) => {
                        return (
                            <div className="flex" key={index}>
                                <div className="font-bold pr-4 w-56">{index == 0 ? "De novembre à avril" : index == 1 ? "Mai et juin" : index == 2 ? "Juillet et août" : "Septembre et octobre"} : &nbsp;</div>
                                <div className="flex items-center">
                                    {!isEditingInfos ? <div>{period.price}</div> : <input type="number" className="px-1 mr-4 w-24 border-2 border-[#e4e4ec] rounded-md" defaultValue={period.price} onChange={(e) => { period.price = parseInt(e.target.value); setHasChanged(true); }} />}
                                    <div>€/nuit -</div>
                                    {!isEditingInfos ? <div className="px-1">{period.minStay}</div> : <input type="number" className="px-1 mx-4 w-24 border-2 border-[#e4e4ec] rounded-md" defaultValue={period.minStay} onChange={(e) => { period.minStay = parseInt(e.target.value); setHasChanged(true); }} />}
                                    <div>nuits minimum</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {/* Save button */}
            <div className="w-4/5 flex justify-end pt-2">
                <button className={`rounded-md px-8 py-2 mt-8 ${hasChanged ? 'bg-[#e4e4ec]' : 'bg-[#e4e4ec] cursor-not-allowed opacity-50'}`} disabled={!hasChanged} onClick={saveChanges}>Enregistrer</button>
            </div>
        </div>
    )
}

export default HouseCard;