import Navbar from '../components/Navbar';
import Histoire from '../components/Histoire/Histoire';
import Home from '../components/Home/Home';
import Milk from '../components/Milk/Milk';
import Social from '../components/Social/Social';
import Footer from '../components/Footer';
import Cabane from '../components/Cabane/Cabane';
import Informations from '../components/Informations/Informations';
import Maisonnette from '../components/Maisonnette/Maisonnette';
import Maison from '../components/Maison/Maison';
import Soins from '../components/Soins/Soins';
import { useEffect, useState } from 'react';
import MaisonModel from '../models/Maisons';
import Loading from '../components/Loading';
import Confidentialite from '../components/Legal/Confidentialite';
import Mentions from '../components/Legal/Mentions';

const App = () => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [maisons, setMaisons] = useState<MaisonModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [innerLoading, setInnerLoading] = useState<boolean>(true);

    const setIndex = (index: number) => {
        setTabIndex(index);

        window.scrollTo({
            top: 0,
            behavior: index === tabIndex ? 'smooth' : 'auto'
        });
    }

    const initMaisons = async () => {
        const startTime = Date.now();
        const minLoadingTime = 2500;
        const res = await MaisonModel.init();

        setMaisons(res);

        const endTime = Date.now();

        console.log(endTime - startTime);

        if (endTime - startTime < minLoadingTime) {
            setTimeout(() => {
                setLoading(false);
            }, minLoadingTime - (endTime - startTime));
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        initMaisons();
    }, []);

    useEffect(() => {
        if (tabIndex !== 0) {
            setInnerLoading(false);
        }
    }, [tabIndex]);

    return (
        <div className={`home ${innerLoading || loading ? 'overflow-y-hidden max-h-screen' : ''}`}>
            <Loading loading={loading || innerLoading} />
            <Navbar setIndex={setIndex} />

            {(() => {
                switch (tabIndex) {
                    case 0:
                        return <Home setIndex={setIndex} setInnerLoading={(value) => setInnerLoading(value)} />;
                    case 1:
                        return !loading && <Maison maison={maisons[1]} mas={maisons[0]} />
                    case 2:
                        return !loading && <Cabane cabane={maisons[2]} />;
                    case 3:
                        return !loading && <Maisonnette maison={maisons[3]} />;
                    case 4:
                        return <Soins />;
                    case 5:
                        return <Histoire />;
                    case 6:
                        return !loading && <Informations mas={maisons[0]} maison={maisons[1]} cabane={maisons[2]} maisonnette={maisons[3]} />
                    case 7:
                        return <Milk />
                    case 8:
                        return <Social />
                    case 9:
                        return <Confidentialite />
                    case 10:
                        return <Mentions />
                    default:
                        return <Home setIndex={setIndex} setInnerLoading={(value) => setInnerLoading(value)} />;
                }
            })()}

            <Footer setIndex={setIndex} />
        </div>
    );
}

export default App;