import { useEffect, useState } from "react";

interface IProps {
    images: string[];
    imageIndex: number;
    close: () => void;
}

const Carousel = (props: IProps) => {
    const [index, setIndex] = useState(props.imageIndex);

    useEffect(() => {
        if (index == null) return;
        if (index < 0) {
            setIndex(props.images.length - 1);
        } else if (index >= props.images.length) {
            setIndex(0);
        }
    }, [index])

    const handleClick = (e: any) => {
        if (e?.target?.id !== "handle") {
            props.close();
        }
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col items-center justify-center z-20 cursor-pointer" onClick={handleClick}>
            <div className='w-full max-w-[1000px] flex justify-between items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="h-16 left-0 absolute lg:relative" id="handle" onClick={() => index !== null && setIndex(index - 1)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" id="handle" />
                </svg>
                <img src={props.images[index]} alt="Full size" className="w-full lg:w-[80%] lg:h-[80%] object-contain" />
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="h-16 right-0 absolute lg:relative" id="handle" onClick={() => index !== null && setIndex(index + 1)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" id="handle" />
                </svg>

            </div>
        </div>
    )
}

export default Carousel;