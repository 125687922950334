import { useEffect, useState } from "react";
import { after } from "underscore";
import Banner from "./Banner";
import Porte from "../../assets/accueil_porte.jpg";
import Fleurs from "../../assets/fleurs.jpg";
import Cabane from "../../assets/cabane.jpg";
import Chataignier from '../../assets/chataignier.jpg';
import Paysage from '../../assets/accueil_maisonnette.jpg';
import Figuier from '../../assets/maisonnette/exterieurs/plante.jpg';

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {
    setIndex: (index: number) => void;
    setInnerLoading: (value: boolean) => void;
}

const Home = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={`flex flex-col items-center justify-start w-full h-full`}>

            <Banner setBannerLoaded={() => props.setInnerLoading(false)} />
            <div className="w-full sm:px-8 lg:px-32 flex flex-col items-center lg:mb-16">



                {/* Maison */}


                <div className="flex flex-row w-full gap-12">

                    {/* Large image */}
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col sm:h-[300px] mb-2 sm:mb-8">
                            <div className={`flex flex-col py-12 sm:py-[92px] ff-king text-[15px] sm:text-[22px] leading-7 self-center xl:self-end ${i18n.language !== 'fr' ? 'italic' : ''}`}>
                                Mon enfant, ma soeur, <br />
                                Songe à la douceur <br />
                                D'aller là-bas vivre ensemble <br />
                            </div>
                            <p className="ff-king text-xl sm:text-3xl w-fit cursor-pointer" onClick={() => props.setIndex(1)}>{t('navbar.maison')}</p>
                        </div>
                        <img src={Porte} alt="Porte" className="w-full object-cover cursor-pointer" onClick={() => props.setIndex(1)} />
                    </div>



                    {/* Small image */}
                    <div className="hidden xl:flex w-1/2 xl:px-16 2xl:px-32 flex-col items-center justify-between">
                        <div>
                            <div className="flex flex-col h-[300px] min-h-[300px] mb-8" />
                            <img src={Fleurs} alt="Fleurs" className="w-full aspect-square object-cover object-center" />
                        </div>

                        {/* Reservez */}
                        <button className="flex self-start -ml-16 ff-king text-[21px] bg-[#A5BFD8] hover:bg-[#82b1dd] py-2 px-4" onClick={() => {
                            navigate('/reservation?house=1');
                        }}>{t('maison.reservation')}</button>
                    </div>
                </div>


                {/* Cabane */}


                <div className="flex flex-row w-full gap-12">

                    {/* Small image */}
                    <div className="hidden xl:flex w-1/2 xl:px-16 2xl:px-32 flex-col items-center justify-between">
                        <div>
                            <div className="flex flex-col h-[300px] min-h-[300px] mb-8" />
                            <img src={Chataignier} alt="Chataignier" className="w-full aspect-square object-cover object-center" />
                        </div>

                        {/* Reservez */}
                        <button className="flex self-start -ml-16 ff-king text-[21px] bg-[#A5BFD8] hover:bg-[#82b1dd] py-2 px-4" onClick={() => {
                            navigate('/reservation?house=2');
                        }}>{t('maison.reservation')}</button>
                    </div>

                    {/* Large image */}
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col sm:h-[300px] mb-2 sm:mb-8">
                            <div className={`flex flex-col py-12 sm:py-[92px] ff-king text-[15px] sm:text-[22px] self-center xl:self-start leading-7 ${i18n.language !== 'fr' ? 'italic' : ''}`}>
                                Tout y parlerait <br />
                                À l'âme en secret <br />
                                Sa douce langue natale <br />
                            </div>
                            <p className={`ff-king text-xl sm:text-3xl w-fit cursor-pointer ${i18n.language !== 'fr' ? '-mt-8' : ''}`} onClick={() => props.setIndex(2)}>{t('navbar.cabane')}</p>
                        </div>
                        <img src={Cabane} alt="Cabane" className="w-full object-cover cursor-pointer" onClick={() => props.setIndex(2)} />
                    </div>
                </div>


                {/* Maisonnette */}


                <div className="flex flex-row w-full gap-12">

                    {/* Large image */}
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col sm:h-[300px] mb-2 sm:mb-8">
                            <div className={`flex flex-col py-12 sm:py-[92px] ff-king text-[15px] sm:text-[22px] leading-7 self-center xl:self-end ${i18n.language !== 'fr' ? 'italic' : ''}`}>
                                Le monde s'endort <br />
                                Dans une chaude lumière.<br />
                            </div>
                            <p className="ff-king text-xl sm:text-3xl w-fit cursor-pointer" onClick={() => props.setIndex(1)}>{t('navbar.maisonnette')}</p>
                        </div>
                        <img src={Paysage} alt="Maisonnette" className="w-full object-cover cursor-pointer" onClick={() => props.setIndex(3)} />
                    </div>

                    {/* Small image */}
                    <div className="hidden xl:flex w-1/2 xl:px-16 2xl:px-32 flex-col items-center justify-between">
                        <div>
                            <div className="flex flex-col h-[300px] min-h-[300px] mb-8" />
                            <img src={Figuier} alt="Figues" className="w-full aspect-square object-cover object-center" />
                        </div>

                        {/* Reservez */}
                        <button className="flex self-start -ml-16 ff-king text-[21px] bg-[#A5BFD8] hover:bg-[#82b1dd] py-2 px-4" onClick={() => {
                            navigate('/reservation?house=3');
                        }}>{t('maison.reservation')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;