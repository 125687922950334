import Image from '../../assets/maison/separator.png';

interface IProps {
    vignette?: string;
}

const Separator = (props: IProps) => {
    return (
        <div className="flex w-full px-8 md:px-12 flex-row items-center relative mt-24 mb-16 md:mt-48 md:mb-24">
            {
                props.vignette && <img src={props.vignette} alt="Separator vignette" className="hidden xl:flex h-[156px] aspect-square object-cover absolute border-r-[36px] box-content border-white" />
            }
            <div className="w-full h-[1px] bg-black mr-[48px] md:mr-[62px]" />
            <div className='absolute bg-black h-[1px] w-[6px] md:w-[8px] top-[-3px] left-[calc(50%-25px)] md:left-[calc(50%-32px)] rotate-[-45deg]'></div>
            <img src={Image} alt="Image" className="object-contain w-[60px] md:w-[80px] absolute left-[calc(50%-30px)] md:left-[calc(50%-40px)] top-[-25px] md:top-[-30px]" />
            <div className='absolute bg-black h-[1px] w-[6px] md:w-[8px] top-[-3px] left-[calc(50%+19px)] md:left-[calc(50%+24px)] rotate-[45deg]'></div>
            <div className="w-full h-[1px] bg-black" />
        </div>
    )
}

export default Separator;