import { useEffect, useState } from "react"
import Loading from "../components/Loading";
import { checkAuth, tryLogin } from "../services/auth"
import Cabane from "../assets/cabane.jpg"
import { useTranslation } from "react-i18next";

const Auth = () => {
    const { t } = useTranslation();
    const [auth, setAuth] = useState<boolean>(true);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const checkAuthentication = async () => {
        const res = await checkAuth()

        if (res) {
            window.location.href = "/admin";
        } else {
            setAuth(false);
        }
    }

    useEffect(() => {
        checkAuthentication();
    }, [])

    const login = async () => {
        const res = await tryLogin(username, password);

        if (res) {
            window.location.href = "/admin";
        } else {
            alert("Wrong credentials");
        }
    }

    return !auth ? (
        <div className="w-full h-[100vh] flex flex-row">

            {/* Left */}
            <div className="w-full lg:w-1/2 xl:w-1/3 h-full flex justify-center items-center">
                <div className="w-[400px] h-[400px] bg-white rounded-lg flex flex-col justify-center items-center px-8">
                    <h1 className="text-3xl font-bold mb-16">Connexion</h1>
                    <div className="w-full flex flex-col mb-4">
                        {/* <label htmlFor="username" className="text-xl font-bold mb-2">Nom d'utilisateur</label> */}
                        <input type="text" name="username" id="username" placeholder="Identifiant" className="w-full py-2 mb-4 border-b outline-none border-[#a5a7ad]" value={username} onKeyDown={async (e) => e.key === "Enter" && await login()} onChange={(e) => setUsername(e.target.value)} />
                    </div>

                    <div className="w-full flex flex-col mb-8">
                        {/* <label htmlFor="password" className="text-xl font-bold mb-2">Mot de passe</label> */}
                        <input type="password" name="password" id="password" placeholder="Mot de passe" className="w-full py-2 mb-4 border-b outline-none border-[#a5a7ad]" value={password} onKeyDown={async (e) => e.key === "Enter" && await login()} onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <button className="bg-[#82b1dd] hover:bg-[#71a6d8] text-white rounded-lg p-2 w-full font-bold" onClick={async () => await login()}>Se connecter</button>
                </div>
            </div>

            {/* Right */}
            <div className="hidden lg:flex w-1/2 xl:w-2/3 h-full">
                <img src={Cabane} alt="Cabane" className="w-full h-full object-cover" />
            </div>
        </div>
    ) : (
        <Loading loading={auth} />
    )
}

export default Auth