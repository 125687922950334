import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CheckoutCancelled = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // TODO: Send a request to the backend to cancel the reservation
    }, []);

    return (
        <div className='w-full h-full'>
            <div className='h-[78px] w-full bg-[#A5BFD8] flex items-center justify-center'></div>
            <div className="flex flex-col h-full justify-center items-center pb-16">


                <div className="ff-king text-[22px] py-12">Votre paiement a été annulé</div>

                <div className="w-full flex flex-col items-center">
                    <div className="ff-king text-[16px] pb-12"> La réservation n'a pas pu aboutir.</div>
                    <div className="w-full flex justify-center">
                        <button className="bg-blue-300 ff-king text-16 w-fit py-2 px-4" onClick={() => navigate("/")}>Retour à la page d'accueil</button> </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutCancelled;
